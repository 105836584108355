import { doc, setDoc, updateDoc } from '@firebase/firestore';
import { Close } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import format from 'date-fns/format';
import ru from "date-fns/locale/ru";
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, Task, Worker } from '../../apiTypes';
import SmartAvatar from '../../components/SmartAvatar';
import { Map } from '../../constants';
import { db } from '../../firebase';
import { sendPush } from '../../utils';


interface Props {
  onClose: () => void
  task?: Document<Task>
  workers: Document<Worker>[]
  workersMap: Map<Document<Worker>>
  author: string
}

const TaskEditModal = (props: Props) => {
  const { task, onClose, workersMap, workers, author } = props
  const { companySlug } = useParams<Params>();
  const [date, setDate] = useState<Date>(new Date(task ? task.time : Date.now()))
  const [name, setName] = useState(task ? task.name : '')
  const [workerId, setWorkerId] = useState<string | null>(task ? task.worker : null)
  const [description, setDescription] = useState(task ? task.description : '')
  const canSave = !!date && !!name.trim() && !!workerId

  const saveChanges = () => {
    if (task && workerId) {
      const worker = workersMap[workerId] || {}
      const displayName = worker.lastName + ' ' + worker.name
      const text = `
        ${task.name !== name ? ('Изменил(а) название на: ' + name + '. ') : ''}
        ${task.time !== date.getTime() ? ('Изменил(а) дату на: ' + format(date, 'dd MMMM', {locale: ru}) + '. ') : ''}
        ${task.worker !== workerId ? ('Изменил(а) исполнителя на: ' + displayName + '. ') : ''}
        ${task.description !== description ? ('Изменил(а) описание на: ' + description + '.') : ''}
      `
      updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
        time: date.getTime(),
        name,
        worker: workerId,
        description,
        comments: [
          ...task.comments,
          {
            time: new Date().getTime(),
            author,
            comment: text,
            viewed: [author],
          }
        ]
      })

      if (worker.msgToken) {
        sendPush({
          title: 'В задаче: ' + task.name,
          body: 'Внесены изменения. ' + text,
          msgToken: worker.msgToken,
          url: `https://jimba.cloud/${companySlug}/terminal`
        })
      }
    } else if (workerId) {
      const worker = workersMap[workerId] || {}

      setDoc(doc(db, "company", companySlug, "tasks", uniqid()), {
        status: 'planned',
        time: date.getTime(),
        name,
        worker: workerId,
        description,
        comments: [{
          time: new Date().getTime(),
          author,
          comment: 'Создал(а) задачу',
          viewed: [author],
        }]
      })

      if (worker.msgToken) {
        sendPush({
          title: 'Новая задача',
          body: name,
          msgToken: worker.msgToken,
          url: `https://jimba.cloud/${companySlug}/terminal`
        })
      }
    }

    onClose()
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open maxWidth='xs' fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
              Задача
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers >
          <TextField
            label='Название'
            value={name}
            onChange={e => setName(e.target.value)}
            required
            sx={{my: 2}}
            fullWidth
          />

          <DatePicker
            disableMaskedInput
            label="Срок выполнения"
            value={date}
            onChange={(date) => date && setDate(date)}
            renderInput={(params) => <TextField {...params} required sx={{my: 2}} fullWidth/>}
          />

          <Autocomplete
            value={workerId}
            options={workers.filter(w => !w.archived).map(w => w.id)}
            getOptionLabel={o => {
              const worker = workersMap[o] || {}
              return worker.lastName + ' ' + worker.name
            }}
            renderOption={(props, o) => {
              const worker = workersMap[o] || {}
              const name = worker.lastName + ' ' + worker.name
              return (
                <Box component="li" {...props} sx={{display: 'flex'}}>
                  <SmartAvatar name={name} photoURL={worker.photoURL} />
                  <Box marginLeft={1}>{name}</Box>
                </Box>
              )
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label='Исполнитель' required sx={{my: 2}}/>}
            onChange={(e, value) => setWorkerId(value)}
          />

          <TextField
            label='Описание'
            fullWidth
            multiline
            value={description}
            onChange={e => setDescription(e.target.value)}
            sx={{my: 2}}
          />
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={saveChanges}
            color="primary"
            disabled={!canSave}
          >
            {!!task ? 'Сохранить изменения' : 'Создать задачу'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default TaskEditModal;
