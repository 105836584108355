
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';

interface Props {
  open: boolean
  onClose: () => void
  title: string
  onSubmit: () => void
  submitLabel?: string
}

function Confirmation(props: Props) {

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>
        {props.title}
      </DialogTitle>
      <DialogActions>
        <Button autoFocus onClick={props.onClose} color="primary">
          Отмена
        </Button>
        <Button onClick={props.onSubmit} color="primary">
          {props.submitLabel || 'Удалить'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


export default Confirmation;
