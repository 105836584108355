import {
  ExpandLess,
  ExpandMore,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Document } from "swr-firestore-v9";
import { TechMap, WorkShift } from "../apiTypes";
import { Map } from "../constants";
import { getTime } from "../pages/TechMaps/utils";
import { getDateFromDateCode } from "../utils";

interface Props {
  availableWorkShifts: Document<WorkShift>[];
  workShifts: Document<WorkShift>[];
  workShift: string | null;
  setWorkShift: Dispatch<SetStateAction<string | null>>;
  techMapsMap: Map<Document<TechMap>>;
}

const ChooseWorkShift = (props: Props) => {
  const {
    availableWorkShifts,
    setWorkShift,
    workShift,
    workShifts,
    techMapsMap,
  } = props;
  const [opened, setOpened] = useState<string | null>(null);

  const dayIndex = String(new Date().getDay());

  useEffect(() => {
    if (availableWorkShifts.length === 1 && !workShift) {
      setWorkShift(availableWorkShifts[0].id);
    }
  }, [availableWorkShifts, workShift, setWorkShift]);

  return (
    <Box>
      <Typography variant="h6" sx={{ my: 2 }} align="center">
        Смены
      </Typography>

      {!availableWorkShifts.length && (
        <Typography
          variant="h5"
          sx={{ my: 2, color: (t) => t.palette.text.secondary }}
          align="center"
        >
          На сегодня нет доступных смен
        </Typography>
      )}

      {availableWorkShifts.map((ws) => {
        const techMap = techMapsMap[ws.techMap] || {};
        const actions = techMap.extendedActions?.[dayIndex].length
          ? techMap.extendedActions[dayIndex]
          : techMap.actions;

        return (
          <Card
            variant="outlined"
            key={ws.id}
            sx={{
              borderColor: (t) =>
                ws.id === workShift
                  ? t.palette.success.light
                  : t.palette.grey[300],
              mb: 2,
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: (t) => t.palette.grey[50],
                overflow: "auto",
              }}
              title={techMap.object + ": " + techMap.name}
              subheader={
                ws.id === workShift ? (
                  <Box sx={{ color: (t) => t.palette.success.main }}>
                    {"Активная"}
                  </Box>
                ) : (
                  <Button
                    size="small"
                    onClick={() => setWorkShift(ws.id)}
                    variant="outlined"
                    sx={{ mt: 1 }}
                  >
                    Выбрать смену
                  </Button>
                )
              }
              action={
                !!actions?.length && (
                  <IconButton
                    onClick={() => setOpened(ws.id === opened ? null : ws.id)}
                    aria-expanded={ws.id === opened}
                    aria-label="show details"
                  >
                    {ws.id === opened ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )
              }
            />

            {ws.id === opened && !!actions?.length && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <CardContent>
                  {(techMap.extendedActions?.[dayIndex] || actions).map(
                    (action, index) => {
                      const [start, end] = getTime(
                        actions,
                        index,
                        techMap.startTime
                      );
                      return (
                        <Box
                          key={index}
                          sx={{
                            mb: 1,
                            pb: 1,
                            borderBottom: (t) =>
                              `1px solid ${t.palette.grey[300]}`,
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box width={38} sx={{ mr: 1, mb: "4px" }}>
                              <Typography variant="body2">{start}</Typography>
                              <Box paddingLeft={1} paddingRight={1}>
                                <Divider />
                              </Box>
                              <Typography variant="body2">{end}</Typography>
                            </Box>
                            <Box mr={2}>
                              <Typography
                                variant="body2"
                                align="center"
                                sx={{ color: (t) => t.palette.text.secondary }}
                              >
                                {action.duration}
                              </Typography>
                              <Typography
                                variant="body2"
                                align="center"
                                sx={{ color: (t) => t.palette.text.secondary }}
                              >
                                мин
                              </Typography>
                            </Box>
                            <Box>
                              <Typography variant="body2">
                                {action.place}
                              </Typography>
                              <Typography variant="subtitle2">
                                {action.name}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            variant="body2"
                            sx={{ color: (t) => t.palette.text.secondary }}
                          >
                            {action.instructions}
                          </Typography>
                        </Box>
                      );
                    }
                  )}
                </CardContent>
              </Collapse>
            )}
          </Card>
        );
      })}

      <Accordion
        sx={{ boxShadow: "0", "::before": { display: "none" } }}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Назначенные смены</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {workShifts?.map((ws) => (
            <Grid container sx={{ mb: 1 }} key={ws.id}>
              <Grid item xs={4}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: (t) => t.palette.text.secondary }}
                  align="left"
                >
                  {format(getDateFromDateCode(ws.dateCode), "dd.MM (EEEEEE)", {
                    locale: ru,
                  })}
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  variant="body2"
                  sx={{ color: (t) => t.palette.text.secondary }}
                  align="left"
                >
                  {techMapsMap[ws.techMap]?.object +
                    ": " +
                    techMapsMap[ws.techMap]?.name}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ChooseWorkShift;
