import { doc, getDoc, increment, runTransaction } from '@firebase/firestore';
import { CheckCircleOutline, Edit, InsertDriveFile, Print } from '@mui/icons-material';
import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Button, Checkbox, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router-dom';
import { Document, useCollection, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, Payout, User, UserCredentials, WalletLog, Worker, WorkShift } from '../../apiTypes';
import Loader from '../../components/Loader';
import SmartAvatar from '../../components/SmartAvatar';
import { useAdmin } from '../../contexts/admin.context';
import { auth, db } from '../../firebase';
import { getDateCode } from '../../utils';
import ActPrintModal, { CheckActPrintModal } from './ActPrintModal';
import PayoutEditModal from './PayoutEditModal';
import PayoutsPrintModal from './PayoutsPrintModal';

export type Checked = {
  [id in string]: boolean
}

const initChecked: Checked = {}

export interface ActProps {
  cheked: boolean
  worker: Document<Worker>
  date: Date
  sum: number
}
interface Props {
  userCredentials: UserCredentials
}

function Payouts(props: Props) {
  const { companySlug } = useParams<Params>()
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()])
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(initChecked)
  const [payout, setPayout] = useState<Document<Payout> | undefined>(undefined)
  const [printModal, setPrintModal] = useState(false)
  const [actPrintModal, setActPrintModal] = useState<ActProps | null>(null)
  const [userAuth] = useAuthState(auth)
  const user = useDocument<User>(userAuth ? `company/${companySlug}/users/${userAuth.email}` : null)
  const author = user.data?.id || '';
  const { workers, workersMap, isValidating } = useAdmin();
  const availableWorkers = workers.filter(w => !w.archived)
  const { walletsMap } = useAdmin()
  const [dateCodesFromThisMonth, setDateCodesFromThisMonth] = useState<number[]>([]);

  const workShiftsFromThisMonth = useCollection<WorkShift>(
    dateCodesFromThisMonth[0] && dateCodesFromThisMonth[1] ? `company/${companySlug}/workShifts` : null, {
      listen: true,
      where: [
        ['dateCode', '>=', dateCodesFromThisMonth[0]],
        ['dateCode', '<=',  dateCodesFromThisMonth[1]],
      ],
    }
  )

  const payouts = useCollection<Payout>(`company/${companySlug}/payouts`, {
    listen: true,
    where: [
      ['time', '>', dates[0]?.getTime() || 0],
      ['time', '<',  dates[1]?.getTime() || 0],
    ],
  })

  const closePayout = async (payout: Document<Payout>) => {
      const walletLogId = uniqid()

      await runTransaction(db, async (transaction) => {
        const payoutDoc = await transaction.get(doc(db, "company", companySlug, "payouts", payout.id));

        if (!payoutDoc.exists()) {
          throw "Document does not exist!";
        }

        const payoutFormDB = payoutDoc.data() as Document<Payout>

        if (!payoutFormDB.closed) {
          if (payout.fromWallet) {
            const wallet = walletsMap[payout.fromWallet]
            const worker = workersMap[payout.worker]
            const name = worker.lastName + ' ' + worker.name

            if (!wallet || !worker) {
              throw "Document does not exist!";
            }

            const log: WalletLog = {
              time: new Date().getTime(),
              sum: payout.sum,
              author,
              wallet: payout.fromWallet,
              total: (wallet?.balance || 0) - payout.sum,
              comment: `Списание согласно ведомости №${payout.number} (${name})`,
              type: 'spending'
            }

            transaction.update(doc(db, "company", companySlug, "wallets", payout.fromWallet), {
              balance: increment(-payout.sum)
            })

            transaction.set(doc(db, "company", companySlug, "wallets", payout.fromWallet, "logs", walletLogId), log)
          }

          transaction.update(doc(db, "company", companySlug, "payouts", payout.id), {
            sum: payout.sum,
            worker: payout.worker,
            fromWallet: payout.fromWallet,
            closed: true,
            walletLogId: payout.fromWallet ? walletLogId : null
          })

          transaction.update(doc(db, "company", companySlug, "workers", payout.worker), {
            balance: increment(-payout.sum)
          })
        } else {
          console.log('Выплата уже закрыта!')
        }
      });
  }

  const cancelClosePayout = async (payout: Document<Payout>) => {
    await runTransaction(db, async (transaction) => {
      const payoutDoc = await transaction.get(doc(db, "company", companySlug, "payouts", payout.id));

      if (!payoutDoc.exists()) {
        throw "Document does not exist!";
      }

      const payoutFormDB = payoutDoc.data() as Document<Payout>

      if (payoutFormDB.closed) {
        if (payout.walletLogId && payout.fromWallet) {
          const wallet = walletsMap[payout.fromWallet]
          const worker = workersMap[payout.worker]
          const name = worker.lastName + ' ' + worker.name
          const walletLogRef = doc(db, "company", companySlug, "wallets", wallet.id, "logs", payout.walletLogId);
          const walletLogSnap = await getDoc(walletLogRef);
          const walletLog = walletLogSnap.exists() ? walletLogSnap.data() as WalletLog : null;

          if (wallet && walletLog && !walletLog.canceled) {
            const log: WalletLog = {
              time: new Date().getTime(),
              sum: payout.sum,
              author,
              wallet: payout.walletLogId,
              total: wallet.balance + payout.sum,
              comment: `Отмена списания согласно ведомости №${payout.number} (${name})`,
              type: 'cancel'
            }

            transaction.update(doc(db, "company", companySlug, "wallets", wallet.id), {
              balance: increment(payout.sum)
            })

            transaction.update(doc(db, "company", companySlug, "wallets", wallet.id, "logs", payout.walletLogId), {
              canceled: true
            })

            transaction.set(doc(db, "company", companySlug, "wallets",  wallet.id, "logs", uniqid()), log)
          }
        }

        transaction.update(doc(db, "company", companySlug, "payouts", payout.id), {
          closed: false,
        })

        transaction.update(doc(db, "company", companySlug, "workers", payout.worker), {
          balance: increment(payout.sum)
        })

      } else {
        console.log('Выплата еще не проведена!')
      }
    })
  }

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(initStartDate.getDate() - 14)
    setDates([initStartDate, initEndDate])
  }, [])

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    initEndDate.setMonth(initEndDate.getMonth() + 1)
    initEndDate.setDate(0)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(1)
    setDateCodesFromThisMonth([getDateCode(initStartDate), getDateCode(initEndDate)])
  }, [])

  useEffect(() => {
    if (payout?.id) {
      const editedPayuot = payouts.data?.find(p => p.id === payout?.id)
      if (editedPayuot?.closed) {
        setOpen(false)
        setPayout(undefined)
      }
    }
  }, [payout?.id, payouts.data])

  return (
    <Fragment>
      <Box padding={2} maxWidth={800} margin='0 auto'>
        <Loader open={isValidating || payouts.isValidating} />

        <Box marginTop={3} marginBottom={3} display='flex' justifyContent='space-between'>
          <Box maxWidth={240}>
            <MobileDateRangePicker
              cancelText={null}
              showToolbar={false}
              startText="Период с"
              endText="Период до"
              value={dates}
              disableMaskedInput
              disableFuture
              onChange={(newValue) => {
                setDates(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                  <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
                </React.Fragment>
              )}
            />
          </Box>

          {props.userCredentials.payouts_create &&
            <Box marginLeft={2}>
              <Button variant='outlined' color='primary' style={{height: '100%'}}
                onClick={() => setOpen(true)}
              >
                Создать ведемость
              </Button>
            </Box>
          }
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{display: {xs: 'none', md: 'table-cell'}, pr: 0}}>
                  <IconButton
                    onClick={() => setPrintModal(!printModal)}
                    disabled={!Object.keys(checked).some(id => checked[id])}
                  >
                    <Print/>
                  </IconButton>
                </TableCell>
                <TableCell>Номер</TableCell>
                <TableCell>Получатель</TableCell>
                <TableCell>Сумма, грн</TableCell>
                <TableCell>Счет для списания</TableCell>
                <TableCell/>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {payouts.data?.sort((a,b) => b.time - a.time)
                .map(payout => {
                  const worker = workersMap[payout.worker]
                  const workerName = worker?.lastName + ' ' + worker?.name
                  return (
                    <TableRow key={payout.id}>
                      <TableCell sx={{display: {xs: 'none', md: 'table-cell'}, pr: 0}}>
                        <Checkbox
                          checked={!!checked[payout.id]}
                          onChange={e => setChecked(p => ({...p, [payout.id]: e.target.checked}))}
                        />
                      </TableCell>
                      <TableCell>
                        <Box>№{payout.number}</Box>
                        <Box sx={{fontSize: '12px'}}>{format(payout.time, 'dd.MM.yyyy')}</Box>
                      </TableCell>
                      <TableCell>
                        <Stack direction='row' alignItems='center' spacing={{xs: 1}}>
                          <SmartAvatar name={workerName} photoURL={worker?.photoURL || ''}/>
                          <span>{workerName}</span>
                        </Stack>
                      </TableCell>
                      <TableCell>{payout.sum}</TableCell>
                      <TableCell>{payout.fromWallet ? walletsMap[payout.fromWallet].name : 'Без списания'}</TableCell>
                      <TableCell align='center'>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                          {!payout.closed && props.userCredentials.payouts_edit &&
                            <IconButton
                              onClick={() => {
                                setPayout(payout)
                                setOpen(true)
                              }}
                            ><Edit/></IconButton>
                          }
                          {payout.closed &&
                            <CheckCircleOutline color='success' sx={{mx: 1}} />
                          }
                          {worker.GPH && props.userCredentials.payouts_edit &&
                            <IconButton
                              onClick={() => setActPrintModal({
                                cheked: false,
                                worker: worker,
                                date: new Date(payout.time),
                                sum: payout.sum
                              })}
                            ><InsertDriveFile/></IconButton>
                          }
                        </Box>
                      </TableCell>
                      <TableCell align='center'>
                        {!payout.closed && props.userCredentials.payouts_close &&
                          <Button onClick={() => closePayout(payout)}>
                            Провести
                          </Button>
                        }
                        {payout.closed && props.userCredentials.payouts_cancel_close &&
                          <Button onClick={() => cancelClosePayout(payout)} color='error'>
                            Отменить
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
              }

              {payouts.data && !payouts.data.length &&
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box padding={3}>
                      <Typography variant='h5' color='textSecondary' align='center'>За этот период нет выплат</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {open &&
        <PayoutEditModal
          workers={availableWorkers}
          workersMap={workersMap}
          walletsMap={walletsMap}
          payout={payout}
          userCredentials={props.userCredentials}
          workShiftsFromThisMonth={workShiftsFromThisMonth.data || []}
          onClose={() => {
            setOpen(false)
            setPayout(undefined)
          }}
        />
      }

      {printModal && payouts.data &&
        <PayoutsPrintModal
          workersMap={workersMap}
          payouts={payouts.data}
          onClose={() => setPrintModal(false)}
          checked={checked}
        />
      }

      {actPrintModal && actPrintModal.cheked &&
        <ActPrintModal
          onClose={() => setActPrintModal(null)}
          worker={actPrintModal.worker}
          date={actPrintModal.date}
          sum={actPrintModal.sum}
        />
      }

      {actPrintModal && !actPrintModal.cheked &&
        <CheckActPrintModal
          setActProps={setActPrintModal}
          actProps={actPrintModal}
        />
      }
    </Fragment>
  );
}

export default Payouts;
