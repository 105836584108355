import { RecaptchaVerifier, signInWithPhoneNumber } from '@firebase/auth';
import { Box, Button, TextField, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { auth } from '../firebase';

declare global {
  interface Window {
    recaptchaVerifier: any
    confirmationResult: any
  }
}

const TerminalAuth = () => {
  const [phone, setPhone] = useState('+380');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [code, setCode] = useState('');

  const sendSms = () => {
    setClicked(true)
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response: any) => {
      }
    }, auth);

    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowCodeInput(true)
      }).catch((error) => {
        console.log("error", error)
      });
  }

  const login = () => {
    window.confirmationResult.confirm(code).then((result: any) => {
    }).catch((error: any) => {
      console.log("error", error)
    });
  }

  return (
    <Box sx={{px: 1, mx: 'auto', my: 3, display: 'flex', flexDirection: 'column', maxWidth: '320px'}}>
      <Typography variant='h6' sx={{my: 3}} align='center'>
        Авторизация
      </Typography>

      <TextField
        value={phone}
        onChange={e => {
          const val = e.target.value.trim()
          if (/^[+]{0,1}[0-9]{3,12}$/.test(val)) {
            setPhone('+380' + val.slice(4))
          }
        }}
        label='Введите номер телефона'
        disabled={clicked}
        sx={{my: 2}}
      />

      <Button
        variant='contained'
        onClick={sendSms}
        id='sign-in-button'
        disabled={phone.length !== 13}
        sx={{my: 2}}
        size='large'
      >
        Отправить СМС
      </Button>

      {showCodeInput &&
        <Fragment>
          <TextField
            value={code}
            onChange={e => {
              const val = e.target.value.trim()
              val.length < 7 && setCode(e.target.value.trim())
            }}
            label='Введите код из СМС'
            sx={{my: 2}}
          />

          <Button
            variant='contained'
            onClick={login}
            disabled={code.length !== 6}
            sx={{my: 2}}
          >
            Войти
          </Button>
        </Fragment>
      }
    </Box>
  )
}

export default TerminalAuth
