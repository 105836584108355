import { doc, increment, runTransaction } from '@firebase/firestore';
import { CheckCircleOutline, Close, DateRange, Edit } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Document, useCollection } from 'swr-firestore-v9';
import { Params, TechMap, UserCredentials, WorkShift, Worker } from '../../apiTypes';
import Loader from '../../components/Loader';
import SmartAvatar from '../../components/SmartAvatar';
import { Map } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';
import { getDateCode, getDateFromDateCode } from '../../utils';

interface Props {
  userCredentials: UserCredentials
}

function DailyJournal(props: Props) {
  const { companySlug } = useParams<Params>()
  const { userCredentials } = props
  const history = useHistory()
  const params = (new URL(window.location.href)).searchParams
  const initDateCode = Number(params.get('date'))
  const initDate = initDateCode ? getDateFromDateCode(initDateCode) : new Date()
  const [date, setDate] = useState<Date>(initDate)
  const closeAllRef = useRef<HTMLButtonElement>()
  const dateCode = getDateCode(date)

  const workShifts = useCollection<WorkShift>(`company/${companySlug}/workShifts`, {
    listen: true,
    where: [
      ['dateCode', '==', dateCode],
    ],
  })

  const { workersMap, techMapsMap, isValidating } = useAdmin()

  const closeAll = async () => {
    if (workShifts.data && !isValidating && !workShifts.loading && closeAllRef.current) {
      closeAllRef.current.disabled = true

      const promises = workShifts.data.map(async (ws) => {
        if (!ws.closed) {
          const techMap = techMapsMap[ws.techMap]
          const worker = workersMap[ws.worker]
          if (!worker || !techMap) return

          const plannedRate = ws.plannedRate ?? (worker.trainee ? techMap.traineeRate : techMap.rate)
          const rate = ws.rate ?? plannedRate

          const changes: Partial<WorkShift> = {
            rate,
            plannedRate,
            closed: true,
          }

          await runTransaction(db, async (transaction) => {
            const workShiftDoc = await transaction.get(doc(db, "company", companySlug, "workShifts", ws.id));

            if (!workShiftDoc.exists()) {
              return
            }

            const workShiftFormDB = workShiftDoc.data() as Document<WorkShift>

            if (!workShiftFormDB.closed) {
              transaction.update(doc(db, "company", companySlug, "workShifts", ws.id), changes);
              transaction.update(doc(db, "company", companySlug, "workers", worker.id), {
                balance: increment(rate)
              })
            }
          })
        }
      })

      await Promise.resolve(promises)
    }
  }

  useEffect(() => {
    history.replace({
      pathname: window.location.pathname,
      search: `?date=${dateCode}`,
    })
  }, [dateCode])

  return (
    <Fragment>
      <Loader open={isValidating || workShifts.isValidating } />

      <Box padding={2} maxWidth={800} margin='0 auto'>
        <Box marginTop={3} marginBottom={3} display='flex' justifyContent='space-between'>
          <Box maxWidth={160}>
            <DatePicker
              disableMaskedInput
              label="Дата"
              value={date}
              onChange={(date) => date && setDate(date)}
              renderInput={(params) => <TextField {...params} size='small' />}
            />
          </Box>

          {userCredentials.journal &&
            <Button
              onClick={() => history.push(`/${companySlug}/journal`)}
              startIcon={<DateRange />}
            >
              Журнал смен
            </Button>
          }
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Тех. карта</TableCell>
                <TableCell>Сотрудник</TableCell>
                <TableCell align='center'>Ставка, грн</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {techMapsMap && workersMap && workShifts.data && workShifts.data
                .map(ws => (
                  <Row
                    key={ws.id}
                    workShift={ws}
                    techMapsMap={techMapsMap}
                    workersMap={workersMap}
                    userCredentials={userCredentials}
                  />
                ))
              }

              {!workShifts.data?.length &&
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box padding={3}>
                      <Typography variant='h5' color='textSecondary' align='center'>
                        На этот день не назначено смен
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>

        {userCredentials.daily_journal_close_shifts && workShifts.data?.some(ws => !ws.closed) &&
          <Button
            variant='outlined'
            sx={{ my: 2 }}
            onClick={closeAll}
            ref={(el) => closeAllRef.current = el || undefined}
          >
            Закрыть все смены
          </Button>
        }

      </Box>
    </Fragment>
  );
}

export default DailyJournal;

interface RowProps {
  workShift: Document<WorkShift>
  techMapsMap: Map<Document<TechMap>>
  workersMap: Map<Document<Worker>>
  userCredentials: UserCredentials
}

const Row = (props: RowProps) => {
  const { workShift, techMapsMap, workersMap, userCredentials } = props
  const { companySlug } = useParams<Params>()
  const [open, setOpen] = useState(false)
  const worker = workersMap[workShift.worker] || {}
  const workerName = worker.lastName + ' ' + worker.name
  const techMap = techMapsMap[workShift.techMap] || {}
  const techMapName = techMap ? techMap.object + ' - ' + techMap.name : workShift.techMap
  const rate = workShift.rate ?? (worker.trainee ? techMap.traineeRate : techMap.rate)
  const plannedRate = workShift.plannedRate ?? (worker.trainee ? techMap.traineeRate : techMap.rate)
  const closeRef = useRef<HTMLButtonElement>()

  const closeShift = async () => {
    if (!worker || !techMap || !closeRef.current) {
      return
    }

    const changes: Partial<WorkShift> = {
      rate,
      plannedRate,
      closed: true,
    }

    await runTransaction(db, async (transaction) => {
      const workShiftDoc = await transaction.get(doc(db, "company", companySlug, "workShifts", workShift.id));

      if (!workShiftDoc.exists()) {
        return
      }

      const workShiftFormDB = workShiftDoc.data() as Document<WorkShift>

      if (!workShiftFormDB.closed) {
        transaction.update(doc(db, "company", companySlug, "workShifts", workShift.id), changes);
        transaction.update(doc(db, "company", companySlug, "workers", worker.id), {
          balance: increment(rate)
        })
      }
    })
  }

  const cancelClose = async () => {
    if (!worker || !techMap || !closeRef.current) {
      return
    }

    await runTransaction(db, async (transaction) => {
      const workShiftDoc = await transaction.get(doc(db, "company", companySlug, "workShifts", workShift.id));

      if (!workShiftDoc.exists()) {
        return
      }

      const workShiftFormDB = workShiftDoc.data() as Document<WorkShift>

      if (workShiftFormDB.closed) {
        transaction.update(doc(db, "company", companySlug, "workShifts", workShift.id), { closed: false });
        transaction.update(doc(db, "company", companySlug, "workers", worker.id), {
          balance: increment(-rate)
        })
      }
    })
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography> {techMap.object} </Typography>
            <Typography variant='caption'>
              {techMap.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SmartAvatar name={workerName} photoURL={worker?.photoURL} sx={{ mr: 1 }}/>
            <div>
              <div>{workerName}</div>

              {workShift.comment &&
                <Typography variant='caption' color='#555'>
                  {workShift.comment}
                </Typography>
              }
            </div>
          </Box>
        </TableCell>
        <TableCell align='center'>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography color={rate !== plannedRate ? 'warning.dark' : 'text.primary'}>
              {rate}
            </Typography>
            {rate !== plannedRate &&
              <Typography variant='caption' color='warning.main' >
                {`${rate > plannedRate ? '+' : ''}${rate - plannedRate}`}
              </Typography>
            }
          </Box>
        </TableCell>
        <TableCell align='center'>
          {workShift.closed
            ? <CheckCircleOutline color='success' />
            : <IconButton onClick={() => setOpen(true)}><Edit /></IconButton>
          }
        </TableCell>
        <TableCell align='center'>
          {!workShift.closed && userCredentials.daily_journal_close_shifts &&
            <Button onClick={closeShift} ref={el => closeRef.current = el || undefined}>
              Закрыть смену
            </Button>
          }
          {workShift.closed && userCredentials.daily_journal_cancel_close_shifts &&
            <Button color='inherit' onClick={cancelClose} ref={el => closeRef.current = el || undefined}>
              Отменить закрытие
            </Button>
          }
        </TableCell>
      </TableRow>

      {open &&
        <EditModal
          workShift={workShift}
          techMapName={techMapName}
          plannedRate={plannedRate}
          workerName={workerName}
          onClose={() => setOpen(false)}
          userCredentials={userCredentials}
        />
      }
    </Fragment>
  )
}

interface EditModalProps {
  workShift: Document<WorkShift>
  techMapName: string
  plannedRate: number
  workerName: string
  onClose: () => void
  userCredentials: UserCredentials
}

const EditModal = (props: EditModalProps) => {
  const { workShift, techMapName, workerName, plannedRate, onClose, userCredentials } = props
  const { companySlug } = useParams<Params>()
  const [rate, setRate] = useState(workShift.rate || plannedRate)
  const [comment, setComment] = useState(workShift.comment)

  const saveChanges = async () => {
    const changes: Partial<WorkShift> = {
      rate,
      comment
    }
    await runTransaction(db, async (transaction) => {
      const workShiftDoc = await transaction.get(doc(db, "company", companySlug, "workShifts", workShift.id));

      if (!workShiftDoc.exists()) {
        return
      }

      const workShiftFormDB = workShiftDoc.data() as Document<WorkShift>

      if (!workShiftFormDB.closed) {
        transaction.update(doc(db, "company", companySlug, "workShifts", workShift.id), changes);
      }
    })
    onClose()
  }

  return (
    <Dialog onClose={onClose} open maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {`${techMapName}: ${workerName}`}
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <TextField
          fullWidth
          label='Ставка, грн'
          type='number'
          value={String(rate)}
          required
          onChange={e => userCredentials.daily_journal_edit_rates && setRate(Number(e.target.value))}
          disabled={!userCredentials.daily_journal_edit_rates}
          helperText={ `Плановая ставка: ${plannedRate} грн` + (rate !== plannedRate
              ? `; Корректировка: ${rate > plannedRate ? '+' : ''}${rate - plannedRate} грн;`
              : '')
          }
          sx={{ mb: 2 }}
        />

        <TextField
          multiline
          fullWidth
          label='Комментарий'
          value={comment}
          required={rate !== plannedRate}
          error={rate !== plannedRate && !comment}
          onChange={e => setComment(e.target.value)}
          helperText={rate !== plannedRate && !comment ? 'Обязательное поле' : undefined}
        />
      </DialogContent>

      <DialogActions>
        <Button
          disabled={rate !== plannedRate && !comment}
          onClick={saveChanges}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}
