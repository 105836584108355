import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDocument } from 'swr-firestore-v9';
import { User } from '../../apiTypes';
import Loader from '../../components/Loader';
import { credentialsKeys, credentialsLabels, managerCredentials } from '../../constants';

interface Params {
  companySlug: string
}

interface EditUserProps {
  email: string
}

const EditUser = (props: EditUserProps) => {
  const { companySlug } = useParams<Params>();
  const user = useDocument<User>(`company/${companySlug}/users/${props.email}`)
  const initialCredentials = user.data?.credentials;
  const initialPhone = user.data?.phone;
  const initialDisplayName = user.data?.displayName;

  const [phone, setPhone] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [credentials, setCredentials] = useState(managerCredentials)
  const [isUpdated, setIsUpdated] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.checked });
    !isUpdated && setIsUpdated(true)
  };

  const saveChanges = async () => {
    setLoading(true)
    await user.update({phone, credentials, displayName})
    await user.mutate()
    setIsUpdated(false)
    setLoading(false)
  }

  useEffect(() => {
    initialPhone && setPhone(initialPhone)
  }, [initialPhone])

  useEffect(() => {
    initialDisplayName && setDisplayName(initialDisplayName)
  }, [initialDisplayName])

  useEffect(() => {
    initialCredentials && setCredentials(initialCredentials)
  }, [initialCredentials])

  return (
    <div>
      <Loader open={loading} />
      <Box marginBottom={4}>
        <TextField
          fullWidth
          required
          id="user-name"
          label="Имя пользователя"
          variant="outlined"
          value={displayName}
          onChange={e => {
            setDisplayName(e.target.value)
            !isUpdated && setIsUpdated(true)
          }}
          error={!displayName}
        />
      </Box>

      <Box marginBottom={4}>
        <TextField
          fullWidth
          id="phone-number"
          label="Номер телефона"
          placeholder="+380123456789"
          variant="outlined"
          value={phone}
          onChange={e => {
            if (/^[0-9+]{0,13}$/.test(e.target.value)) {
              setPhone(e.target.value)
              !isUpdated && setIsUpdated(true)
            }
          }}
        />
      </Box>

      {credentials &&
        <Box marginBottom={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Права доступа</FormLabel>
            <FormGroup>
              {credentialsKeys.map((key) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      key={key}
                      checked={credentials[key]}
                      onChange={handleChange}
                      name={key}
                      // disabled={user.data?.type === 'manager'}
                    />
                  }
                  label={credentialsLabels[key]}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      }

      <Button fullWidth variant='contained' color='primary' disabled={!isUpdated} onClick={saveChanges}>
        Сохранить изменения
      </Button>
    </div>
  )
}

export default EditUser;
