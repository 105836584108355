import { ArrowRightAlt, Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { ArchivedStoreLog, Consumable } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import { useAdmin } from '../../contexts/admin.context';


interface CellProps {
  total: number
  logs: ArchivedStoreLog[]
  consumable: Document<Consumable>
  average?: number
  type?: 'day' | 'store'
}

const ConsumablesReportCell = (props: CellProps) => {
  const { logs, total, consumable, type = 'day', average } = props
  const { storesMap } = useAdmin()
  const [open, setOpen] = useState(false)
  const marked = !!average && (average - total < -average || average - total > average)

  return (
    <Fragment>
      <Box
        sx={{
          cursor: 'pointer',
          color: total === 0
            ? '#aaa'
            : marked ? 'error.main' : 'inherit'
      }}
        onClick={() => logs.length && setOpen(true)}
      >
        {total}
      </Box>

      {open &&
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
          <DialogTitle>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <ConsumableTitle consumable={consumable}/>
              {type === 'day'
                ? format(new Date(logs[0].time), 'dd.MM.yyyy')
                : storesMap[logs[0].from]?.name || logs[0].from
              }
              <IconButton aria-label="close" onClick={() => setOpen(false)}>
                <Close />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            {logs.map(log => (
              <Paper
                variant='outlined'
                sx={{my: 2, py: 1, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                key={log.id}
              >
                <div>
                  <Typography variant='subtitle2' >
                    {format(new Date(log.time), type === 'day' ? 'HH:mm' : 'dd.MM HH:mm')} - {log.author}
                  </Typography>
                  <Typography sx={{display: 'flex', alignItems: 'center'}}>
                    {storesMap[log.from]?.name || log.from} <ArrowRightAlt/> {storesMap[log.to]?.name || log.to}
                  </Typography>
                </div>
                <div>
                  {log.residues[consumable.id]} {consumable.unit}
                </div>
              </Paper>
            ))}

          </DialogContent>
        </Dialog>
      }
    </Fragment>
  )
}

export default ConsumablesReportCell
