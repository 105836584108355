import { UserCredentialKey, WorkerGPH, WorkerPassport } from "./apiTypes";

export const managerCredentials = {
  users: true,
  users_edit_credentials: true,
  workers: true,
  workers_info: true,
  workers_edit_info: true,
  techMaps: true,
  techMaps_edit: true,
  techMaps_edit_rate: true,
  consumables: true,
  consumables_edit: true,
  stores: true,
  stores_edit: true,
  stores_taking: true,
  consumables_motion: true,
  consumables_move: true,
  consumables_spend: true,
  consumables_cancel_spend: true,
  consumables_orders: true,
  consumables_orders_create: true,
  consumables_orders_edit: true,
  consumables_orders_take: true,
  consumables_report: true,
  journal: true,
  journal_edit: true,
  journal_edit_previous_days: true,
  daily_journal: true,
  daily_journal_close_shifts: true,
  daily_journal_edit_rates: true,
  daily_journal_cancel_close_shifts: true,
  wallets: true,
  wallets_edit: true,
  wallets_private: true,
  wallets_create_actions: true,
  wallets_cancel_actions: true,
  payouts: true,
  payouts_create: true,
  payouts_edit: true,
  payouts_close: true,
  payouts_cancel_close: true,
  payouts_report: true,
  tasks: true,
  tasks_create: true,
  tasks_edit: true,
  tasks_change_status: true,
};

export const adminCredentials = {
  users: false,
  users_edit_credentials: false,
  workers: true,
  workers_info: true,
  workers_edit_info: true,
  techMaps: true,
  techMaps_edit: true,
  techMaps_edit_rate: false,
  consumables: true,
  consumables_edit: false,
  stores: true,
  stores_edit: false,
  stores_taking: false,
  consumables_motion: true,
  consumables_move: true,
  consumables_spend: true,
  consumables_cancel_spend: false,
  consumables_orders: true,
  consumables_orders_create: true,
  consumables_orders_edit: false,
  consumables_orders_take: true,
  consumables_report: false,
  journal: true,
  journal_edit: true,
  journal_edit_previous_days: false,
  daily_journal: true,
  daily_journal_close_shifts: true,
  daily_journal_edit_rates: true,
  daily_journal_cancel_close_shifts: false,
  wallets: true,
  wallets_edit: false,
  wallets_private: false,
  wallets_create_actions: true,
  wallets_cancel_actions: false,
  payouts: true,
  payouts_create: true,
  payouts_edit: false,
  payouts_close: true,
  payouts_cancel_close: false,
  payouts_report: false,
  tasks: true,
  tasks_create: true,
  tasks_edit: true,
  tasks_change_status: true,
};

type CredentialsLabels = {
  [key in UserCredentialKey]: string;
};

export const credentialsLabels: CredentialsLabels = {
  users: 'Страница "Пользователи"',
  users_edit_credentials: "Редактировать права доступа пользователей",
  workers: 'Страница "Сотрудники"',
  workers_info: "Просматривать детальную информация о сотрудниках",
  workers_edit_info: "Редактировать детальную информация о сотрудниках",
  techMaps: 'Страница "Технологические карты"',
  techMaps_edit: "Редактировать технологические карты",
  techMaps_edit_rate: "Редактировать ставки в технологических картах",
  consumables: 'Страница "Виды расходников"',
  consumables_edit: "Редактировать виды расходников",
  stores: 'Страница "Склады"',
  stores_edit: "Редактировать склады",
  stores_taking: "Проводить инвентаризацию",
  consumables_motion: 'Старница "Движение расходников"',
  consumables_move: "Перемещать расходники",
  consumables_spend: "Вносить расход",
  consumables_cancel_spend: "Отменять расход",
  consumables_orders: 'Старница "Поставки"',
  consumables_orders_create: "Создавать заказы на поставку",
  consumables_orders_edit: "Редакировать заказы на поставку",
  consumables_orders_take: "Принимать поставки",
  consumables_report: 'Страница "Отчет по расходниками"',
  journal: 'Старница "График смен"',
  journal_edit: "Редактировать график смен",
  journal_edit_previous_days: "Добавлять смены за прошлый период",
  daily_journal: 'Страница "Ежедневный журнал"',
  daily_journal_close_shifts: "Закрывать смены",
  daily_journal_edit_rates: "Редактировать ставки в сменах",
  daily_journal_cancel_close_shifts: "Отменять закрытие смен",
  wallets: 'Страница "Движение денежных средств"',
  wallets_edit: "Редактировать счета",
  wallets_private: "Доступ к приватным счетам",
  wallets_create_actions: "Вносить доход/расход/перевод",
  wallets_cancel_actions: "Отменять доход/расход ",
  payouts: 'Страница "Выплаты з/п"',
  payouts_create: "Создавать ведемести",
  payouts_edit: "Редактировать ведемости",
  payouts_close: "Проводить ведемости",
  payouts_cancel_close: "Отменять проведение ведомости",
  payouts_report: 'Страница "Отчет по ФОТ"',
  tasks: 'Страница "Задачи"',
  tasks_create: "Создавать задачи",
  tasks_edit: "Редактировать задачи",
  tasks_change_status: "Изменять статус задачи",
};

export const credentialsKeys = Object.keys(
  managerCredentials
) as UserCredentialKey[];

export const initialPassport: WorkerPassport = {
  inn: "",
  passportNumber: "",
  passportFrom: "",
  passportFistName: "",
  passportLastName: "",
  passportFatherName: "",
  passportCreated: "",
};

export const initialGPH: WorkerGPH = {
  docNumber: "",
  company: "",
  edrpou: "",
  seo: "",
};

type Translations = { [key: string]: string };

export const translations: Translations = {
  inn: "ИНН",
  passportNumber: "Серия и номер паспорта",
  passportFrom: "Кем выдан",
  passportFistName: "Имя",
  passportLastName: "Фамилия",
  passportFatherName: "Очество",
  passportCreated: "Дата выдачи",
  docNumber: "Номер договора",
  company: "Предприятие",
  move: "Перемещение",
  spend: "Списание",
  taking: "Инвентаризация",
  take: "Поставка",
  planned: "Запланировано",
  checking: "Ожидает проверки",
  active: "В процессе",
  closed: "Принято",
  deleted: "Удалено",
  edrpou: "ЄДРПОУ",
  seo: "Директор",
};

export const unitTypes = [
  { type: "шт", name: "штука" },
  { type: "г", name: "грамм" },
  { type: "мл", name: "миллилитр" },
  { type: "рул", name: "рулон" },
  { type: "пар", name: "пара" },
  { type: "м", name: "метр" },
  { type: "кг", name: "киллограмм" },
  { type: "л", name: "литр" },
];

export type Map<T = string> = {
  [id: string]: T;
};

export function getInitMap<T = string>(): Map<T> {
  return {};
}

export const vapidKey =
  "BIEEeDJ9phxm_MNlCORuBntrUbC51qBnREzK6COSuaI9IrzRgAX2LtDa_eCst1aRsaQu5LR9jayN9Zcxc57PYII";

export const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
