import { DatePicker } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { SetStateAction, useEffect } from 'react';
import { Document } from 'swr-firestore-v9';
import { Worker } from '../../apiTypes';
import { ActProps } from './Payouts';
var hryvnias = require('hryvnias').rubles

interface Props {
  onClose: () => void
  worker: Document<Worker>
  date: Date
  sum: number
}

const monthLocalize = [
  "січні", "лютому", "березні", "квітні", "травні", "червні", "липні", "серпні", "вересні", "жовтні", "листопаді", "грудні"
]

const ActPrintModal = (props: Props) => {
  const { onClose, worker, date, sum } = props
  const total = (sum/0.805).toFixed(2)
  const {passport} = worker;

  useEffect(() => {
    setTimeout(() => {
      window.print()
      onClose()
    }, 1000)
  }, [onClose])

  return (
    <Dialog onClose={onClose} open={true} maxWidth='md' fullWidth>
      <DialogContent dividers>
      <h3 style={{textAlign: 'center'}}>АКТ №{format(date, 'MM-yy')}</h3>
          <h3 style={{textAlign: 'center'}}>ПРИЙОМУ НАДАНИХ ПОСЛУГ </h3>
          <h3 style={{textAlign: 'center'}}>до Договору надання послуг {worker.GPH?.docNumber || '_______________'}</h3>
          <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0'}}>
            <span>м. Київ</span>
            <span>{format(date, 'dd.MM.yyyy')}р.</span>
          </div>
          <p style={{textAlign: 'left'}}>
            <strong>Товариство з обмеженою відповідальністю {worker.GPH?.company}</strong>, (код ЄДРПОУ {worker.GPH?.edrpou}), в особі директора {worker.GPH?.seo}, що діє на підставі Статуту, надалі – «ЗАМОВНИК», з однієї сторони, та
          </p>
          <p style={{textAlign: 'left'}}>
            <strong>{passport?.passportLastName} {passport?.passportFistName} {passport?.passportFatherName}</strong>, (РНОКПП {passport?.inn}, паспорт: {passport?.passportNumber}, виданий {passport?.passportFrom}), надалі – «ВИКОНАВЕЦЬ», з іншої сторони, склали цей Акт прийому наданих послуг до Договору надання послуг {worker.GPH?.docNumber || '№14-03-21 від 01.03.2021р.'} (далі Договір), про наступне:
          </p>

          <p style={{textAlign: 'left', margin: '15px 0'}}>
            1. Згідно умов Договору, в {monthLocalize[date.getMonth()]} ВИКОНАВЦЕМ були надані ЗАМОВНИКУ наступні послуги:
          </p>

          <table style={{border: '1px solid black'}}>
            <tr>
              <td style={{border: '1px solid black', padding: '10px'}}>
                №
              </td>
              <td style={{border: '1px solid black', padding: '10px'}}>
                Назва послуг
              </td>
              <td style={{border: '1px solid black', padding: '10px'}}>
                Вартість послуг, грн.
              </td>
            </tr>
            <tr>
              <td style={{border: '1px solid black', padding: '10px'}}>
                1
              </td>
              <td style={{textAlign: 'left', border: '1px solid black', padding: '10px'}}>
                Надання послуг з періодичного комплексного обслуговування по прибиранню приміщень об'єкта в {monthLocalize[date.getMonth()]} {date.getFullYear()}р.
              </td>
              <td style={{border: '1px solid black', padding: '10px'}}>
                {total}
              </td>
            </tr>
          </table>

          <p style={{textAlign: 'left', margin: '15px 0'}}>
            2. Загальна вартість послуг по Договору за період дії Договору. складає {total}грн. ({hryvnias(total)}) без ПДВ.
          </p>

          <p style={{textAlign: 'left', margin: '15px 0'}}>
            3. ЗАМОВНИК не має претензій до ВИКОНАВЦЯ, що до якості та обсягу наданих послуг.
          </p>

          <p style={{textAlign: 'left', margin: '15px 0 100px 0'}}>
            4. Цей Акт набуває чинності з моменту підписання його Сторонами Договору та є невід’ємною частиною Договору.
          </p>

          <div style={{display: 'flex', justifyContent: 'space-between', margin: '15px 0'}}>
            <div style={{textAlign: 'left'}}>
              <p><strong>ЗАМОВНИК</strong></p>
              <p style={{marginBottom: 20}}>Директор</p>
              <p> __________________ / {worker.GPH?.seo} / </p>
            </div>
            <div style={{textAlign: 'left'}}>
              <p style={{marginBottom: 38}}><strong>ВИКОНАВЕЦЬ</strong></p>
              <p> __________________ / {passport?.passportLastName} {passport?.passportFistName && passport.passportFistName.slice(0, 1)}.{passport?.passportFatherName && passport.passportFatherName.slice(0, 1)}. / </p>
            </div>
          </div>
      </DialogContent>
    </Dialog>
  )
}

export default ActPrintModal;


interface CheckActPrintModalProps {
  setActProps: (value: SetStateAction<ActProps | null>) => void
  actProps: ActProps
}

export const CheckActPrintModal = (props: CheckActPrintModalProps) => {
  const { setActProps, actProps } = props

  const onClose = () => setActProps(null)

  return (
    <Dialog onClose={onClose} open={true} maxWidth='xs' fullWidth>
      <DialogTitle>
        Данные для акта
      </DialogTitle>
      <DialogContent dividers>
        <DatePicker
          value={actProps.date}
          onChange={(date) => date && setActProps({...actProps, date: date})}
          label="Дата"
          renderInput={(params) => <TextField {...params} fullWidth sx={{my: 2}} />}
        />

        <TextField
          value={String(actProps.sum)}
          onChange={e => {
            if (Number(e.target.value) >= 0) {
              setActProps({...actProps, sum: Number(e.target.value)})
            }
          }}
          label='Сумма'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setActProps({...actProps, cheked: true})}>
          Печать
        </Button>
      </DialogActions>
    </Dialog>
  )
}
