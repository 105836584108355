import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';

const filter = createFilterOptions<string>();

interface Props {
  options: string[]
  value: string
  onChange: (value: string) => void
  label: string
}

export default function CreatableSelect(props: Props) {
  const {options, value, onChange, label} = props

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => onChange(newValue || '')}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        if (options.includes(option) || option === value) {
          return option
        } else if (option) {
          return `Добавить "${option}"`
        } else {
          return ''
        }
      }}
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  );
}
