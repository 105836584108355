import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Tab, Tabs, TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCollection } from 'swr-firestore-v9';
import { Params, UserCredentials, WorkShift } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import { getDateCode, incrementDateCode } from '../../utils';
import PayoutsByTechMapTable from './PayoutsByTechMapTable';
import PayoutsByWorkersTable from './PayoutsByWorkersTable';
import PayoutsChart from './PayoutsChart';

type TabName = 'chart' | 'table1' | 'table2'

interface Props {
  userCredentials: UserCredentials
}

function PayoutsReport(props: Props) {
  const { companySlug } = useParams<Params>()
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()])
  const [dateCodes, setDateCodes] = useState<number[]>([]);
  const [tab, setTab] = useState<TabName>('chart');
  const { workers, workersMap, allTechMapsByObject, techMapsMap, isValidating } = useAdmin();

  const workShifts = useCollection<WorkShift>(
    dates[0] && dates[1] ? `company/${companySlug}/workShifts` : null, {
      listen: true,
      where: [
        ['dateCode', '>=', getDateCode(dates[0])],
        ['dateCode', '<=',  getDateCode(dates[1])],
      ],
    }
  )

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabName) => {
    setTab(newValue);
  };

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    initEndDate.setMonth(initEndDate.getMonth() + 1)
    initEndDate.setDate(0)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(1)
    setDates([initStartDate, initEndDate])
  }, [])

  useEffect(() => {
    const startDateCode = getDateCode(dates[0])
    const endDateCode = getDateCode(dates[1])
    const newDateCodes: number[] = []
    for (let dc = startDateCode; dc <= endDateCode; dc = incrementDateCode(dc)) {
      newDateCodes.push(dc)
    }
    setDateCodes(newDateCodes)
  }, [dates])

  return (
    <Fragment>
      <Box sx={{ mx: 'auto', pt: 2, pb: 3, px: {md: 4, xs: 1} }}>
        <Loader open={isValidating || workShifts.isValidating} />

        <Box sx={{maxWidth: '240px', my: 1, mx: 'auto'}}>
          <MobileDateRangePicker
            cancelText={null}
            showToolbar={false}
            startText="Период с"
            endText="Период до"
            value={dates}
            disableMaskedInput
            onAccept={(newValue) => {
              setDates(newValue);
            }}
            onChange={() => {}}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
              </React.Fragment>
            )}
          />
        </Box>

        <Tabs value={tab} onChange={handleChangeTab} centered sx={{mb: 3}} allowScrollButtonsMobile>
          <Tab value='chart' label="График" sx={{flexShrink: 1}}/>
          <Tab value="table1" label="Отчет по тех картам" sx={{flexShrink: 1}}/>
          <Tab value="table2" label="Отчет по сотрудникам" sx={{flexShrink: 1}}/>
        </Tabs>

        {workShifts.data && tab === 'chart' && !!dateCodes.length && !!workShifts.data.length &&
          <Box sx={{ maxWidth: '1000px', mb: 3, mx: 'auto', p: { md: 4, xs: 0 }}}>
            <PayoutsChart
              dateCodes={dateCodes}
              workShifts={workShifts.data}
              techMapsMap={techMapsMap}
            />
          </Box>
        }

        {workShifts.data && allTechMapsByObject && tab === 'table1' &&
          <PayoutsByTechMapTable
            dateCodes={dateCodes}
            techMapsByObject={allTechMapsByObject}
            workShifts={workShifts.data}
            techMapsMap={techMapsMap}
            workersMap={workersMap}
          />
        }

        {workShifts.data && allTechMapsByObject && tab === 'table2' && dates[0] && dates[1] &&
          <PayoutsByWorkersTable
            workShifts={workShifts.data}
            techMapsMap={techMapsMap}
            workers={workers}
            dates={dates}
          />
        }
      </Box>
    </Fragment>
  )
}

export default PayoutsReport;
