import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Document } from 'swr-firestore-v9';
import { ArchivedStoreLog, Consumable } from '../../apiTypes';
import { useAdmin } from '../../contexts/admin.context';
import ConsumablesReportCell from './ConsumablesReportCell';


interface Props {
  storesLogs: ArchivedStoreLog[]
  consumables: Document<Consumable>[]
}

function ConsumablesByStores(props: Props) {
  const { storesLogs, consumables } = props
  const { stores } = useAdmin();
  const filteredStoresLogs = storesLogs.filter(l => l.type === 'spend' && !l.canceled)

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 240px)'}}>
      <Table
        stickyHeader
        size='small'
        sx={{
          width: 'auto',
          mx: 'auto',
          '& td': {padding: '4px'},
          '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
          '& th': {padding: '4px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
          '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3},
          '& tr': {verticalAlign: 'middle'},
          'tr:hover td:first-child': {backgroundColor: t => t.palette.grey[300]},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Расходник</TableCell>
            {stores.map(store => {
              return (
                <TableCell key={store.id} align='center'>
                  <Box sx={{p: '2px' }} >
                    {store.name}
                  </Box>

                  {/* <Typography color={(day === 'сб' || day === 'вс') ? 'orange' : 'default'}>
                    {day}
                  </Typography> */}
                </TableCell>
              )
            })}
            <TableCell>Всего</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {consumables.map(consumable => {
            const consumableLogs = filteredStoresLogs.filter(l => Object.keys(l.residues).includes(consumable.id))
            const total = consumableLogs.reduce((acc, l) => {
              return acc + l.residues[consumable.id]
            }, 0)

            return(
              <TableRow key={consumable.id} sx={{ verticalAlign: 'top', ":hover": {backgroundColor: t => t.palette.primary.light + '20'}}}>
                <TableCell align='right'>
                  <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }} >
                    {`${consumable.name}, ${consumable.unit}`}
                  </Typography>
                </TableCell>

                {stores.map(store => {
                  const filteredLogs = consumableLogs.filter(l => l.from === store.id)
                  const spendByStore = filteredLogs.reduce((acc, l) => {
                    return acc + l.residues[consumable.id]
                  }, 0)
                  return (
                    <TableCell key={store.id} align='center' sx={{ ':hover': { backgroundColor: t => t.palette.primary.light + '30' } }}>
                      <ConsumablesReportCell total={spendByStore} logs={filteredLogs} consumable={consumable} type='store'/>
                    </TableCell>
                  )
                })}
                <TableCell>
                  <Typography variant='subtitle2'>
                    {total}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

    </TableContainer>
  );
}

export default ConsumablesByStores;
