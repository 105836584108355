import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Button, TextField } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, useCollection } from 'swr-firestore-v9';
import { Params, Task, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { getInitMap } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import TaskEditModal from './TaskEditModal';
import TaskRow from './TaskRow';

interface Props {
  userCredentials: UserCredentials
}

function Tasks(props: Props) {
  const { companySlug } = useParams<Params>()
  const [open, setOpen] = useState(false)
  const [archive, setArchive] = useState(false)
  const [taskId, setTaskId] = useState<string>()
  const [period, setPeriod] = useState<DateRange<Date>>([new Date(), new Date()]);
  const { workers, workersMap, authorsMap, user } = useAdmin()
  const author = user?.id || ''
  const availableWorkers = workers.filter(w => !w.archived)

  const tasks = useCollection<Task>(`company/${companySlug}/tasks`, {
    listen: true,
    where: archive
      ? [
          ['time', '>', period[0]?.getTime() || 0],
          ['time', '<',  period[1]?.getTime() || 0],
        ]
      : [['status', '!=', 'closed']],
  })

  const displayedTasks = useMemo(() => archive
    ? tasks.data?.filter(t => t.status === 'closed').sort((a,b) => a.time - b.time) || []
    : tasks.data?.sort((a,b) => a.time - b.time) || []
  , [tasks.data, archive])

  const tasksMap = useMemo(
    () => (displayedTasks || []).reduce((acc, t) => {
      acc[t.id] = t
      return acc
    }, getInitMap<Document<Task>>()),
    [displayedTasks]
  )

  const openEditModal = (taskId: string) => {
    setTaskId(taskId)
    setOpen(true)
  }

  const closeEditModal = () => {
    setOpen(false)
    setTaskId(undefined)
  }

  useEffect(() => {
    const today = new Date()
    const initStartDate = new Date(today.setDate(today.getDate() - today.getDay() - 7))
    setPeriod([initStartDate, new Date()])
  }, [])

  return (
    <Fragment>
      <Box padding={2} maxWidth={500} margin='0 auto'>
        <Loader open={tasks.isValidating} />

        <Box marginTop={3} marginBottom={3} display='flex' justifyContent='space-between'>
          <Button
            onClick={() => setArchive(!archive)}
          >
            {archive ? 'Выйти из архива' : 'Перейти в архив'}
          </Button>

          {props.userCredentials.tasks_create && !archive &&
            <Box marginLeft={2}>
              <Button variant='outlined' color='primary'
                onClick={() => setOpen(true)}
              >
                Создать задачу
              </Button>
            </Box>
          }

          {archive &&
            <Box sx={{ maxWidth: '300px' }}>
              <MobileDateRangePicker
                cancelText={null}
                showToolbar={false}
                startText="Период с"
                endText="Период до"
                value={period}
                disableMaskedInput
                onAccept={(newValue) => {
                  setPeriod(newValue)
                }}
                onChange={() => {}}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                    <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
                  </React.Fragment>
                )}
              />
            </Box>
          }
        </Box>

        {authorsMap && displayedTasks
          .map(task => (
            <Box key={task.id} marginBottom={2}>
              <TaskRow
                authorsMap={authorsMap}
                task={task}
                userCredentials={props.userCredentials}
                openEditModal={openEditModal}
                author={author}
                workersMap={workersMap}
            />
            </Box>
          ))
        }
      </Box>

      {open &&
        <TaskEditModal
          onClose={closeEditModal}
          task={taskId ? tasksMap[taskId] : undefined}
          workers={availableWorkers}
          workersMap={workersMap}
          author={author}
        />
      }
    </Fragment>
  );
}

export default Tasks;
