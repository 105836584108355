import { Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';

interface Props {
  canClearState?: boolean
}

function NotFoundPage(props: Props) {
  const history = useHistory();

  return (
    <Container maxWidth='xs' sx={{ py: 5 }}>
      <Typography variant='h4' align='center' sx={{py: 2}}>
        Такой страницы не существует
      </Typography>

      {props.canClearState &&
        <Button
          fullWidth
          size='large'
          variant='contained'
          onClick={() => {
            localStorage.clear()
            history.push('/')
          }}
        >
          Назад
        </Button>
      }
    </Container>
  );
}

export default NotFoundPage;
