import { AssignmentTurnedIn, Edit, ExpandLess, ExpandMore, History } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Collapse, Divider, IconButton, ListItem, ListItemText } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable, Store, UserCredentials } from '../../apiTypes';
import IconTitleButton from '../../components/IconTitleButton';
import { useAdmin } from '../../contexts/admin.context';
import ConsumableResiduesCard from './ConsumableResiduesCard';
import EditStore from './EditStore';
import TakingModal from './TakingModal';


interface Props {
  store: Document<Store>
  userCredentials: UserCredentials
}

const StoreCard = (props: Props) => {
  const {userCredentials, store} = props
  const { name } = store
  const { consumables, consumablesByCategories } = useAdmin()
  const [expanded, setExpanded] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [takingModalIsOpen, setTakingModalIsOpen] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box marginTop={2}>

      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          title={name}
          action={
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show details"
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        />


        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider/>
          <CardContent>
            <Box marginBottom={3} display='flex'>
              <IconTitleButton
                icon={<History/>}
                title='История'
                onClick={() => {}}
              />
              {userCredentials.stores_taking &&
                <IconTitleButton
                  icon={<AssignmentTurnedIn />}
                  title='Инвентар-ция'
                  onClick={() => setTakingModalIsOpen(true)}
                />
              }
              {userCredentials.stores_edit &&
                <IconTitleButton
                  icon={<Edit/>}
                  title='Редактировать'
                  onClick={() => setEditModalIsOpen(true)}
                />
              }
            </Box>

            <Divider/>

            {Object.keys(consumablesByCategories).map(category => {
              const items: Document<Consumable>[] = consumablesByCategories[category]?.filter(c => store.residues[c.id])
              return (
                <ConsumableList
                  key={category}
                  items={items}
                  category={category}
                  store={store}
                />
              )
            })}

          </CardContent>
          {editModalIsOpen &&
            <EditStore store={store} open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)}/>
          }

          {takingModalIsOpen &&
            <TakingModal
              consumables={consumables}
              consumablesByCategories={consumablesByCategories}
              store={store}
              open={takingModalIsOpen}
              onClose={() => setTakingModalIsOpen(false)}
            />
          }
        </Collapse>
      </Card>
    </Box>
  )
}

export default StoreCard;

interface ConsumableListProps {
  items: Document<Consumable>[]
  category: string
  store: Document<Store>
}

const ConsumableList = (props: ConsumableListProps) => {
  const { items, category, store } = props
  const [open, setOpen] = useState(false)

  if (!items.length) {
    return null
  }

  return (
    <Fragment>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={category === 'withoutCategory' ? 'Без категории' : category} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {items.map(consumable => (
          <ConsumableResiduesCard
            key={consumable.id}
            consumable={consumable}
            qnt={store.residues[consumable.id]}
          />
        ))}
      </Collapse>
    </Fragment>
  )
}
