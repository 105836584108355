import { Search } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { UserCredentials, Worker } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import EditWorkerModal from './EditWorkerModal';
import WorkerCard from './WorkerCard';

interface Props {
  userCredentials: UserCredentials
}

function Workers(props: Props) {
  const [archived, setArchived] = useState(false)
  const { isValidating, workers } = useAdmin();
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const filterFunc = (worker: Worker) => {
    return (archived ? worker.archived : !worker.archived)
      && (worker.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      || worker.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }

  return (
    <Box padding={2} maxWidth={500} margin='0 auto'>
      <Loader open={isValidating} />

      <Box marginTop={2}>
        <FormControlLabel
          control={ <Checkbox checked={archived} onChange={e => setArchived(e.target.checked)} /> }
          label="Отобразить архив"
        />
      </Box>

      <Box marginTop={1} marginBottom={5} display='flex' justifyContent='space-between'>
        <TextField variant="outlined" label="Поиск" size='small'
          InputProps={{ endAdornment: <Search /> }}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />

        {props.userCredentials.workers_edit_info &&
          <Box marginLeft={2}>
            <Button variant='outlined' color='primary' style={{height: '100%'}}
              onClick={() => setOpen(true)}
            >
              Добавить сотрудника
            </Button>
          </Box>
        }
      </Box>

      {!!workers.length &&
        workers.filter(filterFunc).map(worker => (
          <Box key={worker.id} marginBottom={2}>
            <WorkerCard worker={worker} userCredentials={props.userCredentials}/>
          </Box>
        ))
      }

      {props.userCredentials.workers_edit_info && open &&
        <EditWorkerModal open={open} onClose={() => setOpen(false)}/>
      }
    </Box>
  );
}

export default Workers;
