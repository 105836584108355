import { Container, Typography } from '@mui/material';
import React from 'react';

function WelcomePage() {

  return (
    <Container maxWidth='xs' sx={{ py: 5 }}>
      <Typography variant='h4' align='center' sx={{py: 2}}>
        Добро пожаловать в админ-панель!
      </Typography>
    </Container>
  );
}

export default WelcomePage;
