import { TechMapAction } from "../../apiTypes"

export const getTime = (actions: TechMapAction[], index: number, startTime: string) => {
  if (!startTime) {
    return ['--:--', '--:--']
  }
  const start = index === 0
    ? startTime
    : actions.slice(0, index).map(a => a.duration).reduce(reducer, startTime)
  const end = addTime(start, actions[index].duration)

  return [start, end]
}

const reducer = (accumulator: string, currentValue: number) => addTime(accumulator, currentValue)

export const addTime = (start: string, adding: number) => {
  const [hours, minutes] = start.split(':').map(i => Number(i))
  let newHours = hours + Math.floor((minutes + adding)/60)
  if (newHours > 23) {
    newHours = newHours%24
  }
  const newMinutes = (minutes + adding)%60
  const hoursStr = `0${newHours}`.slice(-2)
  const minutesStr = `0${newMinutes}`.slice(-2)

  return `${hoursStr}:${minutesStr}`
}
