import { ArrowBack, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Collapse, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Document, useCollection } from 'swr-firestore-v9';
import { Author, Consumable, Params, Store, StoreLog, TechMap, Worker } from '../../apiTypes';
import { getInitMap, Map, translations } from '../../constants';
import { consumablesReducer } from '../../pages/ConsumablesTypes/ConsumablesTypes';
import ConsumableResiduesCard from '../../pages/Stores/ConsumableResiduesCard';
import TerminalStoresEventModal from './TerminalStoresEventModal';
import TerminalStoresResidues from './TerminalStoresResidues';

interface Props {
  techMap: Document<TechMap>
  authorsMap: Map<Author>
  worker: Document<Worker>
}

const TerminalStores = (props: Props) => {
  const { techMap, worker } = props
  const { companySlug } = useParams<Params>();
  const [storeId, setStoreId] = useState<string | null>(null)
  const [eventModal, setEventModal] = useState(false)
  const [residuesModal, setResiduesModal] = useState(false)

  const consumables = useCollection<Consumable>(`company/${companySlug}/consumables`, {listen: true})
  const stores = useCollection<Store>(`company/${companySlug}/stores`, {listen: true})
  const logs = useCollection<StoreLog>(`company/${companySlug}/storesLogs`, {
    listen: true,
    where: [
      ['date', '==', format(new Date(), 'dd.MM.yyyy')],
    ],
  })

  const storesMap = useMemo(
    () => (stores.data || []).reduce((acc, s) => {
      acc[s.id] = s
      return acc
    }, getInitMap<Document<Store>>()),
    [stores.data]
  )

  const consumablesByCategories = useMemo(
    () => consumables.data?.reduce(consumablesReducer, {withoutCategory: []}),
    [consumables.data]
  )

  const filteredLogs = useMemo(
    () => logs.data?.filter(l => (l.from === storeId || l.to === storeId)).reverse() || [],
    [logs.data, storeId]
  )

  useEffect(() => {
    if (!storeId && techMap.stores.length === 1) {
      setStoreId(techMap.stores[0])
    }
  }, [setStoreId, storeId, techMap.stores])

  if (!storeId && !!techMap.stores.length) {
    return (
      <div>
        <Typography variant='h6' sx={{my: 2}} align='center'>
          Выберите склад
        </Typography>

        {techMap.stores.map(storeId => (
          <Button
            key={storeId}
            onClick={() => setStoreId(storeId)}
            variant='outlined'
            fullWidth
            size='large'
            sx={{my: 1}}
          >
            {storesMap[storeId]?.name}
          </Button>
        ))}
      </div>
    )
  }

  if (!techMap.stores.length) {
    return (
      <div>
        <Typography variant='h6' sx={{my: 2}} align='center'>
          Расходники
        </Typography>

        <Typography variant='h5' sx={{my: 2, color: t => t.palette.text.secondary}} align='center'>
          Для этой тех. карты нет доступных складов
        </Typography>
      </div>
    )
  }

  return (
    <Box>
      <Box sx={{my: 2, display: 'flex', alignItems: 'center'}} >
        {techMap.stores.length > 1 &&
          <IconButton onClick={() => setStoreId(null)} sx={{mr: 2}}>
            <ArrowBack/>
          </IconButton>
        }

        <Typography variant='h6' align='center' sx={{width: techMap.stores.length > 1 ? 'auto' : '100%'}}>
          Склад: {!!storeId && storesMap[storeId]?.name}
        </Typography>
      </Box>

      {techMap.writeOffZones.length &&
        <Button
          onClick={() => setEventModal(true)}
          variant='contained'
          fullWidth
          sx={{my: 1}}
          size='large'
        >
          Внести расход
        </Button>
      }

      <Button
        onClick={() => setResiduesModal(true)}
        variant='contained'
        fullWidth
        sx={{my: 1}}
        size='large'
      >
        Смотреть остатки
      </Button>

      <Typography variant='h6' align='center' sx={{mt: 3, mb: 1, fontSize: '18px'}}>
        История
      </Typography>

      {filteredLogs?.length === 0 &&
        <Typography variant='h6' align='center' sx={{ color: t => t.palette.text.secondary}}>
          Сегодня еще не было событий
        </Typography>
      }

      {consumablesByCategories && filteredLogs.map(log => (
        <LogsCard
          key={log.id}
          log={log}
          storesMap={storesMap}
          consumablesByCategories={consumablesByCategories}
        />
      ))}

      {eventModal && storeId && storesMap[storeId] && consumablesByCategories &&
        <TerminalStoresEventModal
          store={storesMap[storeId]}
          consumables={consumables.data || []}
          consumablesByCategories={consumablesByCategories}
          onClose={() => setEventModal(false)}
          techMap={techMap}
          worker={worker}
        />
      }

      {residuesModal && storeId && storesMap[storeId] && consumablesByCategories &&
        <TerminalStoresResidues
          store={storesMap[storeId]}
          consumablesByCategories={consumablesByCategories}
          onClose={() => setResiduesModal(false)}
        />
      }
    </Box>
  )
}

export default TerminalStores

interface CardProps {
  log: Document<StoreLog>
  storesMap: Map<Document<Store>>
  consumablesByCategories: {
    [category: string]: Document<Consumable>[]
  }
}

const LogsCard = (props: CardProps) => {
  const { log, storesMap, consumablesByCategories } = props
  const [open, setOpen] = useState(false)

  return (
    <Card
      variant="outlined"
      sx={{borderColor: t => t.palette.grey[300], mb: 2}}
    >
      <CardHeader
        onClick={() => setOpen(!open)}
        sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
        title={translations[log.type] + (log.canceled ? ' (отменено)' : '') + ' | ' + log.author}
        avatar={format(new Date(log.time), 'HH:mm')}
        subheader={(storesMap[log.from]?.name || log.from) + ' → ' + (storesMap[log.to]?.name || log.to)}
        action={
          <IconButton
            aria-expanded={open}
            aria-label="show details"
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          {consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
            const items = consumablesByCategories[category].filter(c => log.residues[c.id] !== undefined)

            if (!items.length) {
              return null
            }

            return (
              <Box marginBottom={2} key={category}>
                <Typography variant='caption' color='textSecondary'>
                  {category === 'withoutCategory' ? 'Без категории' : category}
                </Typography>

                {items.map(consumable => (
                  <ConsumableResiduesCard
                    key={consumable.id}
                    consumable={consumable}
                    qnt={log.residues[consumable.id] || 0}
                    prevQnt={log.prevResidues ? (log.prevResidues[consumable.id] || 0) : undefined}
                  />
                ))}
              </Box>
            )
          })}
        </CardContent>
      </Collapse>
    </Card>
  )
}
