import { Search } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Store, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import ConsumablesStock from './ConsumablesStock';
import EditStore from './EditStore';
import StoreCard from './StoreCard';

type TabName = 'table1' | 'table2'

interface Props {
  userCredentials: UserCredentials
}

function Stores(props: Props) {
  const { stores, isValidating } = useAdmin();
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState<TabName>('table1');

  const filterFunc = (store: Store) => {
    return store.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: TabName) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ mx: 'auto', pt: 2, pb: 3, px: {md: 4, xs: 1} }}>
      <Loader open={isValidating} />

      <Tabs value={tab} onChange={handleChangeTab} centered sx={{mb: 3}} allowScrollButtonsMobile>
        <Tab value="table1" label="Склады" sx={{flexShrink: 1}}/>
        <Tab value='table2' label="Остатки" sx={{flexShrink: 1}}/>
      </Tabs>

      {tab === 'table2' &&
        <ConsumablesStock />
      }

      {tab === 'table1' &&
        <Box padding={2} maxWidth={500} margin='0 auto'>
          <Box marginBottom={5} display='flex' justifyContent='space-between'>
            <TextField variant="outlined" label="Поиск" size='small'
              InputProps={{ endAdornment: <Search /> }}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />

            {props.userCredentials.stores_edit &&
              <Box marginLeft={2}>
                <Button variant='outlined' color='primary' style={{height: '100%'}}
                  onClick={() => setOpen(true)}
                >
                  Добавить склад
                </Button>
              </Box>
            }
          </Box>

          {!!stores.length &&
            stores.filter(filterFunc).map(store => (
              <Box key={store.id} marginBottom={2}>
                <StoreCard store={store} userCredentials={props.userCredentials}/>
              </Box>
            ))
          }

          {props.userCredentials.stores_edit && open &&
            <EditStore open={open} onClose={() => setOpen(false)}/>
          }
        </Box>
      }

    </Box>
  );
}

export default Stores;
