import { Close } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { deleteDoc, deleteField, doc, setDoc, updateDoc } from 'firebase/firestore';
import { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Consumable, Params } from '../../apiTypes';
import Confirmation from '../../components/Confirmation';
import CreatableSelect from '../../components/CreatableSelect';
import UploadImg from '../../components/UploadImg';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';

interface Props {
  open: boolean
  onClose: () => void
  consumable?: Document<Consumable>
}

const unitTypes = ["шт", "г", "мл", "рул", "пак", "пар", "м", "кг", "л"]
const packageTypes = ["уп", "рул", "ящ", "бут", "кан", "кг", "л"]

const EditConsumablesType = (props: Props) => {
  const { open, onClose, consumable } = props
  const { companySlug } = useParams<Params>();
  const { stores, consumables } = useAdmin();
  const [name, setName] = useState(consumable?.name || '')
  const [provider, setProvider] = useState(consumable?.provider || '')
  const [category, setCategory] = useState(consumable?.category || '')
  const [unit, setUnit] = useState(consumable?.unit || unitTypes[0])
  const [imageUrl, setImageUrl] = useState(consumable?.imageUrl || '')
  const [packageName, setPackageName] = useState(consumable?.packageName || 'none')
  const [qpp, setQpp] = useState(consumable?.qpp || 0)
  const [ean, setEan] = useState(consumable?.ean || '')
  const [disableSpend, setDisableSpend] = useState(consumable?.disableSpend || false)
  const [confirmOpen, setConfirmOpen] = useState(false);

  const categories = useMemo(() => {
    return Array.from(new Set(consumables.map(c => c.category))).filter(c => !!c)
  }, [consumables])

  const providers = useMemo(() => {
    return Array.from(new Set(consumables.map(c => c.provider))).filter(p => !!p)
  }, [consumables])

  const saveChanges = () => {
    const newConsumable = {
      name, provider, category, unit, imageUrl, packageName, qpp: qpp || null, ean, disableSpend
    }
    if (consumable?.id) {
      updateDoc(doc(db, "company", companySlug, "consumables", consumable.id), newConsumable)
    } else {
      setDoc(doc(db, "company", companySlug, "consumables", uniqid()), newConsumable)
    }
    onClose()
  }

  const deleteConsumable = async () => {
    if (consumable?.id) {
      stores.forEach(store => {
        updateDoc(doc(db, "company", companySlug, "stores", store.id), {
          [`residues.${consumable.id}`]: deleteField()
        })
      })
      deleteDoc(doc(db, "company", companySlug, "consumables", consumable.id))
      setConfirmOpen(false)
      onClose()
    }
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Вид расходного материала
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UploadImg imgUrl={imageUrl} setImgUrl={setImageUrl} folder={companySlug}/>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Название"
                variant="outlined"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} sm={6}>
              <CreatableSelect
                label="Категория"
                value={category}
                onChange={v => setCategory(v)}
                options={categories}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CreatableSelect
                label="Поставщик"
                value={provider}
                onChange={v => setProvider(v)}
                options={providers}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Eд. измерения *</InputLabel>
                <Select
                  value={unit}
                  onChange={e => setUnit(e.target.value as string)}
                  label="Eд. измерения *"
                >
                  {unitTypes.map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Упаковка *</InputLabel>
                <Select
                  value={packageName}
                  onChange={e => {
                    setPackageName(e.target.value as string)
                    if (e.target.value === 'none') {
                      setQpp(0)
                    }
                  }}
                  label="Упаковка *"
                >
                  <MenuItem value={'none'}>Нет</MenuItem>
                  {packageTypes.map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label={`${unit}. в упаковке`}
                variant="outlined"
                type='number'
                value={String(qpp)}
                required={packageName !== 'none'}
                disabled={packageName === 'none'}
                onChange={e => setQpp(Number(e.target.value))}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="consumable-ean"
                label="Артикул"
                variant="outlined"
                value={ean}
                onChange={e => setEan(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disableSpend}
                    onChange={e => setDisableSpend(e.target.checked)}
                    name='Запретить списание'
                  />
                }
                label='Запретить списание'
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {consumable?.id &&
            <Button onClick={() => setConfirmOpen(true)} color='secondary'>
              Удалить
            </Button>
          }
          <Button autoFocus onClick={saveChanges} color="primary" disabled={!name || !unit || !packageName || (packageName !== 'none' && !qpp)}>
            {consumable?.id ? 'Сохранить изменения' : 'Добавить вид расходного материала'}
          </Button>
        </DialogActions>
      </Dialog>

      <Confirmation
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title="Вы уверены, что хотите удалить этот вид расходного материала?"
        onSubmit={deleteConsumable}
      />
    </Fragment>
  )
}

export default EditConsumablesType;
