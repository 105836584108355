import { MoreVert } from "@mui/icons-material";
import { Box, Divider, IconButton, TextField, Typography } from "@mui/material";
import { MouseEvent } from "react";
import { TechMapAction } from "../../apiTypes";

interface Props {
  start: string;
  end: string;
  action: TechMapAction;
  index: number;
  dayIndex: number | null;
  changeAction: (
    index: number,
    name: keyof TechMapAction,
    value: string,
    dayIndex: number | null
  ) => void;
  onMoreClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const Action = (props: Props) => {
  const { start, end, action, changeAction, index, dayIndex, onMoreClick } =
    props;

  return (
    <Box
      marginTop={1}
      marginBottom={1}
      display="flex"
      alignItems="center"
      flexWrap="wrap"
    >
      <Box width={38} padding={1}>
        <Typography variant="body2" color="textSecondary">
          {start}
        </Typography>
        <Box paddingLeft={1} paddingRight={1}>
          <Divider />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {end}
        </Typography>
      </Box>
      <Box padding={1}>
        <TextField
          style={{ width: 240 }}
          variant="outlined"
          label="Место"
          size="small"
          value={action.place}
          onChange={(e) =>
            changeAction(index, "place", e.target.value, dayIndex)
          }
        />
      </Box>
      <Box padding={1}>
        <TextField
          style={{ width: 240 }}
          variant="outlined"
          label="Комплекс работ"
          size="small"
          value={action.name}
          onChange={(e) =>
            changeAction(index, "name", e.target.value, dayIndex)
          }
        />
      </Box>
      <Box padding={1} flexGrow={1} flexShrink={1}>
        <TextField
          style={{ minWidth: 240, width: "100%" }}
          variant="outlined"
          label="Инструкция"
          size="small"
          multiline
          value={action.instructions}
          onChange={(e) =>
            changeAction(index, "instructions", e.target.value, dayIndex)
          }
        />
      </Box>
      <Box padding={1}>
        <TextField
          label="Время (мин)"
          type="number"
          size="small"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          style={{ width: 100 }}
          value={String(action.duration)}
          onChange={(e) => {
            if (Number(e.target.value) >= 0) {
              changeAction(index, "duration", e.target.value, dayIndex);
            }
          }}
        />
      </Box>
      <Box padding={1}>
        <IconButton onClick={onMoreClick}>
          <MoreVert />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Action;
