import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable, Store } from '../../apiTypes';
import ConsumableResiduesCard from '../../pages/Stores/ConsumableResiduesCard';

interface Props {
  store: Document<Store>
  consumablesByCategories: {
    [category: string]: Document<Consumable>[]
  }
  onClose: () => void
}

const TerminalStoresResidues = (props: Props) => {
  const { onClose, store, consumablesByCategories } = props

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {`Остатки по складу "${store.name}"`}

          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
            const items = consumablesByCategories[category].filter(c => store.residues[c.id] !== undefined)

            if (!items.length) {
              return null
            }

            return (
              <Box marginBottom={2} key={category}>
                <Typography variant='caption' color='textSecondary'>
                  {category === 'withoutCategory' ? 'Без категории' : category}
                </Typography>

                {items.map(consumable => (
                  <Box sx={{mb: 2}} key={consumable.id}>
                    <ConsumableResiduesCard
                      consumable={consumable}
                      qnt={store.residues[consumable.id] || 0}
                    />
                  </Box>
                ))}
              </Box>
            )
          })}
      </DialogContent>
    </Dialog>
  )
}

export default TerminalStoresResidues
