import { Avatar, Tooltip, TooltipProps } from '@mui/material'
import { SxProps } from '@mui/system'
import React, { useState } from 'react'


interface Props {
  name?: string
  photoURL?: string
  onClick?: () => void
  tooltipProps?: Partial<TooltipProps>
  hoverIcon?: JSX.Element
  borderColor?: string
  sx?: SxProps
}

const SmartAvatar = (props: Props) => {
  const { name = 'Неизвестный пользователь', photoURL, onClick, tooltipProps, hoverIcon, borderColor, sx } = props
  const [hover, setHover] = useState(false)

  return (
    <Tooltip title={name} placement='right' {...tooltipProps}>
      <Avatar
        alt={name}
        src={!hover && photoURL ? photoURL : undefined}
        onClick={onClick}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
          bgcolor: !photoURL ? stringToColor(name) : 'none',
          border: `2px solid ${borderColor || '#fff'}`,
          ...sx
        }}
        onMouseEnter={() => !!hoverIcon && setHover(true)}
        onMouseLeave={() => !!hoverIcon && setHover(false)}
        children={
          hover && hoverIcon
            ? hoverIcon
            : photoURL ? undefined : `${name.split(' ')[0]?.[0]}${name.split(' ')[1]?.[0]}`}
      />
    </Tooltip>
  )
}

export default SmartAvatar

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}
