import { RemoveCircle, SaveAlt } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { grey } from '@mui/material/colors'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { fromBlob } from 'image-resize-compress'
import React, { ChangeEvent, CSSProperties, FC } from 'react'
import uniqid from 'uniqid'
import { storage } from '../firebase'


interface Props {
  imgUrl: string
  setImgUrl: (ulr: string) => void
  folder: string
}

const UploadImg: FC<Props> = (props) => {
  const { imgUrl, setImgUrl, folder } = props

  const handleUploadImg = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const compressedFile = await fromBlob(file, 80, 400, 'auto', 'webp')
      const id = uniqid()
      const fileRef = ref(storage, `${folder}/${id}`);
      await uploadBytes(fileRef, compressedFile)
      const url = await getDownloadURL(fileRef)
      setImgUrl(url)
    }
  }

  const handleRemoveImg = async () => {
    setImgUrl('')
  }

  return (
    <Box width={120} height={120}>
      <div style={getStyles(imgUrl)}>
        {!imgUrl &&
          <Box textAlign='center' marginTop='10px'>
            <input
              accept="image/*"
              id={`upload`}
              style={{display: 'none'}}
              type="file"
              onChange={handleUploadImg}
            />
            <label htmlFor={`upload`}>
              <IconButton
                color='inherit'
                aria-label={`upload`}
                component="span"
                className='UploadLogoBlock__uploadButtonIcon'
              >
                <SaveAlt />
              </IconButton>
            </label>
          </Box>
        }

        {!imgUrl &&
          <Box textAlign='center' color={grey[600]}>
            {`Загрузите картинку`}
          </Box>
        }

        {imgUrl &&
          <Box position='relative' top="-17px" right="-17px">
            <IconButton
              size='small'
              onClick={handleRemoveImg}
              color='secondary'
            >
              <RemoveCircle />
            </IconButton>
          </Box>
        }
      </div>
    </Box>
  )
}

export default UploadImg

const getStyles = (imageUrl: string): CSSProperties => {
  if (imageUrl) {
    return {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: '5px',
      border: '1px dashed #777',
      textAlign: 'right',
      width: '100%',
      height: '100%'
    }
  }

  return {
    backgroundImage: 'none',
    borderRadius: '5px',
    border: '1px dashed #777',
    width: '100%',
    height: '100%'
  }
}
