import { doc, increment, setDoc, updateDoc } from '@firebase/firestore';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Autocomplete, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router';
import { Document, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Consumable, Order, Params, Store, StoreLog, User, UserCredentials } from '../../apiTypes';
import Confirmation from '../../components/Confirmation';
import { Map, translations } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import { auth, db } from '../../firebase';
import ConsumableResiduesCard from '../Stores/ConsumableResiduesCard';
import OrderEditModal from './OrderEditModal';

interface Props {
  order: Document<Order>
  consumablesByCategories: {
    [category: string]: Document<Consumable>[]
  }
  userCredentials: UserCredentials
  consumables: Document<Consumable>[]
  totalResidues: Map<number>
}

const OrderRow = (props: Props) => {
  const { order, consumablesByCategories, userCredentials, consumables, totalResidues } = props
  const { companySlug } = useParams<Params>()
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [take, setTake] = useState(false)
  const [edit, setEdit] = useState(false)
  const [storeId, setStoreId] = useState<string | null>(null)
  const [store, setStore] = useState<Document<Store> | null>(null)
  const [userAuth] = useAuthState(auth);
  const user = useDocument<User>(userAuth ? `company/${companySlug}/users/${userAuth.email}` : null)
  const userName = user.data?.displayName
  const { stores, storesMap } = useAdmin()

  const handleDelete = () => {
    updateDoc(doc(db, "company", companySlug, "orders", order.id), { status: 'deleted' })
    setConfirm(false)
  }

  const handleTake = () => {
    if (store) {
      const date = new Date()
      const storeChanges: {[key: string] : any} = {}

      const log: StoreLog = {
        date: format(date, 'dd.MM.yyyy'),
        time: date.getTime(),
        author: userName || 'Неизвестный пользователь',
        type: 'take',
        from: order.provider,
        to: store.id,
        residues: order.residues,
      }

      Object.keys(order.residues).forEach(id => {
        const residuesKey = `residues.${id}`
        storeChanges[residuesKey] = increment(order.residues[id])
      })

      setDoc(doc(db, "company", companySlug, "storesLogs", uniqid()), log)
      updateDoc(doc(db, "company", companySlug, "stores", store.id), storeChanges)
      updateDoc(doc(db, "company", companySlug, "orders", order.id), { status: 'closed' })

      setTake(false)
    }
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell style={{paddingRight: 0}}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {new Date(order.time).toLocaleDateString()}
        </TableCell>
        <TableCell>{order.number}</TableCell>
        <TableCell>{order.provider}</TableCell>
        <TableCell>{translations[order.status]}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{padding: 0}}></TableCell>
        <TableCell colSpan={5} style={{padding: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginLeft={2} marginRight={2} marginBottom={4}>
              {consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
                const items = consumablesByCategories[category].filter(c => order.residues[c.id] !== undefined)

                if (!items.length) {
                  return null
                }

                return (
                  <Box marginBottom={2} key={category}>
                    <Typography variant='caption' color='textSecondary'>
                      {category === 'withoutCategory' ? 'Без категории' : category}
                    </Typography>

                    {items.map(consumable => (
                      <ConsumableResiduesCard
                        key={consumable.id}
                        consumable={consumable}
                        qnt={order.residues[consumable.id] || 0}
                        withEan
                      />
                    ))}
                  </Box>
                )
              })}
            </Box>

            {order.status === 'active' &&
              <Box margin={2}>
                <Grid container spacing={2}>
                  {userCredentials.consumables_orders_take &&
                    <Grid item xs={6} sm={4}>
                      <Button onClick={() => setTake(true)}>
                        Принять поставку
                      </Button>
                    </Grid>
                  }
                  {userCredentials.consumables_orders_edit &&
                    <Grid item xs={6} sm={4}>
                      <Button onClick={() => setEdit(true)}>
                        Редактировать
                      </Button>
                    </Grid>
                  }
                  {userCredentials.consumables_orders_edit &&
                    <Grid item xs={6} sm={4}>
                      <Button color='error' onClick={() => setConfirm(true)}>
                        Отменить
                      </Button>
                    </Grid>
                  }
                </Grid>
              </Box>
            }
          </Collapse>
        </TableCell>
      </TableRow>

      {confirm &&
        <Confirmation
          open={confirm}
          onClose={() => setConfirm(false)}
          title='Вы действительно хотите удалить поставку?'
          onSubmit={handleDelete}
        />
      }

      {take &&
        <Dialog
          open={take}
          onClose={() => setTake(false)}
        >
          <DialogTitle>
            Принять поставку №{order.number}
          </DialogTitle>

          <DialogContent>
            <Autocomplete
              sx={{marginTop: 2}}
              value={storeId}
              onChange={(_, val: string | null) => {
                setStoreId(val)
                setStore(stores.find(s => s.id === val) || null)
              }}
              options={stores.map(s => s.id) || []}
              openOnFocus
              getOptionLabel={o => storesMap[o]?.name || ''}
              renderInput={(params) => <TextField
                {...params}
                label="На склад"
                variant="outlined"
              />}
            />
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={() => setTake(false)}>
              Отмена
            </Button>
            <Button onClick={handleTake} disabled={!store}>
              Принять
            </Button>
          </DialogActions>
        </Dialog>
      }

      {edit &&
        <OrderEditModal
          open={edit}
          onClose={() => setEdit(false)}
          consumablesByCategories={consumablesByCategories}
          consumables={consumables}
          userCredentials={userCredentials}
          order={order}
          totalResidues={totalResidues}
        />
      }
    </Fragment>
  )
}

export default OrderRow
