import { Archive, Edit, ExpandLess, ExpandMore, Phone, Unarchive } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Collapse, Divider, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { format } from 'date-fns';
import { doc, updateDoc } from 'firebase/firestore';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import { Params, UserCredentials, Worker, WorkerGPH, WorkerPassport } from '../../apiTypes';
import IconTitleButton from '../../components/IconTitleButton';
import SmartAvatar from '../../components/SmartAvatar';
import { initialGPH, initialPassport, translations } from '../../constants';
import { db } from '../../firebase';
import EditWorkerModal from './EditWorkerModal';


interface WorkerCardProps {
  worker: Document<Worker>
  userCredentials: UserCredentials
}

const WorkerCard = (props: WorkerCardProps) => {
  const { companySlug } = useParams<Params>();
  const {userCredentials, worker} = props
  const { name, lastName, photoURL, position, trainee, phone, birthday, extra, passport, GPH } = worker

  const displayName = `${lastName} ${name}`
  const birthdayString = birthday ? format(new Date(birthday), 'dd/MM/yyyy') : ''

  const [expanded, setExpanded] = useState(false)
  const [showPassport, setShowPassport] = useState(false)
  const [showGPH, setShowGPH] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleArchive = async () => {
    await updateDoc(doc(db, "company", companySlug, "workers", worker.id), {archived: !worker.archived});
  }

  return (
    <Box marginTop={2}>

      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          avatar={<SmartAvatar name={displayName} photoURL={photoURL} />}
          title={displayName}
          subheader={position + (trainee ? ' (стажер)' : '')}
          action={phone
            ? (
              <Box paddingTop={1}>
                {true &&
                  <a href={`tel:${phone}`}>
                    <IconButton aria-label="phone"><Phone /></IconButton>
                  </a>
                }
                {userCredentials.workers_info &&
                  <IconButton
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show details"
                  >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                }
              </Box>
            ) : null
          }
        />


        {userCredentials.workers_info &&
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Divider/>
            <CardContent>
              {userCredentials.workers_edit_info &&
                <Fragment>
                  <Box marginBottom={2} display='flex'>
                    <IconTitleButton
                      icon={<Edit/>}
                      title='Редактировать'
                      onClick={() => setEditModalIsOpen(true)}
                    />
                    <IconTitleButton
                      icon={worker.archived ? <Archive/> : <Unarchive/>}
                      title={worker.archived ? 'Восстановить' : 'Отправить в архив'}
                      onClick={toggleArchive}
                    />
                  </Box>
                </Fragment>
              }

              <List dense={true}>
                <ListItem><ListItemText primary="Номер телефона" secondary={phone} /></ListItem>
                {birthdayString &&
                  <ListItem><ListItemText primary="День рождения" secondary={birthdayString} /></ListItem>
                }
                {extra &&
                  <ListItem><ListItemText primary="Дополнительная информация" secondary={extra} /></ListItem>
                }
                {passport &&
                  <Fragment>
                    <ListItem button onClick={() => setShowPassport(!showPassport)}>
                      <ListItemText primary="Паспортные данные" />
                      {showPassport ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={showPassport} timeout="auto" unmountOnExit>
                      <Box marginLeft={2}>
                        <List disablePadding dense={true}>
                          {Object.keys(initialPassport).map(key => {
                            const field = key as keyof WorkerPassport
                            return(
                              <ListItem key={key}>
                                <ListItemText primary={translations[key]} secondary={passport[field]} />
                              </ListItem>
                            )
                          })}
                        </List>
                      </Box>
                    </Collapse>
                  </Fragment>
                }
                {GPH &&
                  <Fragment>
                    <ListItem button onClick={() => setShowGPH(!showGPH)}>
                      <ListItemText primary="Данные по ГПХ" />
                      {showGPH ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={showGPH} timeout="auto" unmountOnExit>
                      <Box marginLeft={2}>
                        <List disablePadding dense={true}>
                          {Object.keys(initialGPH).map(key => {
                            const field = key as keyof WorkerGPH
                            return(
                              <ListItem key={key}>
                                <ListItemText primary={translations[key]} secondary={GPH[field]} />
                              </ListItem>
                            )
                          })}
                        </List>
                      </Box>
                    </Collapse>
                  </Fragment>
                }

              </List>
            </CardContent>
            {editModalIsOpen &&
              <EditWorkerModal worker={worker} open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)}/>
            }
          </Collapse>
        }
      </Card>
    </Box>
  )
}


export default WorkerCard;
