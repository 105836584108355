import { doc, updateDoc } from '@firebase/firestore';
import { Edit, ExpandLess, ExpandMore, Send } from '@mui/icons-material';
import { Autocomplete, Badge, Box, Button, Card, CardContent, CardHeader, Collapse, Divider, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import ru from "date-fns/locale/ru";
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Document } from 'swr-firestore-v9';
import { Author, Params, Task, TaskStatus, UserCredentials, Worker } from '../../apiTypes';
import SmartAvatar from '../../components/SmartAvatar';
import { Map, translations } from '../../constants';
import { db } from '../../firebase';
import { getDateCode, sendPush } from '../../utils';

const statuses: TaskStatus[] = ['planned', 'active', 'checking', 'closed']

interface Props {
  task: Document<Task>
  authorsMap: Map<Author>
  userCredentials: UserCredentials
  openEditModal: (taskId: string) => void
  author: string
  workersMap: Map<Document<Worker>>
}

const TaskRow = (props: Props) => {
  const { task, authorsMap, userCredentials, openEditModal, author, workersMap } = props
  const { companySlug } = useParams<Params>()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [showAllComments, setShowAllComments] = useState(false)
  const worker = authorsMap[task.worker] || {}
  const taskDateCode = getDateCode(new Date(task.time))
  const todayDateCode = getDateCode(new Date())
  const showedComments = showAllComments || task.comments.length < 5
    ? task.comments
    : task.comments.slice(task.comments.length - 5)
  const unViewedCount = task.comments.reduce((acc, c) => {
    if (!c.viewed.includes(author)) {
      acc = acc + 1
    }
    return acc
  }, 0)

  const saveComment = () => {
    if (text.trim()) {
      updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
        comments: [
          ...task.comments,
          {
            time: new Date().getTime(),
            author,
            comment: text,
            viewed: [author],
          }
        ]
      })
    }

    if (workersMap[task.worker]?.msgToken) {
      sendPush({
        title: 'В задаче: ' + task.name,
        body: 'Новый комментарий: ' + text,
        msgToken: workersMap[task.worker].msgToken || '',
        url: `https://jimba.cloud/${companySlug}/terminal`
      })
    }

    setText('')
  }

  const changeStatus = (status: TaskStatus) => {
    updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
      status,
      comments: [
        ...task.comments,
        {
          time: new Date().getTime(),
          author,
          comment: `Изменил(а) статус на "${translations[status]}"`,
          viewed: [author],
        }
      ]
    })

    if (workersMap[task.worker]?.msgToken) {
      sendPush({
        title: 'В задаче: ' + task.name,
        body: 'Изменен статус на: ' + translations[status],
        msgToken: workersMap[task.worker].msgToken || '',
        url: `https://jimba.cloud/${companySlug}/terminal`
      })
    }
  }

  useEffect(() => {
    if (open) {
      if (task && unViewedCount) {
        const newComments = task.comments.map(c => {
          const newViewed = c.viewed.includes(author) ? c.viewed : [...c.viewed, author]
          return ({...c, viewed: newViewed})
        })

        updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
          comments: newComments
        })
      }
    }
  }, [open])

  return (
    <Fragment>
      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          title={task.name}
          avatar={
            <Badge badgeContent={unViewedCount} color="info">
              <SmartAvatar name={worker.name} photoURL={worker.photoURL} />
            </Badge>
          }
          subheader={
            <Box sx={{ display: 'flex', }} >
              <Box sx={{
                color: (taskDateCode > todayDateCode || task.status === 'closed')
                ? 'success.main'
                : taskDateCode === todayDateCode ? 'warning.main' : 'error.main',
              }}>
                {format(new Date(task.time), 'dd MMMM', {locale: ru})}
              </Box>

              <Box sx={{ml: 1}}>{'| ' + translations[task.status]}</Box>
            </Box>
          }
          action={
              <Box paddingTop={1}>
                {userCredentials.tasks_edit && task.status !== 'closed' &&
                  <IconButton onClick={() => openEditModal(task.id)}>
                    <Edit/>
                  </IconButton>
                }
                <IconButton
                  onClick={() => setOpen(!open)}
                  aria-expanded={open}
                  aria-label="show details"
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
          }
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider/>
          <CardContent>
            {userCredentials.tasks_change_status && task.status !== 'closed' &&
              <Autocomplete
                value={task.status}
                options={statuses}
                onChange={(_, s) => s && changeStatus(s)}
                getOptionLabel={o => translations[o]}
                blurOnSelect
                renderInput={(params) => <TextField
                  {...params}
                  label="Изменить статус"
                />}
                sx={{backgroundColor: '#fff', mb: 3}}
              />
            }

            {task.description &&
              <Fragment>
                <Typography variant='subtitle2'>Описание:</Typography>
                <Box sx={{mb: 3, pl: 2}}>{task.description}</Box>
              </Fragment>
            }

            <Typography sx={{mb: 1}} variant='subtitle2'>Комментарии:</Typography>
            {task.comments.length > 5 && !showAllComments &&
              <Box sx={{display: 'flex', justifyContent: 'center', my: 1}}>
                <Button size='small' sx={{px: 1, py: 0}} onClick={() => setShowAllComments(true)}>
                  Отобразить все комментарии ({task.comments.length})
                </Button>
              </Box>
            }
            <Box sx={{mb: 3, pl: 2}}>
              {showedComments.map(comment => {
                const author = authorsMap[comment.author] || {}
                return (
                  <Box sx={{display: 'flex', mb: 2}} key={comment.time}>
                    <SmartAvatar name={author.name} photoURL={author.photoURL} sx={{mr: 2}}/>
                    <div>
                      <div>
                        <Box component='span' sx={{fontWeight: 500}}>{author.name}</Box>
                        <Box component='span' sx={{color: 'text.secondary', ml: 1}}>
                          {format(new Date(comment.time), 'dd MMM, HH:mm', {locale: ru})}
                        </Box>
                      </div>
                      <Box>{comment.comment}</Box>
                    </div>
                  </Box>
                )
              })}
            </Box>

            {task.status !== 'closed' &&
              <TextField
                sx={{backgroundColor: '#fff'}}
                multiline
                label='Добавить комментарий'
                value={text}
                fullWidth
                onChange={e => setText(e.target.value)}
                onKeyPress={e => e.key === 'Enter' && saveComment()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={saveComment} disabled={!text.trim()}>
                        <Send/>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
          </CardContent>
        </Collapse>
      </Card>
    </Fragment>
  )
}

export default TaskRow
