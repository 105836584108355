import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { Fragment, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Document } from "swr-firestore-v9";
import { TechMap } from "../../apiTypes";
import { weekDays } from "../../constants";
import { getTime } from "./utils";

interface Props {
  open: boolean;
  onClose: () => void;
  techMap?: Document<TechMap>;
}

const ViewTechMap = (props: Props) => {
  const { open, onClose, techMap } = props;
  const [tabIndex, setTabIndex] = useState(0);

  const actions =
    tabIndex === 0
      ? techMap?.actions
      : techMap?.extendedActions?.[tabIndex - 1] || techMap?.actions;

  const handleChange = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const print = useReactToPrint({ contentRef });

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen={document.documentElement.clientWidth < 860}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Технологическая карта
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box marginBottom={3}>
            <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth">
              <Tab label="Базовие" />
              {weekDays.map((day, dayIndex) => (
                <Tab key={day} label={day} />
              ))}
            </Tabs>
          </Box>

          <Box ref={contentRef} p={2}>
            <Typography variant="h5" textAlign="center" marginBottom={2}>
              {techMap?.name}{" "}
              {weekDays[tabIndex - 1] ? `(${weekDays[tabIndex - 1]})` : ""}
            </Typography>

            {!!actions?.length && (
              <TableContainer>
                <Table size="small">
                  <TableHead style={{ fontWeight: 600 }}>
                    <TableRow>
                      <TableCell>Время</TableCell>
                      <TableCell>Место</TableCell>
                      <TableCell>Комплекс работ</TableCell>
                      <TableCell>Инструкция</TableCell>
                      <TableCell>Пр-ть</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {actions.map((action, index) => {
                      const [start, end] = getTime(
                        actions,
                        index,
                        techMap?.startTime || ""
                      );
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            <Box width={38}>
                              <Typography variant="body2" color="textSecondary">
                                {start}
                              </Typography>
                              <Box paddingLeft={1} paddingRight={1}>
                                <Divider />
                              </Box>
                              <Typography variant="body2" color="textSecondary">
                                {end}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{action.place}</TableCell>
                          <TableCell>{action.name}</TableCell>
                          <TableCell>{action.instructions}</TableCell>
                          <TableCell>{action.duration}м</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => print()}>Печать</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ViewTechMap;
