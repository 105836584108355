import { GoogleAuthProvider, signInWithPopup, signOut } from '@firebase/auth';
import { Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, Button, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useLocation } from 'react-router';
import { UserCredentials } from '../apiTypes';
import { auth } from '../firebase';
import Menu, { menuMap } from './Menu';

interface Props {
  userName: string | undefined
  userCredentials?: UserCredentials
  collapsed: boolean
  collapseMenu: () => void
}

function Header(props: Props) {
  const { userCredentials, userName, collapsed, collapseMenu } = props
  const [userAuth] = useAuthState(auth);
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const location = useLocation()
  const page = location.pathname.split('/')[2]

  const logout = () => {
    signOut(auth)
  }

  const login = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {}).catch((error) => {});
  }

  return (
    <AppBar
      position='sticky'
      color='default'
      sx={{boxShadow: 'none', borderBottom: t => `1px solid ${t.palette.grey[300]}`}}
    >
      <Toolbar>
        {userName && userCredentials &&
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setMenuIsOpen(true)}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        }

        <Box sx={{m: '0px 118px 0px 48px', display: { xs: 'none', md: 'block' }}}>
          <img
            src='/jimbo_logo_40.png'
            alt='jimbo logo'
            loading="lazy"
          />
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box padding={1}>
            <Typography variant="h6" sx={{ml: {md: 2}}}>
              {menuMap[page]?.title || ''}
            </Typography>
          </Box>

          {userAuth &&
            <Box display="flex">
              <Box sx={{mr: 1}}>
                <Avatar alt={userAuth.displayName || ''} src={userAuth.photoURL || ''} />
              </Box>
              <IconButton onClick={logout}><Logout/></IconButton>
            </Box>
          }

          {!userAuth &&
            <div>
              <Button color="inherit" onClick={login}>Войти</Button>
            </div>
          }
        </Box>
      </Toolbar>

      {userCredentials &&
        <Drawer
          color='default'
          variant='temporary'
          open={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '280px' },
          }}
        >
          <Menu
            closeMenu={() => setMenuIsOpen(false)}
            userCredentials={userCredentials}
            collapsed={collapsed}
            collapseMenu={collapseMenu}
          />
        </Drawer>
      }


      {userCredentials &&
        <Drawer
          color='default'
          variant='permanent'
          open={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              top: '64px',
              boxSizing: 'border-box',
              width: collapsed ? '60px' : '280px',
              height: 'calc(100% - 64px)',
              backgroundColor: t => t.palette.grey[100]
            },
          }}
        >
          <Menu
            closeMenu={() => setMenuIsOpen(false)}
            userCredentials={userCredentials}
            collapsed={collapsed}
            collapseMenu={collapseMenu}
          />
        </Drawer>
      }
    </AppBar>
  );
}

export default Header;
