import { Close } from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { Box, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField } from '@mui/material';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import { slugify } from 'transliteration';
import { Params, Worker, WorkerGPH, WorkerPassport } from '../../apiTypes';
import Loader from '../../components/Loader';
import UploadImg from '../../components/UploadImg';
import { initialGPH, initialPassport, translations } from '../../constants';
import { db } from '../../firebase';

interface Props {
  open: boolean
  onClose: () => void
  worker?: Document<Worker>
}

const initialTouched = {name: false, lastName: false, phone: false}

const EditWorkerModal = (props: Props) => {
  const { open, onClose, worker } = props
  const { companySlug } = useParams<Params>();

  const [name, setName] = useState(worker?.name || '')
  const [lastName, setLastName] = useState(worker?.lastName || '')
  const [phone, setPhone] = useState(worker?.phone || '')
  const [photoURL, setPhotoURL] = useState(worker?.photoURL || '')
  const [position, setPosition] = useState(worker?.position || '')
  const [trainee, setTrainee] = useState(worker?.trainee || false)
  const [birthday, setBirthday] = useState<Date | null>(worker?.birthday ? new Date(worker.birthday) : null)
  const [extra, setExtra] = useState(worker?.extra || '')
  const [passport, setPassport] = useState<WorkerPassport | null>(worker?.passport || null)
  const [GPH, setGPH] = useState<WorkerGPH | null>(worker?.GPH || null)
  const [touched, setTouched] = useState(initialTouched)
  const [loading, setLoading] = useState(false)

  const changePassport = (field: keyof WorkerPassport, value: string) => {
    passport && setPassport({ ...passport, [field]: value })
  }

  const changeGPH = (field: keyof WorkerGPH, value: string) => {
    GPH && setGPH({ ...GPH, [field]: value })
  }

  const saveChanges = async () => {
    setLoading(true)
    const newWorker = {
      name, lastName, phone, photoURL, position, trainee,
      birthday: birthday ? birthday.getTime() : null,
      extra, passport, GPH, archived: false, balance: worker?.balance || 0,
    }
    if (worker?.id) {
      await updateDoc(doc(db, "company", companySlug, "workers", worker.id), newWorker)
    } else {
      await setDoc(doc(db, "company", companySlug, "workers", slugify(`${name}_${lastName}`)), newWorker)
    }
    onClose()
    setLoading(false)
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullWidth>
        <Loader open={loading} />

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            {worker?.id ? 'Катра сотрудника' : 'Новый сотрудник'}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box marginBottom={2}>
            <UploadImg imgUrl={photoURL} setImgUrl={setPhotoURL} folder={companySlug}/>
          </Box>

          <Box marginBottom={2}>
            <TextField fullWidth variant="outlined" required
              id="name" label="Имя"
              value={name}
              onChange={e => setName(e.target.value)}
              error={touched.name && !name}
              helperText={touched.name && !name && 'Обязательное поле'}
              onBlur={() => setTouched({...touched, name: true})}
            />
          </Box>

          <Box marginBottom={2}>
            <TextField fullWidth variant="outlined" required
              id="lastName" label="Фамилия"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              error={touched.lastName && !lastName}
              helperText={touched.lastName && !lastName && 'Обязательное поле'}
              onBlur={() => setTouched({...touched, lastName: true})}
            />
          </Box>

          <Box marginBottom={2}>
            <TextField fullWidth variant="outlined" required
              id="phone" label="Номер телефона"
              value={phone}
              onChange={e => {
                const val = e.target.value
                if (/^[+]{0,1}[0-9]{0,13}$/.test(val)) {
                  setPhone(val[0] === '+' || val === '' ? val : `+${val}`)
                }
              }}
              error={touched.phone && (!phone || phone === '+')}
              helperText={touched.phone && (!phone || phone === '+') && 'Обязательное поле'}
              onBlur={() => setTouched({...touched, phone: true})}
            />
          </Box>

          <Box marginBottom={2}>
            <TextField fullWidth variant="outlined"
              id='position' label='Позиция / Должность'
              value={position}
              onChange={e => setPosition(e.target.value)}
            />
          </Box>

          <Box marginBottom={2}>
            <DatePicker
              disableMaskedInput
              label="День рождения"
              value={birthday}
              onChange={(date) => setBirthday(date)}
              toolbarPlaceholder='none'
              renderInput={(params) => <TextField {...params}/>}
            />
          </Box>

          <Box marginBottom={2}>
            <TextField fullWidth variant="outlined" multiline rows={3}
              id="extra-ifo" label="Дополнительная информация"
              value={extra}
              onChange={e => setExtra(e.target.value)}
            />
          </Box>

          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={trainee}
                  onChange={e => setTrainee(e.target.checked)}
                />
              }
              label="Стажер"
            />
          </Box>

          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!passport}
                  onChange={e => setPassport(e.target.checked ? initialPassport : null)}
                />
              }
              label="Паспортные данные"
            />
          </Box>

          <Collapse in={!!passport} unmountOnExit>
            {passport && Object.keys(initialPassport).map(key => {
              const field = key as keyof WorkerPassport
              return (
                <Box marginBottom={2} key={field}>
                  <TextField fullWidth variant="outlined"
                    id={field} label={translations[field]}
                    value={passport[field]}
                    onChange={e => changePassport(field, e.target.value)}
                  />
                </Box>
              )
            })}
          </Collapse>

          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!GPH}
                  onChange={e => setGPH(e.target.checked ? initialGPH : null)}
                />
              }
              label="Работает по ГПХ"
            />
          </Box>

          <Collapse in={!!GPH} unmountOnExit>
            {GPH && Object.keys(GPH).map(key => {
              const field = key as keyof WorkerGPH
              return (
                <Box marginBottom={2} key={field}>
                  <TextField fullWidth variant="outlined"
                    id={field} label={translations[field]}
                    value={GPH[field]}
                    onChange={e => changeGPH(field, e.target.value)}
                  />
                </Box>
              )
            })}
          </Collapse>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={saveChanges} color="primary" disabled={!name || !lastName || !phone}>
            {worker?.id ? 'Сохранить изменения' : 'Добавить сотрудника'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default EditWorkerModal;
