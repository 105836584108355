import { signOut } from '@firebase/auth';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { auth } from '../firebase';


function NotAvailable() {
  const [userAuth] = useAuthState(auth);

  return (
    <Box padding={2} marginTop={6} textAlign='center'>
      <Typography variant='h6'>
        {`Эта страница ограничена в доступе для аккаунта: ${userAuth?.email || userAuth?.phoneNumber}`}
      </Typography>

      <Button
        onClick={() => signOut(auth)}
        sx={{my: 2}}
        variant='contained'
      >
        Сменить аккаунт
      </Button>
    </Box>
  );
}

export default NotAvailable;
