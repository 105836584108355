import { doc, runTransaction } from '@firebase/firestore';
import { Add, ArrowBack, ArrowForwardIos, Close, Remove } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Consumable, Params, Store, StoreLog, TechMap, Worker } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import Loader from '../../components/Loader';
import { db } from '../../firebase';

interface EventModalProps {
  store: Document<Store>
  consumables: Document<Consumable>[]
  consumablesByCategories: {
    [category: string]: Document<Consumable>[]
  }
  onClose: () => void
  techMap: Document<TechMap>
  worker: Document<Worker>
}

const TerminalStoresEventModal = (props: EventModalProps) => {
  const { onClose, store, consumablesByCategories, techMap, consumables, worker } = props
  const { companySlug } = useParams<Params>();
  const [consumableId, setConsumableId] = useState<string | null>(null)
  const [writeOffZone, setWriteOffZone] = useState<string | null>(null)
  const [qnt, setQnt] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>()

  const consumable = useMemo(
    () => consumables.find(c => c.id === consumableId),
    [consumableId, consumables]
  )

  const changeQnt = (newQnt: number) => {
    if (consumableId && newQnt > store.residues[consumableId]) {
      setQnt(store.residues[consumableId])
    } else if (consumableId && newQnt <= store.residues[consumableId] && newQnt >= 0) {
      setQnt(newQnt)
    }
  }

  const saveChanges = async () => {
    if (consumableId && writeOffZone && qnt && buttonRef.current && store.id) {
      buttonRef.current.disabled = true
      setLoading(true)
      try {
        const date = new Date()
        const log: StoreLog = {
          date: format(date, 'dd.MM.yyyy'),
          time: date.getTime(),
          author: worker?.name + " " + worker?.lastName,
          type: 'spend',
          from: store.id,
          to: writeOffZone,
          residues: {[consumableId]: qnt},
        }
        const storeRef = doc(db, "company", companySlug, "stores", store.id)
        await runTransaction(db, async (transaction) => {
          const sfDoc = await transaction.get(storeRef);
          if (!sfDoc.exists()) {
            throw "Document does not exist!";
          }

          const newCount = sfDoc.data().residues[consumableId] - qnt;
          const res = transaction.update(storeRef, { [`residues.${consumableId}`]: newCount });
          res.set(doc(db, "company", companySlug, "storesLogs", uniqid()), log)
        });
      } catch (e) {
        console.log("Transaction failed: ", e);
      }
      onClose()
      setLoading(false)
      // try {
      //   const batch = writeBatch(db);
      //   batch.set(doc(db, "company", companySlug, "storesLogs", uniqid()), log)
      //   batch.update(doc(db, "company", companySlug, "stores", store.id), {
      //     [`residues.${consumableId}`]: increment(-qnt)
      //   })
      //   batch.commit()
      //   onClose()
      // } catch(err) {
      //   console.error(err)
      //   onClose()
      // }
    }
  }

  useEffect(() => {
    if (techMap.writeOffZones.length === 1) {
      setWriteOffZone(techMap.writeOffZones[0])
    }
  }, [techMap.writeOffZones])

  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <Loader open={loading}/>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {consumableId &&
            <IconButton onClick={() => writeOffZone ? setWriteOffZone(null) : setConsumableId(null)}>
              <ArrowBack/>
            </IconButton>
          }

          {consumableId
            ? writeOffZone ? 'Введите количество' : 'Выберите зону списания'
            : 'Выберите расходник'
          }

          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {consumable &&
          <Box sx={{mb: 2, ml: 2}}>
            <ConsumableTitle consumable={consumable} />
          </Box>
        }
        {writeOffZone &&
          <Box sx={{mb: 2, ml: 3}}>
            <Typography sx={{fontSize: '18px'}}>{writeOffZone}</Typography>
          </Box>
        }


        {!consumableId && consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
            const items = consumablesByCategories[category].filter(c => !!store.residues[c.id] && !c.disableSpend)

            if (!items.length) {
              return null
            }

            return (
              <Box marginBottom={2} key={category}>
                <Typography variant='caption' color='textSecondary'>
                  {category === 'withoutCategory' ? 'Без категории' : category}
                </Typography>

                {items.map(consumable => (
                  <Box
                    key={consumable.id}
                    sx={{
                      m: 1, p: 1,
                      border: t => `1px solid ${t.palette.grey[500]}`,
                      backgroundColor: t => t.palette.grey[50],
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    onClick={() => setConsumableId(consumable.id)}
                  >
                    <ConsumableTitle consumable={consumable} />
                    <IconButton>
                      <ArrowForwardIos />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )
          })}

          {consumableId && !writeOffZone && techMap.writeOffZones.map(woz => (
            <Box
              key={woz}
              sx={{
                m: 1, p: 1,
                border: t => `1px solid ${t.palette.grey[500]}`,
                backgroundColor: t => t.palette.grey[50],
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              onClick={() => setWriteOffZone(woz)}
            >
              <Typography sx={{fontSize: '18px', p: 1}}>{woz}</Typography>
              <IconButton>
                <ArrowForwardIos />
              </IconButton>
            </Box>
          ))}

          {consumableId && consumable && writeOffZone &&
            <Fragment>
              <Box sx={{display: 'flex'}}>
                <IconButton onClick={() => changeQnt(qnt ? qnt - 1 : 0)}>
                  <Remove/>
                </IconButton>
                <TextField
                  fullWidth
                  type='number'
                  autoFocus
                  value={String(qnt)}
                  onChange={e => changeQnt(Number(e.target.value))}
                  sx={{mx: 1}}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>{consumable.unit}</InputAdornment>,
                  }}
                />
                <IconButton onClick={() => changeQnt(qnt ? qnt + 1 : 1)}>
                  <Add/>
                </IconButton>
              </Box>
              <Typography variant='caption' sx={{color: t => t.palette.text.secondary, ml: '48px'}}>
                {`Доступно: ${store.residues[consumableId]} ${consumable.unit}`}
              </Typography>

              <Button
                size='large'
                variant="contained"
                disabled={!qnt}
                sx={{my: 3}}
                fullWidth
                onClick={saveChanges}
                ref={(el) => buttonRef.current = el || undefined}
                >
                Внести расход
              </Button>
            </Fragment>
          }
      </DialogContent>
    </Dialog>
  )
}

export default TerminalStoresEventModal
