import { doc, updateDoc } from '@firebase/firestore';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMessagingToken } from 'react-firehooks';
import { useAuthState } from 'react-firehooks/auth';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { useDocument } from 'swr-firestore-v9';
import { Company, User } from '../apiTypes';
import { vapidKey } from '../constants';
import { AdminProvider } from '../contexts/admin.context';
import { auth, db, messaging } from '../firebase';
import ConsumablesMotion from '../pages/ConsumablesMotion/ConsumablesMotion';
import ConsumablesReport from '../pages/ConsumablesReport/ConsumablesReport';
import ConsumablesTypes from '../pages/ConsumablesTypes/ConsumablesTypes';
import DailyJournal from '../pages/DailyJournal/DailyJournal';
import Journal from '../pages/Journal/Journal';
import Orders from '../pages/Orders/Orders';
import Payouts from '../pages/Payouts/Payouts';
import PayoutsReport from '../pages/PayoutsReport/PayoutsReport';
import Stores from '../pages/Stores/Stores';
import Tasks from '../pages/Tasks/Tasks';
import TechMaps from '../pages/TechMaps/TechMaps';
import Users from '../pages/Users/Users';
import Wallets from '../pages/Wallets/Wallets';
import Workers from '../pages/Workers/Workers';
import AuthPage from './AuthPage';
import Header from './Header';
import NotAvailable from './NotAvailable';
import NotFoundPage from './NotFoundPage';
import WelcomePage from './WelcomePage';

interface Params {
  companySlug: string
}

function AdminRouter() {
  const [userAuth, userIsValidating] = useAuthState(auth);
  const { companySlug } = useParams<Params>();
  const location = useLocation();
  const company = useDocument<Company>(userAuth ? `company/${companySlug}` : null)
  const companyName = company.data?.name;
  const user = useDocument<User>(companyName && userAuth ? `company/${companySlug}/users/${userAuth.email}` : null, {listen: true})
  const [token] = useMessagingToken(messaging,  {getTokenOptions: {vapidKey: vapidKey}});
  const userName = user.data?.displayName;
  const userCredentials = user.data?.credentials;
  const [menuIsCollapsed, setMenuIsCollapsed] = useState(false)

  useEffect(() => {
    const company = localStorage.getItem('company')
    const platform = localStorage.getItem('platform')
    const page = location.pathname.split('/')[1]

    if (!company && !platform && companySlug) {
      localStorage.setItem('company', companySlug)
      if (page && page === 'terminal') {
        localStorage.setItem('platform', 'terminal')
      }
      if (page && page !== 'terminal') {
        localStorage.setItem('platform', 'admin')
      }
    }
  }, [])

  useEffect(() => {
    if (token && user.data && !user.data.msgToken && userAuth?.email) {
      updateDoc(doc(db, "company", companySlug, "users", userAuth.email), { msgToken: token});
    }
  }, [user, token, companySlug, userAuth])

  return (
    <div>
      <Header
        userName={userName}
        userCredentials={userCredentials}
        collapsed={menuIsCollapsed}
        collapseMenu={() => setMenuIsCollapsed(!menuIsCollapsed)}
      />

      {!userAuth && !userIsValidating && <AuthPage />}
      {userAuth && !company.isValidating && !companyName && <NotFoundPage canClearState />}
      {userAuth && companyName && !userName && !user.isValidating && <NotAvailable />}

      {userAuth && companyName && userName && userCredentials &&
        <Box
          sx={{
            width: { md: `calc(100% - ${menuIsCollapsed ? '60px' : '280px'})` },
            ml: { md: menuIsCollapsed ? '60px' : '280px' },
          }}
        >
          <AdminProvider>
            <Switch>
              <Route path='/:companySlug' exact>
                <WelcomePage />
              </Route>
              <Route path='/:companySlug/users' exact>
                {userCredentials.users ? <Users userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/workers' exact>
                {userCredentials.workers ? <Workers userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/tech_maps' exact>
                {userCredentials.techMaps ? <TechMaps userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/consumables' exact>
                {userCredentials.consumables ? <ConsumablesTypes userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/consumables_motion' exact>
                {userCredentials.consumables_motion ? <ConsumablesMotion userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/stores' exact>
                {userCredentials.stores ? <Stores userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/orders' exact>
                {userCredentials.consumables_orders ? <Orders userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/journal' exact>
                {userCredentials.consumables_orders ? <Journal userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/daily_journal' exact>
                {userCredentials.daily_journal ? <DailyJournal userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/wallets' exact>
                {userCredentials.wallets ? <Wallets userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/payouts' exact>
                {userCredentials.payouts ? <Payouts userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/tasks' exact>
                {userCredentials.tasks ? <Tasks userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/payouts_report' exact>
                {userCredentials.payouts_report ? <PayoutsReport userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/consumables_report' exact>
                {userCredentials.consumables_report ? <ConsumablesReport userCredentials={userCredentials} /> : <NotAvailable />}
              </Route>
              <Route path='/:companySlug/*'>
                <NotFoundPage />
              </Route>
            </Switch>
          </AdminProvider>
        </Box>
      }

    </div>
  );
}

export default AdminRouter;
