import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import ru from "date-fns/locale/ru";
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Document, useCollection } from 'swr-firestore-v9';
import { Params, TechMap, UserCredentials, WorkShift } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import { getDateCode, getDateFromDateCode, incrementDateCode } from '../../utils';
import Cell from './Cell';

interface Props {
  userCredentials: UserCredentials
}

function Journal(props: Props) {
  const { companySlug } = useParams<Params>();
  const [period, setPeriod] = useState<DateRange<Date>>([new Date(), new Date()]);
  const [dateCodes, setDateCodes] = useState<number[]>([]);
  const [lastWorker, setLastWorker] = useState('')
  const history = useHistory();

  const workShifts = useCollection<WorkShift>(`company/${companySlug}/workShifts`, {
    listen: true,
    where: [
      ['dateCode', '>=', getDateCode(period[0])],
      ['dateCode', '<=',  getDateCode(period[1])],
    ],
  })

  const { workers, workersMap, techMapsByObject, isValidating } = useAdmin();
  const availableWorkers = workers.filter(w => !w.archived)

  useEffect(() => {
    const today = new Date()
    const initStartDate = new Date(today.setDate(today.getDate() - today.getDay() - 6))
    const initEndDate = new Date(initStartDate)
    initEndDate.setDate(initEndDate.getDate() + 20)
    setPeriod([initStartDate, initEndDate])
  }, [])

  useEffect(() => {
    const startDateCode = getDateCode(period[0])
    const endDateCode = getDateCode(period[1])
    const newDateCodes: number[] = []
    for (let dc = startDateCode; dc <= endDateCode; dc = incrementDateCode(dc)) {
      newDateCodes.push(dc)
    }
    setDateCodes(newDateCodes)
  }, [period])

  if (workShifts.isValidating || isValidating) {
    return <Loader open />
  }

  return (
    <Box padding={2} margin='0 auto'>
      <Box sx={{ my: 3, mx: 'auto', maxWidth: '300px' }}>
        <MobileDateRangePicker
          startText="Период с"
          endText="Период до"
          value={period}
          disableMaskedInput
          cancelText={null}
          showToolbar={false}
          onAccept={(newValue) => {
            setPeriod(newValue);
          }}
          onChange={() => {}}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
              <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
            </React.Fragment>
          )}
        />
      </Box>

      <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)'}}>
        <Table
          stickyHeader
          size='small'
          sx={{
            width: 'auto',
            mx: 'auto',
            '& td': {padding: '4px'},
            '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.background.paper},
            '& th': {padding: '4px', zIndex: 2},
            '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3, backgroundColor: t => t.palette.background.paper},
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Тех. карта</TableCell>
              {dateCodes.map(dc => {
                const date = getDateFromDateCode(dc)
                const isToday = date.toDateString() === new Date().toDateString()
                const day = format(date, 'EEEEEE', {locale: ru})
                return (
                  <TableCell key={dc} align='center'>
                    <Box
                      sx={{ border: isToday ? '1px solid #555' : 'none', borderRadius: '3px' }}
                    >
                      <Button
                        sx={{ minWidth: '0px', padding: '2px' }}
                        onClick={() => history.push(`/${companySlug}/daily_journal?date=${dc}`)}
                      >
                        {format(date, 'dd.MM')}
                      </Button>

                      <Typography color={(day === 'сб' || day === 'вс') ? 'orange' : 'default'}>
                        {day}
                      </Typography>
                    </Box>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody sx={{'& td': {padding: 0}, '& th': {padding: 0}}}>
            {techMapsByObject && Object.keys(techMapsByObject).map(object => {
              const techMaps = techMapsByObject[object]
              if (!techMaps || !techMaps.length) {
                return null
              }

              return(
                <Fragment key={object}>
                  <TableRow>
                    <TableCell sx={{ border: 'none' }}>
                      <Box marginTop={1}>
                        <strong>{object}</strong>
                      </Box>
                    </TableCell>
                  </TableRow>

                  {techMaps.map(techMap => {
                    return (
                      <TableRow key={techMap.id} sx={{ verticalAlign: 'top' }}>
                        <TableCell sx={{ verticalAlign: 'middle' }}>
                          <Box sx={{ padding: '4px' }} >{techMap.name}</Box>
                        </TableCell>

                        {dateCodes.map(dc => {
                          const filteredWorkShifts = (workShifts.data || []).filter(ws => ws.dateCode === dc && ws.techMap === techMap.id)
                          return (
                            <TableCell key={dc} align='center'>
                              <Cell
                                userCredentials={props.userCredentials}
                                workersMap={workersMap}
                                workShifts={filteredWorkShifts}
                                workers={availableWorkers}
                                dateCode={dc}
                                techMapId={techMap.id}
                                lastWorker={lastWorker}
                                setLastWorker={setLastWorker}
                              />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Journal;

export const techMapsReducer = (acc: {[object: string]: Document<TechMap>[]}, techMap: Document<TechMap>) => {
  if (techMap.object) {
    if (acc[techMap.object]) {
      acc[techMap.object].push(techMap)
    } else {
      acc[techMap.object] = [techMap]
    }
  } else {
    acc.withoutObject.push(techMap)
  }

  return acc
}
