import { doc, setDoc, updateDoc } from '@firebase/firestore';
import { Close, Delete } from '@mui/icons-material';
import { Autocomplete, MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, useCollection, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Company, Consumable, Order, Params, Residues, StoreLog, UserCredentials } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import { getInitMap, Map } from '../../constants';
import { db } from '../../firebase';
import { getQntStr } from '../../utils';
import ConsumablesInput from '../Stores/ConsumablesInput';



interface Props {
  open: boolean
  onClose: () => void
  consumablesByCategories: { [category: string]: Document<Consumable>[] }
  consumables: Document<Consumable>[]
  userCredentials: UserCredentials
  totalResidues: Map<number>
  order?: Document<Order>
}

const OrderEditModal = (props: Props) => {
  const { open, onClose, consumablesByCategories, consumables, userCredentials, order, totalResidues } = props
  const { companySlug } = useParams<Params>();
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()]);
  const [provider, setProvider] = useState<string | null>(order ? order.provider : null)
  const [residues, setResidues] = useState<Residues>(order ? order.residues : {})
  const [autofocusConsumable, setAutofocusConsumable] = useState<string>('')
  const [adding, setAdding] = useState(false);

  const company = useDocument<Company>(`company/${companySlug}`)
  const logs = useCollection<StoreLog>(`company/${companySlug}/storesLogs`, {
    listen: true,
    where: [
      ['time', '>', dates[0]?.getTime() || 0],
      ['time', '<',  dates[1]?.getTime() || 0],
    ],
  })

  const providers = useMemo(
    () => consumables.reduce((acc, c) => {
      acc[c.provider] = c.provider
      return acc
    }, getInitMap()),
    [consumables]
  )

  const writeOffMap = useMemo(
    () => (logs.data || []).filter(l => l.type === 'spend').reduce((acc, l) => {
      Object.keys(l.residues).forEach(key => {
        acc[key] = acc[key] ? (acc[key] + l.residues[key]) : l.residues[key]
      })
      return acc
    }, getInitMap<number>()),
    [logs.data]
  )

  const saveChanges = () => {
    const newResidues: Residues = {}
    const date = new Date()

    Object.keys(residues).forEach(id => {
      if (residues[id]) {
        newResidues[id] = residues[id]
      }
    })

    if (!order && userCredentials.consumables_orders_create) {
      const orderNumber = (company.data?.orderNumber || 0) + 1
      const order: Order = {
        time: date.getTime(),
        number: orderNumber,
        provider: provider || '',
        status: 'active',
        residues: newResidues,
      }

      setDoc(doc(db, "company", companySlug, "orders", uniqid()), order)
      updateDoc(doc(db, "company", companySlug), { orderNumber: orderNumber })
    } else if (order && userCredentials.consumables_orders_edit) {
      const changes: Partial<Order> = {
        residues: newResidues,
        provider: provider || '',
      }

      updateDoc(doc(db, "company", companySlug, "orders", order.id), changes)
    }
    onClose()
  }

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(initStartDate.getDate() - 30)
    setDates([initStartDate, initEndDate])
  }, [])

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullScreen={document.documentElement.clientWidth < 860} maxWidth='md' fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Поставка №{company.data ? company.data.orderNumber + 1 : ''}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers sx={{minHeight: 400}}>
          <Box marginTop={3} marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  value={provider}
                  disabled={!!order}
                  onChange={(_, val: string | null) => {
                    setProvider(val)
                    const newResidues: Residues = {}
                    consumables.forEach(c => {
                      if (c.provider === val) {
                        newResidues[c.id] = 0
                      }
                    })
                    setResidues(newResidues)
                  }}
                  options={Object.keys(providers) || []}
                  openOnFocus
                  getOptionLabel={o => o || 'Неизвестно'}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Поставщик"
                    variant="outlined"
                    size="small"
                  />}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MobileDateRangePicker
                  cancelText={null}
                  showToolbar={false}
                  startText="Отобразить расход с"
                  endText="Отобразить расход по"
                  value={dates}
                  disableFuture
                  disableMaskedInput
                  onAccept={(newValue) => {
                    setDates(newValue);
                  }}
                  onChange={() => {}}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} size='small' />
                      <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
                    </React.Fragment>
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          {Object.keys(consumablesByCategories).map(category => {
            const items = consumablesByCategories[category].filter(c => residues[c.id] !== undefined)

            if (!items.length) {
              return null
            }

            return (
              <Box key={category}>
                <Typography variant='caption' color='textSecondary'>
                  {category === 'withoutCategory' ? 'Без категории' : category}
                </Typography>

                {items.map(consumable => {
                  const { id  } = consumable
                  const helperText: string[] = [
                    `Потрачено: ${getQntStr(consumable, writeOffMap[id] || 0)}`,
                    `Общий статок: ${getQntStr(consumable, totalResidues[id] || 0)}`
                  ]

                  return (
                    <Box marginBottom={2} key={id}>
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} sm={6}>
                          <Box display='flex' alignItems='center'>
                            <div>
                              <IconButton
                                size='small'
                                onClick={() => {
                                  const newResidues: Residues = {}
                                  Object.keys(residues).filter(key => key !== id).forEach(key => newResidues[key] = residues[key])
                                  setResidues(newResidues)
                                }}
                              >
                                <Delete/>
                              </IconButton>
                            </div>
                            <ConsumableTitle consumable={consumable} withEan />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ConsumablesInput
                            consumable={consumable}
                            qnt={residues[id]}
                            onChange={newQnt => setResidues({...residues, [id]: newQnt})}
                            helperText={helperText}
                            autofocus={autofocusConsumable === consumable.id}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
              </Box>
            )
          })}

          {provider !== null && !Object.keys(residues).length &&
            <Box padding={3}>
              <Typography variant='h5' color='textSecondary' align='center'>Список пока пуст</Typography>
            </Box>
          }

          {provider !== null &&
            <Grid container>
              <Grid item xs={12} sm={6}>
                {!adding &&
                  <Button onClick={() => setAdding(true)} fullWidth>
                    Добавить позицию
                  </Button>
                }

                {adding &&
                  <Autocomplete
                    size='small'
                    options={consumables.filter(c => residues[c.id] === undefined)}
                    groupBy={(option) => option.category}
                    getOptionLabel={o => o.name + (o.ean ? ` [${o.ean}]` : '')}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        <ConsumableTitle consumable={option} withEan/>
                      </Box>
                    )}
                    fullWidth
                    blurOnSelect
                    openOnFocus
                    renderInput={(params) => <TextField {...params} label="Выберите расходник" variant="outlined" autoFocus />}
                    onChange={(e, value) => {
                      if (value) {
                        setResidues({...residues, [value.id]: 0})
                        setAutofocusConsumable(value.id)
                      }
                    }}
                    onBlur={() =>  setAdding(false)}
                  />
                  }
              </Grid>
            </Grid>
          }
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={saveChanges}
            color="primary"
            disabled={!Object.keys(residues).filter(id => !!residues[id]).length || provider === null}
          >
            {!!order ? 'Сохранить изменения' : 'Создать'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default OrderEditModal;
