import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';

interface ConsumableResiduesCardProps {
  consumable: Document<Consumable>
  qnt: number
  prevQnt?: number
  withEan?: boolean
}

const ConsumableResiduesCard = (props: ConsumableResiduesCardProps) => {
  const {qnt, prevQnt, consumable, withEan} = props
  const {unit, packageName, qpp} = consumable
  const hasPrev = prevQnt !== undefined

  return (
    <Box marginTop={1} marginBottom={1}>
      <Grid container spacing={1}>
        <Grid item xs={hasPrev ? 5 : 7}>
          <ConsumableTitle consumable={consumable} withEan={withEan}/>
        </Grid>
        {prevQnt !== undefined &&
          <Grid item xs={3}>
            <Box>
              {packageName !== 'none' && qpp
                ? <Fragment>
                    <Typography color={prevQnt === qnt ? 'textPrimary' : 'error'}>
                      {`${Math.floor(prevQnt/qpp)} ${packageName} и ${prevQnt%qpp} ${unit}`}
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                      {`${prevQnt} ${unit}`}
                    </Typography>
                  </Fragment>
                : <Typography color={prevQnt === qnt ? 'textPrimary' : 'error'}>
                    {`${prevQnt} ${unit}`}
                  </Typography>
              }
            </Box>
          </Grid>
        }
        {prevQnt !== undefined &&
          <Grid item xs={1}>
            <ArrowRightAlt color='disabled' />
          </Grid>
        }
        <Grid item xs={hasPrev ? 3 : 5}>
          <Box>
            {packageName !== 'none' && qpp
              ? <Fragment>
                  <Typography>
                    {`${Math.floor(qnt/qpp)} ${packageName} и ${qnt%qpp} ${unit}`}
                  </Typography>
                  <Typography variant='caption' color='textSecondary'>
                    {`${qnt} ${unit}`}
                  </Typography>
                </Fragment>
              : <Typography>{`${qnt} ${unit}`}</Typography>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConsumableResiduesCard;
