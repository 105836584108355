import { Search } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { TechMap, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import EditTechMap from './EditTechMap';
import TechMapCard from './TechMapCard';

interface Props {
  userCredentials: UserCredentials
}

function TechMaps(props: Props) {
  const { techMaps, archivedTechMaps, isValidating } = useAdmin();
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const filterFunc = (techMap: TechMap) => {
    return techMap.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      techMap.object.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  }

  return (
    <Box padding={2} maxWidth={960} margin='0 auto'>
      <Loader open={isValidating} />

      <Box marginTop={3} marginBottom={5} display='flex' justifyContent='space-between'>
        <TextField variant="outlined" label="Поиск" size='small'
          InputProps={{ endAdornment: <Search /> }}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />

        {props.userCredentials.techMaps_edit &&
          <Box marginLeft={2}>
            <Button variant='outlined' color='primary' style={{height: '100%'}}
              onClick={() => setOpen(true)}
            >
              Добавить тех карту
            </Button>
          </Box>
        }
      </Box>

      {!!techMaps.length &&
        techMaps.filter(filterFunc).map(techMap => (
          <Box key={techMap.id} marginBottom={2}>
            <TechMapCard techMap={techMap} userCredentials={props.userCredentials}/>
          </Box>
        ))
      }

      {!!archivedTechMaps.length &&
        <>
          <Typography color="gray" variant="h5" sx={{mt: 6, mb: 2, ml: 4}}>Архив</Typography>

          {archivedTechMaps.filter(filterFunc).map(techMap => (
            <Box key={techMap.id} marginBottom={2}>
              <TechMapCard techMap={techMap} userCredentials={props.userCredentials}/>
            </Box>
          ))}
        </>
      }

      {props.userCredentials.users_edit_credentials && open &&
        <EditTechMap open={open} onClose={() => setOpen(false)} userCredentials={props.userCredentials}/>
      }
    </Box>
  );
}

export default TechMaps;
