import { Search } from '@mui/icons-material';
import { Autocomplete, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import format from 'date-fns/format';
import ru from "date-fns/locale/ru";
import { debounce, uniqBy } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { ArchivedStoreLog, Consumable } from '../../apiTypes';
import { useAdmin } from '../../contexts/admin.context';
import { getDateFromDateCode } from '../../utils';
import ConsumablesReportCell from './ConsumablesReportCell';


interface Props {
  dateCodes: number[]
  storesLogs: ArchivedStoreLog[]
  consumables: Document<Consumable>[]
}

function ConsumablesByDay(props: Props) {
  const { dateCodes, storesLogs, consumables } = props
  const { stores, storesMap, consumablesByCategories } = useAdmin()
  const categories = Object.keys(consumablesByCategories)
  const [store, setStore] = useState('all')
  const [object, setObject] = useState('all')
  const [category, setCategory] = useState('all')
  const [inpunValue, setInputValue] = useState('')
  const [search, setSearch] = useState('')
  const applySearch = useMemo(() => debounce(setSearch, 500), []);

  const filteredStoresLogs = useMemo(() => {
    return storesLogs.filter(l => {
      return l.type === 'spend' && !l.canceled && (store === 'all' || l.from === store)
    })
  }, [storesLogs, store])

  const objects = useMemo(() => {
    const objects = uniqBy(filteredStoresLogs, l => l.to).map(l => l.to)
    if (object !== 'all' && !objects.includes(object)) {
      setObject('all')
    }
    return objects
  }, [filteredStoresLogs])

  const filteredObjectsLog = useMemo(() => {
    return object === 'all'
      ? filteredStoresLogs
      : filteredStoresLogs.filter(l => l.to === object)
  }, [filteredStoresLogs, object])

  const filteredConsumables = useMemo(() => {
    return category === 'all'
      ? consumables.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()))
      : consumables.filter(c => {
        return (category === 'withoutCategory' ? c.category === '' : c.category === category) && (!search
          || c.name.toLowerCase().includes(search.toLowerCase()))
      })
  }, [consumables, category, search])

  return (
    <Fragment>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{mb: 2, justifyContent: 'center'}}>
        <Autocomplete
          sx={{minWidth: '200px'}}
          value={store}
          options={['all', ...stores.map(s => s.id)]}
          renderInput={(params) => <TextField {...params} size='small' label="Cклад" />}
          disableClearable
          onChange={(e, v) => setStore(v)}
          getOptionLabel={o => o === 'all' ? 'Все склады' : storesMap[o].name}
        />
        <Autocomplete
          sx={{minWidth: '200px'}}
          value={object}
          options={['all', ...objects]}
          renderInput={(params) => <TextField {...params} size='small' label="Зона списания" />}
          disableClearable
          onChange={(e, v) => setObject(v)}
          getOptionLabel={o => o === 'all' ? 'Все зоны' : o}
        />
        <Autocomplete
          sx={{minWidth: '200px'}}
          value={category}
          options={['all', ...categories]}
          renderInput={(params) => <TextField {...params} size='small' label="Фильтр по категориям" />}
          disableClearable
          onChange={(e, v) => setCategory(v)}
          getOptionLabel={o => o === 'all' ? 'Все' : o === 'withoutCategory' ? 'Без категории' : o}
        />
      </Stack>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 240px)'}}>
        <Table
          stickyHeader
          size='small'
          sx={{
            width: 'auto',
            mx: 'auto',
            '& td': {padding: '3px'},
            '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
            '& th': {padding: '4px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
            '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3},
            '& tr': {verticalAlign: 'middle'},
            'tr:hover td:first-child': {backgroundColor: t => t.palette.grey[300]},
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
              <TextField
                sx={{minWidth: '200px', mx: 1}}
                value={inpunValue}
                size='small'
                label='Поиск'
                onChange={e => {
                  setInputValue(e.target.value)
                  applySearch(e.target.value)
                }}
                InputProps={{
                  endAdornment: <Search/>
                }}
              />
              </TableCell>

              {dateCodes.map(dc => {
                const date = getDateFromDateCode(dc)
                const day = format(date, 'EEEEEE', {locale: ru})
                return (
                  <TableCell key={dc} align='center'>
                    <Box sx={{p: '2px' }} >
                      {format(date, 'dd.MM')}
                    </Box>

                    <Typography color={(day === 'сб' || day === 'вс') ? 'orange' : 'default'}>
                      {day}
                    </Typography>
                  </TableCell>
                )
              })}
              <TableCell>Всего</TableCell>
              <TableCell>Среднее</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredConsumables.map(consumable => {
              const consumableLogs = filteredObjectsLog.filter(l => Object.keys(l.residues).includes(consumable.id))

              if (!consumableLogs.length) {
                return null
              }

              const total = consumableLogs.reduce((acc, l) => {
                return acc + l.residues[consumable.id]
              }, 0)

              const daysCount = uniqBy(consumableLogs, l => l.date).length
              const average = Math.round((total/daysCount)*10)/10

              return(
                <TableRow key={consumable.id} sx={{ verticalAlign: 'top', ":hover": {backgroundColor: t => t.palette.primary.light + '20'}}}>
                  <TableCell align='right'>
                    <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }} >
                      {`${consumable.name}, ${consumable.unit}`}
                    </Typography>
                  </TableCell>

                  {dateCodes.map(dc => {
                    const date = format(getDateFromDateCode(dc), 'dd.MM.yyyy')
                    const filteredLogs = consumableLogs.filter(l => l.date === date)
                    const spendByDay = filteredLogs.reduce((acc, l) => {
                      return acc + l.residues[consumable.id]
                    }, 0)
                    return (
                      <TableCell key={dc} align='center' sx={{ ':hover': { backgroundColor: t => t.palette.primary.light + '30' } }}>
                        <ConsumablesReportCell
                          total={spendByDay}
                          logs={filteredLogs}
                          consumable={consumable}
                          average={average}
                        />
                      </TableCell>
                    )
                  })}
                  <TableCell>
                    <Typography variant='subtitle2'>
                      {total}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='subtitle2'>
                      {average}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>

      {(!filteredObjectsLog.length || !filteredConsumables.length) &&
        <Typography variant='h6' sx={{ m: 5, textAlign: 'center' }}>
          За этот период не найдено событий расхода
        </Typography>
      }
    </Fragment>
  );
}

export default ConsumablesByDay;
