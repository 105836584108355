import { Delete, ExpandMore, Phone } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Collapse, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import { Params, User, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { db } from '../../firebase';
import EditUser from './EditUser';


interface UserCardProps {
  user: Document<User>
  userCredentials: UserCredentials
}

const UserCard = (props: UserCardProps) => {
  const { companySlug } = useParams<Params>();
  const {userCredentials, user} = props
  const {
    displayName,
    photoURL,
    phone,
    email,
  } = user

  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const removeUser = async () => {
    setLoading(true)
    await deleteDoc(doc(db, "company", companySlug, "users", email));
    setOpen(false)
    setLoading(true)
  }

  return (
    <Box marginTop={2}>
      <Loader open={loading} />

      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          avatar={<Avatar alt={displayName} src={photoURL} />}
          title={displayName}
          subheader={email}
          action={phone
            ? (
              <Box paddingTop={1}>
                <a href={`tel:${phone}`}>
                  <IconButton aria-label="phone"><Phone /></IconButton>
                </a>
              </Box>
            ) : null
          }
        />

        {userCredentials.users_edit_credentials &&
          <CardActions disableSpacing sx={{ backgroundColor: t => t.palette.grey[50] }} >
            <Box display='flex' justifyContent='space-between' width='100%' marginLeft={1}>
              <Button
                color='error'
                startIcon={<Delete/>}
                disabled={user.type === 'manager'}
                onClick={() => setOpen(true)}
              >
                Удалить
              </Button>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show details"
              >
                <ExpandMore />
              </IconButton>
            </Box>
          </CardActions>
        }

        {userCredentials.users_edit_credentials &&
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <EditUser email={user.email}/>
            </CardContent>
          </Collapse>
        }

        {userCredentials.users_edit_credentials &&
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Вы умерены, что хотите удалить пользователя?</DialogTitle>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Отмена
              </Button>
              <Button onClick={removeUser} color='secondary' autoFocus>
                Удалить
              </Button>
            </DialogActions>
          </Dialog>
        }
      </Card>
    </Box>
  )
}


export default UserCard;

