import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface Props {
  open: boolean
}

function Loader(props: Props) {

  return (
    <Backdrop
      sx={{
        zIndex: 2000,
        color: '#fff',
      }}
      open={props.open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;
