import { doc, updateDoc } from "@firebase/firestore";
import { ExpandLess, ExpandMore, Send } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Document } from "swr-firestore-v9";
import { Author, Params, Task, TaskStatus, Worker } from "../apiTypes";
import SmartAvatar from "../components/SmartAvatar";
import { Map, getInitMap, translations } from "../constants";
import { db } from "../firebase";
import { getDateCode } from "../utils";

interface Props {
  worker: Document<Worker>;
  tasks: Document<Task>[];
  authorsMap: Map<Author>;
  todayDateCode: number;
}

const TerminalTasks = (props: Props) => {
  const { worker, tasks, authorsMap, todayDateCode } = props;
  const { companySlug } = useParams<Params>();
  const [opened, setOpened] = useState<string | null>(null);
  const [showAllComments, setShowAllComments] = useState(false);
  const [text, setText] = useState("");

  const saveComment = (task: Document<Task>) => {
    if (text.trim()) {
      updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
        comments: [
          ...task.comments,
          {
            time: new Date().getTime(),
            author: worker.id,
            comment: text,
            viewed: [worker.id],
          },
        ],
      });
    }
    setText("");
  };

  const changeStatus = (task: Document<Task>, status: TaskStatus) => {
    updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
      status,
      comments: [
        ...task.comments,
        {
          time: new Date().getTime(),
          author: worker.id,
          comment: `Изменил(а) статус на "${translations[status]}"`,
          viewed: [worker.id],
        },
      ],
    });
  };

  const unViewedMap = useMemo(() => {
    return tasks.reduce((acc, t) => {
      const count = t.comments.reduce((cAcc, c) => {
        if (!c.viewed.includes(worker.id)) {
          cAcc = cAcc + 1;
        }
        return cAcc;
      }, 0);

      acc[t.id] = count;
      return acc;
    }, getInitMap<number>());
  }, [tasks]);

  useEffect(() => {
    if (opened) {
      const task = tasks.find((t) => t.id === opened);
      const hasUnViewed = Object.keys(unViewedMap).some(
        (id) => !!unViewedMap[id]
      );
      if (task && hasUnViewed) {
        const newComments = task.comments.map((c) => {
          const newViewed = c.viewed.includes(worker.id)
            ? c.viewed
            : [...c.viewed, worker.id];
          return { ...c, viewed: newViewed };
        });

        updateDoc(doc(db, "company", companySlug, "tasks", task.id), {
          comments: newComments,
        });
      }
    }
  }, [opened]);

  return (
    <Fragment>
      <Typography variant="h6" sx={{ my: 2 }} align="center">
        Задачи
      </Typography>

      <Box maxWidth={500} margin="0 auto">
        {tasks.map((task) => {
          const taskDateCode = getDateCode(new Date(task.time));
          const showedComments =
            showAllComments || task.comments.length < 5
              ? task.comments
              : task.comments.slice(task.comments.length - 5);

          return (
            <Box key={task.id} marginBottom={2}>
              <Card variant="outlined">
                <CardHeader
                  onClick={() => {
                    setOpened(opened === task.id ? null : task.id);
                    showAllComments && setShowAllComments(false);
                  }}
                  sx={{
                    backgroundColor: (t) => t.palette.grey[50],
                    overflow: "auto",
                  }}
                  title={task.name}
                  titleTypographyProps={{ variant: "body1" }}
                  subheader={
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          color:
                            taskDateCode > todayDateCode
                              ? "success.main"
                              : taskDateCode === todayDateCode
                              ? "warning.main"
                              : "error.main",
                        }}
                      >
                        {format(new Date(task.time), "dd MMMM", { locale: ru })}
                      </Box>

                      <Box sx={{ ml: 1 }}>
                        {"| " + translations[task.status]}
                      </Box>
                    </Box>
                  }
                  action={
                    <Box paddingTop={1}>
                      <Badge
                        badgeContent={unViewedMap[task.id]}
                        color="error"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <IconButton
                          onClick={() => {
                            setOpened(opened === task.id ? null : task.id);
                            showAllComments && setShowAllComments(false);
                          }}
                          aria-expanded={opened === task.id}
                          aria-label="show details"
                        >
                          {opened === task.id ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </Badge>
                    </Box>
                  }
                />

                <Collapse in={opened === task.id} timeout="auto" unmountOnExit>
                  <Divider />
                  <CardContent>
                    {task.status === "planned" && (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => changeStatus(task, "active")}
                        sx={{ mb: 2 }}
                      >
                        Приступить
                      </Button>
                    )}

                    {task.status === "active" && (
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => changeStatus(task, "checking")}
                        sx={{ mb: 2 }}
                      >
                        Выполнено
                      </Button>
                    )}

                    {task.description && (
                      <Fragment>
                        <Typography variant="subtitle2">Описание:</Typography>
                        <Box sx={{ mb: 3, pl: 2 }}>{task.description}</Box>
                      </Fragment>
                    )}

                    <Typography sx={{ mb: 1 }} variant="subtitle2">
                      Комментарии:
                    </Typography>
                    {task.comments.length > 5 && !showAllComments && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          my: 1,
                        }}
                      >
                        <Button
                          size="small"
                          sx={{ px: 1, py: 0 }}
                          onClick={() => setShowAllComments(true)}
                        >
                          Отобразить все комментарии ({task.comments.length})
                        </Button>
                      </Box>
                    )}
                    <Box sx={{ mb: 3, pl: 2 }}>
                      {showedComments.map((comment) => {
                        const author = authorsMap[comment.author] || {};
                        return (
                          <Box
                            sx={{ display: "flex", mb: 2 }}
                            key={comment.time}
                          >
                            <SmartAvatar
                              name={author.name}
                              photoURL={author.photoURL}
                              sx={{ mr: 2 }}
                            />
                            <div>
                              <div>
                                <Box component="span" sx={{ fontWeight: 500 }}>
                                  {author.name}
                                </Box>
                                <Box
                                  component="span"
                                  sx={{ color: "text.secondary", ml: 1 }}
                                >
                                  {format(
                                    new Date(comment.time),
                                    "dd MMM, HH:mm",
                                    { locale: ru }
                                  )}
                                </Box>
                              </div>
                              <Box>{comment.comment}</Box>
                            </div>
                          </Box>
                        );
                      })}
                    </Box>

                    <TextField
                      sx={{ backgroundColor: "#fff" }}
                      multiline
                      label="Добавить комментарий"
                      value={text}
                      fullWidth
                      onChange={(e) => setText(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && saveComment(task)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => saveComment(task)}
                              disabled={!text.trim()}
                            >
                              <Send />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardContent>
                </Collapse>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Fragment>
  );
};

export default TerminalTasks;
