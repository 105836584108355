import { useTheme } from '@mui/material';
import format from 'date-fns/format';
import { Bar } from 'react-chartjs-2';
import { Document } from 'swr-firestore-v9';
import { TechMap, WorkShift } from '../../apiTypes';
import { Map } from '../../constants';
import { getDateFromDateCode } from '../../utils';


interface Props {
  dateCodes: number[]
  workShifts: Document<WorkShift>[]
  techMapsMap: Map<Document<TechMap>>
}

function PayoutsChart(props: Props) {
  const { dateCodes, workShifts, techMapsMap } = props
  const theme = useTheme()

  const labels = dateCodes.map(dc => {
    const date = getDateFromDateCode(dc)
    return format(date, 'dd.MM')
  })

  return (
    <Bar
      data={{
        labels: labels,
        datasets: [
          {
            label: 'Начислено',
            backgroundColor: theme.palette.primary.main,
            data: dateCodes.map(dc => {
              const filteredWorkShifts = workShifts.filter(ws => ws.closed && ws.dateCode === dc)
              const total = filteredWorkShifts.reduce((acc, ws) => {
                return acc + (ws.rate || techMapsMap[ws.techMap].rate)
              }, 0)
              return total
            })
          },
          {
            label: 'Запланировано',
            backgroundColor: theme.palette.primary.light,
            data: dateCodes.map(dc => {
              const filteredWorkShifts = workShifts.filter(ws => !ws.closed && ws.dateCode === dc)
              const total = filteredWorkShifts.reduce((acc, ws) => {
                return acc + (ws.rate || techMapsMap[ws.techMap].rate)
              }, 0)
              return total
            })
          },
        ]
      }}
    />
  );
}

export default PayoutsChart;
