import { Consumable } from "./apiTypes";

export const getQntStr = (consumable: Consumable, qnt: number) => {
  const { qpp, packageName, unit } = consumable

  return qpp
    ? `${Math.floor(qnt/qpp)} ${packageName} и ${qnt%qpp} ${unit}`
    : `${qnt} ${unit}`
}

export const getDateCode = (date: Date | null) => {
  if (date) {
    return Number(date.toLocaleDateString('ru').split('.').reverse().join(''))
  }
  return 0
}

export const incrementDateCode = (dateCode: number) => {
  const date = getDateFromDateCode(dateCode)
  date.setDate(date.getDate() + 1)
  return getDateCode(date)
}

export const getDateFromDateCode = (dateCode: number) => {
  const dateCodeStr = dateCode.toString()
  return new Date(`${dateCodeStr.substring(0, 4)}/${dateCodeStr.substring(4, 6)}/${dateCodeStr.substring(6)}`)
}

interface SendPushProps {
  title: string,
  body: string,
  msgToken: string
  url: string
}

export const sendPush = (props: SendPushProps) => {
  fetch('https://fcm.googleapis.com/fcm/send', {
    method: 'POST',
    headers: {
      Authorization: 'key=AAAAVznV6-Q:APA91bHCk048LTflrfj3oMaou4PH2TiGgFSjaBCUbZH8fLD8vSXLJX6LPJrSX_Fd4E9rBlbxbQRMQ0BetVbJpUPz9t3TVh05QbYvZ0rH4B5qKCS_mSFJc1x7CgECriZB7yXa6uCXqMBf',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      notification: {
        title: props.title,
        body: props.body,
        icon: 'https://jimba.cloud/logo192.png',
        click_action: props.url,
        default_sound: true,
        notification_priority: 'PRIORITY_MAX',
        silent: false,
        sound: 'default',
      },
      to: props.msgToken,
      time_to_live: 80_000
    })
  })
}
