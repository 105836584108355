import { signOut } from '@firebase/auth';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { auth } from '../firebase';

interface Props {

}

const OtherMenu = (props: Props) => {
  return (
    <Box>
      <Typography variant='h6' sx={{my: 2}} align='center'>
        Другое
      </Typography>

      <Button
        onClick={() => signOut(auth)}
        variant='outlined'
        fullWidth
        color='warning'
      >
        Сменить аккаунт
      </Button>
    </Box>
  )
}

export default OtherMenu
