import { doc, increment, writeBatch } from '@firebase/firestore';
import { Close, Delete } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router-dom';
import { Document, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Consumable, Params, Residues, Store, StoreLog, User, UserCredentials } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import { useAdmin } from '../../contexts/admin.context';
import { auth, db } from '../../firebase';
import { getQntStr } from '../../utils';
import ConsumablesInput from '../Stores/ConsumablesInput';

interface Props {
  open: boolean
  onClose: () => void
  consumablesByCategories: { [category: string]: Document<Consumable>[] }
  consumables: Document<Consumable>[]
  userCredentials: UserCredentials
}

const ConsumablesEventModal = (props: Props) => {
  const { open, onClose, consumablesByCategories, consumables, userCredentials } = props
  const { companySlug } = useParams<Params>();
  const [userAuth] = useAuthState(auth);
  const { writeOffZonesMap, stores, storesMap } = useAdmin()
  const user = useDocument<User>(userAuth ? `company/${companySlug}/users/${userAuth.email}` : null)
  const userName = user.data?.displayName;

  const [tabIndex, setTabIndex] = useState(userCredentials.consumables_move ? 0 : 1)
  const [fromStoreId, setFromStoreId] = useState<string | null>(null)
  const [fromStore, setFromStore] = useState<Document<Store> | null>(null)
  const [toStoreId, setToStoreId] = useState<string | null>(null)
  const [toStore, setToStore] = useState<Document<Store> | null>(null)
  const [residues, setResidues] = useState<Residues>({})
  const [focusedResidues, setFocusedResidues] = useState<string>('')
  const [adding, setAdding] = useState(false);

  const saveChanges = () => {
    if (fromStoreId && toStoreId) {
      const newResidues: Residues = {}
      const date = new Date()
      const fromStoreChanges: {[key: string] : any} = {}
      const toStoreChanges: {[key: string] : any} = {}

      Object.keys(residues).forEach(id => {
        if (residues[id]) {
          newResidues[id] = residues[id]
        }
      })

      const log: StoreLog = {
        date: format(date, 'dd.MM.yyyy'),
        time: date.getTime(),
        author: userName || 'Неизвестный пользователь',
        type: tabIndex === 0 ? 'move' : 'spend',
        from: fromStoreId,
        to: toStoreId,
        residues: newResidues,
      }

      Object.keys(newResidues).forEach(id => {
        const residuesKey = `residues.${id}`
        fromStoreChanges[residuesKey] = increment(-newResidues[id])
        toStoreChanges[residuesKey] = increment(newResidues[id])
      })

      const batch = writeBatch(db);
      batch.set(doc(db, "company", companySlug, "storesLogs", uniqid()), log)
      batch.update(doc(db, "company", companySlug, "stores", fromStoreId), fromStoreChanges)

      if (tabIndex === 0) {
        batch.update(doc(db, "company", companySlug, "stores", toStoreId), toStoreChanges)
      }

      batch.commit()
      onClose()
    }
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullScreen={document.documentElement.clientWidth < 860} maxWidth='md' fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Новое событие
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box minHeight={400}>
            <Box marginBottom={4}>
              <Tabs
                value={tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, i) => {
                  setToStoreId(null)
                  setToStore(null)
                  setResidues({})
                  setTabIndex(i)
                }}
                centered={true}
              >
                <Tab label="Перемещение" disabled={!userCredentials.consumables_move} />
                <Tab label="Расход" disabled={!userCredentials.consumables_spend} />
              </Tabs>
            </Box>

            <Box marginTop={4} marginBottom={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={fromStoreId}
                    onChange={(_, val: string | null) => {
                      setToStoreId(null)
                      setToStore(null)
                      setFromStoreId(val)
                      setResidues({})
                      setFromStore(stores.find(s => s.id === val) || null)
                    }}
                    options={stores.map(s => s.id) || []}
                    openOnFocus
                    getOptionLabel={o => storesMap[o]?.name || ''}
                    renderInput={(params) => <TextField
                      {...params}
                      label="Со склада"
                      variant="outlined"
                      size="small"
                    />}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={toStoreId}
                    onChange={(_, val: string | null) => {
                      setToStoreId(val)
                      setToStore(stores.find(s => s.id === val) || null)
                    }}
                    options={tabIndex === 0
                      ? stores.map(s => s.id).filter(id => id !== fromStoreId) || []
                      : Object.keys(writeOffZonesMap)
                    }
                    openOnFocus
                    getOptionLabel={o => (tabIndex === 0 && storesMap[o]?.name) || o}
                    renderInput={(params) => <TextField
                      {...params}
                      label={tabIndex === 0 ? "На склад" : "На зону списания"}
                      variant="outlined"
                      size="small"
                    />}
                  />
                </Grid>
              </Grid>
            </Box>

            {Object.keys(consumablesByCategories).map(category => {
              const items = consumablesByCategories[category].filter(c => residues[c.id] !== undefined)

              if (!items.length) {
                return null
              }

              return (
                <Box key={category}>
                  <Typography variant='caption' color='textSecondary'>
                    {category === 'withoutCategory' ? 'Без категории' : category}
                  </Typography>

                  {items.map(consumable => {
                    const { id } = consumable
                    const helperText: string[] = [
                      fromStore ? `${storesMap[fromStore.id]?.name}: ${getQntStr(consumable, fromStore.residues[id] || 0)}` : '',
                    ]

                    if (toStore) {
                      helperText.push(`${storesMap[toStore.id]?.name}: ${getQntStr(consumable, toStore.residues[id] || 0)}`)
                    }

                    return (
                      <Box marginBottom={2} key={id}>
                        <Grid container spacing={2} alignItems='center'>
                          <Grid item xs={12} sm={6}>
                            <Box display='flex' alignItems='center'>
                              <div>
                                <IconButton
                                  size='small'
                                  onClick={() => {
                                    const newResidues: Residues = {}
                                    Object.keys(residues).filter(key => key !== id).forEach(key => newResidues[key] = residues[key])
                                    setResidues(newResidues)
                                  }}
                                >
                                  <Delete/>
                                </IconButton>
                              </div>
                              <ConsumableTitle consumable={consumable}/>
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <ConsumablesInput
                              consumable={consumable}
                              qnt={residues[id]}
                              onChange={newQnt => setResidues({...residues, [id]: newQnt})}
                              availableQnt={fromStore?.residues[id] || 0}
                              helperText={helperText}
                              autofocus={id === focusedResidues}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )
                  })}
                </Box>
              )
            })}

            {fromStoreId && toStoreId && !Object.keys(residues).length &&
              <Box padding={3}>
                <Typography variant='h5' color='textSecondary' align='center'>Список пока пуст</Typography>
              </Box>
            }

            {fromStoreId && toStoreId &&
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {!adding &&
                    <Button onClick={() => setAdding(true)} fullWidth disabled={!fromStoreId || !toStoreId}>
                      Добавить позицию
                    </Button>
                  }

                  {adding &&
                    <Autocomplete
                      size='small'
                      options={consumables.filter(c => fromStore?.residues[c.id] && residues[c.id] === undefined && (tabIndex === 0 || !c.disableSpend))}
                      groupBy={(option) => option.category || 'Без категории'}
                      getOptionLabel={o => o.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <ConsumableTitle consumable={option}/>
                        </Box>
                      )}
                      fullWidth
                      blurOnSelect
                      openOnFocus
                      renderInput={(params) => <TextField {...params} label="Выберите расходник" variant="outlined" autoFocus />}
                      onChange={(e, value) => {
                        if (value) {
                          setResidues({...residues, [value.id]: 0})
                          setFocusedResidues(value.id)
                        }
                      }}
                      onBlur={() =>  setAdding(false)}
                    />
                  }
                </Grid>
              </Grid>
            }
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={saveChanges}
            color="primary"
            disabled={!Object.keys(residues).filter(id => !!residues[id]).length || !fromStoreId || !toStoreId}
          >
            {tabIndex === 0 ? 'Переместить' : 'Списать'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default ConsumablesEventModal;
