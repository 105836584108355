import { AllInbox, MoreVert, TaskAlt, Today } from "@mui/icons-material";
import { Badge, Box, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { Document, useCollection } from "swr-firestore-v9";
import {
  Author,
  Params,
  Task,
  TechMap,
  User,
  WorkShift,
  Worker,
} from "../apiTypes";
import { Map, getInitMap } from "../constants";
import { getDateCode } from "../utils";
import ChooseWorkShift from "./ChooseWorkShift";
import OtherMenu from "./OtherMenu";
import TerminalStores from "./TerminalStores/TerminalStores";
import TerminalTasks from "./TerminalTasks";

interface Props {
  worker: Document<Worker>;
}

const MainScreen = (props: Props) => {
  const { worker } = props;
  const [tabIndex, setTabIndex] = React.useState(0);
  const { companySlug } = useParams<Params>();
  const dateCode = getDateCode(new Date());
  const [workShift, setWorkShift] = useState<string | null>(null);
  const workShifts = useCollection<WorkShift>(
    `company/${companySlug}/workShifts`,
    {
      listen: true,
      where: [
        ["dateCode", ">=", dateCode],
        ["worker", "==", worker.id],
      ],
    }
  );
  const techMaps = useCollection<TechMap>(`company/${companySlug}/techMaps`, {
    listen: true,
  });
  const tasks = useCollection<Task>(`company/${companySlug}/tasks`, {
    listen: true,
    where: [["status", "!=", "closed"]],
  });
  const users = useCollection<User>(`company/${companySlug}/users`, {
    listen: true,
  });
  const workers = useCollection<Worker>(`company/${companySlug}/workers`, {
    listen: true,
  });

  const handleChange = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const availableWorkShifts = useMemo(
    () => workShifts.data?.filter((ws) => ws.dateCode === dateCode) || [],
    [workShifts.data, dateCode]
  );

  const displayedTasks = useMemo(
    () =>
      tasks.data
        ?.filter((t) => t.worker === worker.id)
        .sort((a, b) => a.time - b.time) || [],
    [tasks.data, worker.id]
  );

  const unreadedMessages = useMemo(
    () =>
      displayedTasks
        .flatMap((t) => t.comments)
        .filter((c) => !c.viewed.includes(worker.id)),
    [displayedTasks, worker.id]
  );

  const techMapsMap = useMemo(
    () =>
      (techMaps.data || []).reduce((acc, tm) => {
        acc[tm.id] = tm;
        return acc;
      }, getInitMap<Document<TechMap>>()),
    [techMaps.data]
  );

  const authorsMap: Map<Author> = useMemo(() => {
    const authorsMap: Map<Author> = {};
    if (users.data && workers.data) {
      users.data.forEach((user) => {
        authorsMap[user.id] = {
          name: user.displayName,
          photoURL: user.photoURL,
        };
      });
      workers.data.forEach((worker) => {
        authorsMap[worker.id] = {
          name: worker.lastName + " " + worker.name,
          photoURL: worker.photoURL,
        };
      });
    }
    return authorsMap;
  }, [users.data, workers.data]);

  const workShiftsMap = useMemo(
    () =>
      (workShifts.data || []).reduce((acc, ws) => {
        acc[ws.id] = ws;
        return acc;
      }, getInitMap<Document<WorkShift>>()),
    [workShifts.data]
  );

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: (t) => t.palette.grey[50],
          button: {
            fontSize: "12px",
          },
        }}
        variant="fullWidth"
      >
        <Tab
          icon={
            <Badge badgeContent={unreadedMessages.length} color={"error"}>
              {displayedTasks.length ? (
                <Box
                  sx={{
                    width: 19,
                    height: 19,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: (t) =>
                      tabIndex === 0
                        ? t.palette.success.light
                        : t.palette.text.secondary,
                    borderRadius: 5,
                    color: (t) =>
                      tabIndex === 0
                        ? t.palette.success.light
                        : t.palette.text.secondary,
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {displayedTasks.length}
                </Box>
              ) : (
                <TaskAlt />
              )}
            </Badge>
          }
          label="Задачи"
        />
        <Tab icon={<Today />} label="Смена" />
        <Tab icon={<AllInbox />} label="Расходники" disabled={!workShift} />
        <Tab icon={<MoreVert />} label="Другое" />
      </Tabs>

      <Box
        sx={{
          p: 2,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 72,
          overflow: "auto",
        }}
      >
        {tabIndex === 0 && (
          <TerminalTasks
            worker={worker}
            tasks={displayedTasks}
            authorsMap={authorsMap}
            todayDateCode={dateCode}
          />
        )}

        {workShifts.data && tabIndex === 1 && (
          <ChooseWorkShift
            availableWorkShifts={availableWorkShifts}
            workShifts={workShifts.data}
            workShift={workShift}
            setWorkShift={setWorkShift}
            techMapsMap={techMapsMap}
          />
        )}

        {tabIndex === 2 &&
          workShift &&
          workShiftsMap[workShift] &&
          techMapsMap[workShiftsMap[workShift].techMap] && (
            <TerminalStores
              techMap={techMapsMap[workShiftsMap[workShift].techMap]}
              authorsMap={authorsMap}
              worker={worker}
            />
          )}

        {tabIndex === 3 && <OtherMenu />}
      </Box>
    </Box>
  );
};

export default MainScreen;
