import { DateRange } from '@mui/lab';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Document, useCollection } from 'swr-firestore-v9';
import { Params, Payout, TechMap, Worker, WorkShift } from '../../apiTypes';
import { Map } from '../../constants';
import { getDateFromDateCode } from '../../utils';


interface Props {
  workShifts: Document<WorkShift>[]
  techMapsMap: Map<Document<TechMap>>
  workers: Document<Worker>[]
  dates: DateRange<Date>
}

function PayoutsByWorkersTable(props: Props) {
  const { workShifts, techMapsMap, workers, dates } = props
  const { companySlug } = useParams<Params>()

  const payouts = useCollection<Payout>(`company/${companySlug}/payouts`, {
    where: [
      ['time', '>', dates[0]?.getTime() || 0],
      ['time', '<',  dates[1]?.getTime() || 0],
    ],
  })

  const filteredWorkers = useMemo(() => workers.filter(w => !w.archived), [workers])
  const filteredWorkShifts = useMemo(() => workShifts.filter(ws => ws.closed), [workShifts])
  const totalRates = useMemo(() => filteredWorkShifts.reduce((acc, ws) => acc + (ws.rate || 0), 0), [filteredWorkShifts])
  const totalPayouts = useMemo(() => payouts.data?.reduce((acc, p) => acc + (p.sum || 0), 0), [payouts.data])
  const totalUnpaid = useMemo(() => filteredWorkers.reduce((acc, w) => acc + (w.balance || 0), 0), [filteredWorkers])
  let totalMonthlyUnpaid = 0

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 260px)'}}>
      <Table
        stickyHeader
        size='small'
        sx={{
          mx: 'auto',
          '& td': {padding: '6px'},
          '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.grey[100] },
          '& th': {padding: '6px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
          '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3},
          '& tr': {verticalAlign: 'middle'},
          'tr:hover td:first-child': {backgroundColor: t => t.palette.grey[300]},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Сотрудник</TableCell>
            <TableCell align='center'>Начисления</TableCell>
            <TableCell align='center'>Всего начислено</TableCell>
            <TableCell align='center'>Выплаты</TableCell>
            <TableCell align='center'>Не выплачено всего</TableCell>
            <TableCell align='center'>Не выплачено <br/> за прошлый период</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredWorkers.map((worker) => {
            const filteredWorkShifts = workShifts.filter(ws => ws.closed && ws.worker === worker.id)
            const totalMonthlyRates = filteredWorkShifts.reduce((acc, ws) => {
              return acc + (ws.rate || 0)
            }, 0)
            const filteredPayouts = payouts.data?.filter(p => p.closed && p.worker === worker.id) || []
            const monthlyUnpaid = worker.balance - totalMonthlyRates > 0
              ? worker.balance - totalMonthlyRates
              : 0
            totalMonthlyUnpaid = totalMonthlyUnpaid + monthlyUnpaid
            let totalRates = 0
            return (
              <TableRow key={worker.id} sx={{":hover": {backgroundColor: t => t.palette.primary.light + '20'}}}>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{worker.lastName + ' ' + worker.name}</TableCell>
                <TableCell align='center'>
                  {filteredWorkShifts.map(ws => {
                    totalRates = totalRates + (ws.rate || 0)

                    return (
                      <Tooltip
                        key={ws.id}
                        disableInteractive
                        title={
                          <Box>
                            <p>{format(getDateFromDateCode(ws.dateCode), 'dd.MM.yyyy')}</p>
                            <p>{techMapsMap[ws.techMap]?.object + ' ' + techMapsMap[ws.techMap]?.name}</p>
                            <p>Плановая ставка: {ws.plannedRate}</p>
                            <p>Начисленная ставка: {ws.rate}</p>
                            {ws.comment && <p>Комментарий: {ws.comment}</p>}
                          </Box>
                        }
                      >
                        <Box
                          component='span'
                          sx={{
                            color: t => ws.rate !== ws.plannedRate ? t.palette.error.dark : 'inherit',
                            cursor: 'default',
                            fontSize: '12px',
                            p: '3px',
                            ':hover': { backgroundColor: t => t.palette.primary.light + '50' }
                          }}
                        >
                          {ws.rate}
                        </Box>
                      </Tooltip>
                    );
                  })
                  }
                </TableCell>
                <TableCell align='center'>{totalRates}</TableCell>
                <TableCell align='center'>
                  {filteredPayouts.map(p => {
                    return (
                      <Tooltip
                        key={p.id}
                        disableInteractive
                        title={
                          <Box>
                            <p>{'Выплата №' + p.number}</p>
                            <p>{format(p.time, 'dd.MM.yyyy')}</p>
                          </Box>
                        }
                      >
                        <Box
                          component='span'
                          sx={{
                            cursor: 'default',
                            p: '3px',
                            ':hover': { backgroundColor: t => t.palette.primary.light + '50' }
                          }}
                        >
                          {p.sum}
                        </Box>
                      </Tooltip>
                    );
                  })
                  }
                </TableCell>
                <TableCell align='center'>{worker.balance}</TableCell>
                <TableCell align='center'>
                  {!!monthlyUnpaid && monthlyUnpaid}
                </TableCell>
              </TableRow>
            )
          })}

          <TableRow sx={{'& td': {m: 1, fontSize: '18px'}}}>
            <TableCell align='right'>Всего</TableCell>
            <TableCell/>
            <TableCell align='center'>{totalRates}</TableCell>
            <TableCell align='center'>{totalPayouts}</TableCell>
            <TableCell align='center'>{totalUnpaid}</TableCell>
            <TableCell align='center'>{totalMonthlyUnpaid}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PayoutsByWorkersTable;
