import { Close } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, TextField } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from '../../apiTypes';
import Loader from '../../components/Loader';
import { adminCredentials, credentialsKeys, credentialsLabels } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';

interface Params {
  companySlug: string
}

interface Props {
  open: boolean
  onClose: () => void
}

const initialTouched = {phone: false, email: false, displayName: false}

const CreateUserModal = (props: Props) => {
  const { open, onClose } = props
  const { companySlug } = useParams<Params>();
  const { users } = useAdmin()
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [touched, setTouched] = useState(initialTouched)
  const [credentials, setCredentials] = useState(adminCredentials)
  const [loading, setLoading] = useState(false)

  const emailError = users.some(user => user.email === email)

  const handleChangeCredentials = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.checked });
  };

  const saveChanges = async () => {
    setLoading(true)
    const user: User = {
      email,
      phone,
      credentials,
      photoURL: '',
      type: 'admin',
      displayName,
    }
    await setDoc(doc(db, "company", companySlug, "users", email), user)
    clearFields()
    onClose()
    setLoading(false)
  }

  const clearFields = () => {
    setPhone('')
    setEmail('')
    setDisplayName('')
    setTouched(initialTouched)
    setCredentials(adminCredentials)
  }

  const handleClose = () => {
    clearFields()
    onClose()
  }


  return (
    <Dialog onClose={handleClose} open={open}>
      <Loader open={loading} />

      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          Новый пользователь системы
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box marginBottom={4}>
          <TextField
            fullWidth
            id="email"
            required
            label="Google аккаунт (email)"
            variant="outlined"
            value={email}
            error={(touched.email && !email) || emailError}
            helperText={
              (emailError && 'Такой пользователь уже существует') ||
              (touched.email && !email && 'Обязательное поле')
            }
            onChange={e => {
              setEmail(e.target.value)
            }}
            onBlur={() => setTouched({...touched, email: true})}
          />
        </Box>

        <Box marginBottom={4}>
          <TextField
            fullWidth
            id="user-name"
            required
            label="Имя пользователя"
            variant="outlined"
            value={displayName}
            error={touched.displayName && !displayName}
            helperText={touched.displayName && !displayName && 'Обязательное поле'}
            onChange={e => {
              setDisplayName(e.target.value)
            }}
            onBlur={() => setTouched({...touched, displayName: true})}
          />
        </Box>

        <Box marginBottom={4}>
          <TextField
            fullWidth
            id="phone-number"
            label="Номер телефона"
            placeholder="+380123456789"
            variant="outlined"
            value={phone}
            onChange={e => {
              const val = e.target.value
              if (/^[+]{0,1}[0-9]{0,13}$/.test(val)) {
                setPhone(val[0] === '+' || val === '' ? val : `+${val}`)
              }
            }}
            onBlur={() => setTouched({...touched, phone: true})}
          />
        </Box>

        <Box marginBottom={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Права доступа</FormLabel>
            <FormGroup>
              {credentialsKeys.map((key) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={credentials[key]}
                      onChange={handleChangeCredentials}
                      name={key}
                    />
                  }
                  label={credentialsLabels[key]}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={saveChanges} color="primary" disabled={!email || !displayName}>
          Создать пользователя
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUserModal;
