import {
  ArrowDropDown,
  ArrowDropUp,
  CopyAll,
  DeleteOutline,
  KeyboardReturn,
  PlaylistAdd,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import uniqid from "uniqid";
import { TechMapAction } from "../../apiTypes";
import Action from "./Action";
import { getTime } from "./utils";

const getNewAction = (): TechMapAction => ({
  id: uniqid(),
  place: "",
  name: "",
  instructions: "",
  duration: 30,
});

interface Props {
  startTime: string;
  actions: TechMapAction[];
  setActions: (actions: TechMapAction[]) => void;
  dublicate?: () => void;
}

const Actions = (props: Props) => {
  const { startTime, actions, setActions, dublicate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowIndex, setRowIndex] = useState<number>(0);

  const addRow = () => {
    setActions([
      ...actions.slice(0, rowIndex + 1),
      getNewAction(),
      ...actions.slice(rowIndex + 1),
    ]);
    setAnchorEl(null);
  };

  const addLastRow = () => {
    setActions([...actions, getNewAction()]);
  };

  const deleteRow = () => {
    setActions([...actions.slice(0, rowIndex), ...actions.slice(rowIndex + 1)]);
    setAnchorEl(null);
  };

  const moveUp = () => {
    setActions([
      ...actions.slice(0, rowIndex - 1),
      actions[rowIndex],
      actions[rowIndex - 1],
      ...actions.slice(rowIndex + 1),
    ]);
    setAnchorEl(null);
  };

  const moveDown = () => {
    setActions([
      ...actions.slice(0, rowIndex),
      actions[rowIndex + 1],
      actions[rowIndex],
      ...actions.slice(rowIndex + 2),
    ]);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAction = (
    index: number,
    field: keyof TechMapAction,
    value: string
  ) => {
    const newActions = actions.map((a) => a);
    newActions[index] = {
      ...newActions[index],
      [field]: field === "duration" ? Number(value) : value,
    };
    setActions(newActions);
  };

  return (
    <Fragment>
      <Paper variant="outlined">
        {!actions.length && (
          <Box margin={3}>
            <Typography color="textSecondary">
              Здесь пока что нет инструкций
            </Typography>
          </Box>
        )}

        {actions.map((action, index) => {
          const [start, end] = getTime(actions, index, startTime);
          return (
            <Box key={index}>
              {index !== 0 && <Divider />}
              <Action
                start={start}
                end={end}
                action={action}
                index={index}
                dayIndex={null}
                changeAction={changeAction}
                onMoreClick={(e) => {
                  setRowIndex(index);
                  setAnchorEl(e.currentTarget);
                }}
              />
            </Box>
          );
        })}

        <Box
          textAlign="left"
          marginTop={1}
          display="flex"
          justifyContent="space-between"
        >
          <Button onClick={addLastRow}>
            <PlaylistAdd />
            Добавить строку
          </Button>

          {!!dublicate && (
            <Button onClick={dublicate}>
              <CopyAll />
              Дублировать базовые
            </Button>
          )}
        </Box>
      </Paper>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={addRow}>
          <KeyboardReturn />
          Добавить строку cнизy
        </MenuItem>
        <MenuItem onClick={moveUp} disabled={rowIndex === 0}>
          <ArrowDropUp />
          Переместить вверх
        </MenuItem>
        <MenuItem onClick={moveDown} disabled={rowIndex === actions.length - 1}>
          <ArrowDropDown />
          Переместить вниз
        </MenuItem>
        <MenuItem onClick={deleteRow}>
          <DeleteOutline />
          Удалить
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default Actions;
