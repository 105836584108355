import { Close, Image } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import React, { Fragment, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router-dom';
import { Document, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Consumable, Params, Residues, Store, StoreLog, User } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import { auth, db } from '../../firebase';
import ConsumablesInput from './ConsumablesInput';

interface Props {
  open: boolean
  onClose: () => void
  store: Document<Store>
  consumablesByCategories: { [category: string]: Document<Consumable>[] }
  consumables: Document<Consumable>[]
}

const TakingModal = (props: Props) => {
  const { open, onClose, store, consumablesByCategories, consumables } = props
  const { companySlug } = useParams<Params>();
  const [userAuth] = useAuthState(auth);
  const user = useDocument<User>(userAuth ? `company/${companySlug}/users/${userAuth.email}` : null)
  const userName = user.data?.displayName;

  const [residues, setResidues] = useState(store.residues)
  const [adding, setAdding] = useState(false);
  const [focusedResidues, setFocusedResidues] = useState<string>('')

  const saveChanges = () => {
    const newResidues: Residues = {}
    Object.keys(residues).forEach(id => {
      if (residues[id]) {
        newResidues[id] = residues[id]
      }
    })
    const date = new Date()
    const log: StoreLog = {
      date: format(date, 'dd.MM.yyyy'),
      time: date.getTime(),
      author: userName || 'Неизвестный пользователь',
      type: 'taking',
      from: store.id,
      to: store.id,
      prevResidues: store.residues,
      residues: residues,
    }
    setDoc(doc(db, "company", companySlug, "storesLogs", uniqid()), log)
    updateDoc(doc(db, "company", companySlug, "stores", store.id), { residues: newResidues })
    onClose()
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullScreen={document.documentElement.clientWidth < 800} maxWidth='md' fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Инвентаризация склада: {store.name}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          {consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
            const items = consumablesByCategories[category].filter(c => residues[c.id] !== undefined)

            if (!items.length) {
              return null
            }

            return (
              <Box key={category}>
                <Typography>{category === 'withoutCategory' ? 'Без категории' : category}</Typography>

                {items.map(consumable => {
                  const { name, imageUrl, packageName, unit, qpp, id  } = consumable
                  const qnt = store.residues[id]

                  return (
                    <Box marginTop={2} marginBottom={4} key={id}>
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={6} sm={4}>
                          <Box display='flex' alignItems='center'>
                            {imageUrl
                              ? <Avatar alt={name} src={imageUrl || ''} />
                              : <Avatar><Image /></Avatar>
                            }
                            <Box marginLeft={1}>
                              <Typography>{name}</Typography>
                              <Typography variant='caption' color='textSecondary'>
                                {packageName === 'none' ? `${unit}.` : `${qpp} ${unit}. в ${packageName}.`}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={6} sm={2}>
                          <Box>
                            {packageName !== 'none' && qpp
                              ? <Fragment>
                                  <Typography color={qnt === residues[id] ? 'textPrimary' : 'error'}>
                                    {`${qnt ? Math.floor(qnt/qpp) : 0} ${packageName} и ${qnt ? qnt%qpp : 0} ${unit}`}
                                  </Typography>
                                  <Typography variant='caption' color='textSecondary'>
                                    {`${qnt || 0} ${unit}`}
                                  </Typography>
                                </Fragment>
                              : <Typography color={qnt === residues[id] ? 'textPrimary' : 'error'}>
                                  {`${qnt || 0} ${unit}`}
                                </Typography>
                            }
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <ConsumablesInput
                            autofocus={id === focusedResidues}
                            consumable={consumable}
                            qnt={residues[id]}
                            onChange={newQnt => setResidues({...residues, [id]: newQnt})}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
              </Box>
            )
          })}

          <Grid container>
            <Grid item xs={12} sm={6}>
              {!adding &&
                <Button onClick={() => setAdding(true)} fullWidth>
                  Добавить позицию
                </Button>
              }

              {adding &&
                <Autocomplete
                  size='small'
                  options={consumables.filter(c => residues[c.id] === undefined)}
                  groupBy={(option) => option.category}
                  getOptionLabel={o => o.name}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <ConsumableTitle consumable={option}/>
                    </Box>
                  )}
                  fullWidth
                  blurOnSelect
                  openOnFocus
                  renderInput={(params) => <TextField {...params} label="Выберите расходник" variant="outlined" autoFocus />}
                  onChange={(e, value) => {
                    if (value) {
                      setResidues({...residues, [value.id]: 0})
                      setFocusedResidues(value.id)
                    }
                  }}
                  onBlur={() =>  setAdding(false)}
                    />
                }
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={saveChanges} color="primary">
            Провести инвентаризацию
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default TakingModal;
