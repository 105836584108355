import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ruRU } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ruLocale from 'date-fns/locale/ru';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { FuegoProvider } from 'swr-firestore-v9';
import './App.css';
import AdminRouter from './components/AdminRouter';
import { fuego } from './firebase';
import CreationPage from './pages/CreationPage';
import SelectCompany from './pages/SelectCompany';
import TerminalApp from './terminal/TerminalApp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#149E42',
      dark: '#3f8b4b',
      light: '#70ff87',
      contrastText: '#ffffff',
    }
  }
}, ruRU);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
        <FuegoProvider fuego={fuego}>
          <Router>
            <Switch>
              <Route path='/' exact>
                <SelectCompany />
              </Route>
              <Route path='/create' exact>
                <CreationPage />
              </Route>
              <Route path='/:companySlug/terminal'>
                <TerminalApp />
              </Route>
              <Route path='/:companySlug'>
                <AdminRouter />
              </Route>
            </Switch>
          </Router>
        </FuegoProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
