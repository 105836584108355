import { deleteDoc, doc, setDoc } from '@firebase/firestore';
import { Add, Remove } from '@mui/icons-material';
import { Autocomplete, Box, Collapse, Popover, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, UserCredentials, Worker, WorkShift } from '../../apiTypes';
import SmartAvatar from '../../components/SmartAvatar';
import { Map } from '../../constants';
import { db } from '../../firebase';
import { getDateCode } from '../../utils';

interface Props {
  userCredentials: UserCredentials
  workersMap: Map<Document<Worker>>
  workers: Document<Worker>[]
  workShifts: Document<WorkShift>[]
  dateCode: number
  techMapId: string
  lastWorker: string
  setLastWorker: Dispatch<SetStateAction<string>>
}

function Cell(props: Props) {
  const { userCredentials, workShifts, workersMap, workers, dateCode, techMapId, lastWorker, setLastWorker } = props
  const { companySlug } = useParams<Params>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const usedWorker = workShifts.map(ws => ws.worker)
  const toDayDateCode = getDateCode(new Date())
  const canAddNew = userCredentials.journal_edit &&
    (userCredentials.journal_edit_previous_days || toDayDateCode <= dateCode)

  const addWorkShift = (workerId: string) => {
    const workShift: WorkShift = {
      dateCode,
      worker: workerId,
      techMap: techMapId,
      comment: '',
      closed: false,
    }
    setDoc(doc(db, "company", companySlug, "workShifts", uniqid()), workShift)
  }

  const deleteWorkShift = (workShift: Document<WorkShift>) => {
    deleteDoc(doc(db, "company", companySlug, "workShifts", workShift.id))
  }

  return (
    <Box
      sx={{
        p: '4px',
        '& .addButton': {
          display: anchorEl ? 'block' : 'none'
        },
        ':hover': {
          '& .addButton': {
            display: 'block'
          }
        }
      }}
      // onMouseEnter={() => setHover(true)}
      // onMouseLeave={() => setHover(false)}
    >
      {!!workShifts.length &&
        <Box sx={{ position: 'relative' }}>
          {workShifts.map((ws, i) => {
            const worker = workersMap[ws.worker]
            const name = worker.lastName + ' ' + worker.name
            return (
              <Box
                key={name}
                sx={{
                  height: '32px',
                  overflow: 'visible',
                  position: 'relative',
                  top: '-8px',
                  ':first-child': {
                    height: '40px',
                    top: '0px'
                  },
                }}
              >
                <SmartAvatar
                  name={name}
                  photoURL={worker.photoURL}
                  onClick={!ws.closed && canAddNew ? () => deleteWorkShift(ws) : undefined}
                  tooltipProps={{ placement: i === 0 ? 'top' : 'right' }}
                  hoverIcon={!ws.closed && canAddNew ? <Remove/> : undefined}
                  borderColor={toDayDateCode > dateCode ? !ws.closed ? '#d32f2f' : '#555' : '#fff'}
                />
              </Box>
              )
            })}
          {canAddNew &&
            <Box
              className='addButton'
              sx={{ position: 'relative', height: '32px', top: '-8px', overflow: 'visible' }}
            >
              <Box
                sx={{
                  m: '1px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #999',
                  cursor: 'pointer',
                  color: '#555',
                  backgroundColor: '#fff',
                  ':hover': {
                    color: '#fff',
                    backgroundColor: 'primary.main',
                    border: 'none',
                  },
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Add />
              </Box>
            </Box>
          }
        </Box>
      }

      {!workShifts.length &&
        <Box
          sx={{
            m: '1px',
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #999',
            cursor: canAddNew ? 'pointer' : 'default',
            color: anchorEl ? '#555' : 'transparent',
            backgroundColor: '#fff',
            ':hover': {
              color: '#fff',
              backgroundColor: canAddNew ? 'primary.main' : '#fff',
              border: canAddNew ? 'none' : '1px solid #999',
            },
          }}
          onClick={(e) => canAddNew && setAnchorEl(e.currentTarget)}
        >
          {canAddNew &&
            <Add />
          }
        </Box>
      }

      {anchorEl &&
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() =>  setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {width: '300px', height: '40px'}
          }}
          TransitionComponent={Collapse}
        >
            <Autocomplete
              size='small'
              options={workers.filter(w => !usedWorker.includes(w.id)).sort(a => a.id === lastWorker ? -1 : 1)}
              getOptionLabel={o => o.lastName + ' ' + o.name}
              groupBy={o => o.id === lastWorker ? 'Недавние' : 'Все сотрудники'}
              renderOption={(props, o) => (
                <Box component="li" {...props} sx={{display: 'flex'}}>
                  <SmartAvatar name={o.lastName + ' ' + o.name} photoURL={o.photoURL} />
                  <Box marginLeft={1}>{o.lastName + ' ' + o.name}</Box>
                </Box>
              )}
              fullWidth
              blurOnSelect
              openOnFocus
              renderInput={(params) => <TextField {...params} variant="outlined" autoFocus/>}
              onChange={(e, value) => {
                if (value) {
                  lastWorker !== value.id && setLastWorker(value.id)
                  addWorkShift(value.id)
                }
              }}
              onBlur={() =>  setAnchorEl(null)}
            />
        </Popover>
      }
    </Box>
  )
}

export default Cell;
