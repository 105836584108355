
import { GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import { Check, CheckCircle, FileCopy } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, IconButton, InputAdornment, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { doc, setDoc } from 'firebase/firestore';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useHistory } from 'react-router-dom';
import { useDocument } from 'swr-firestore-v9';
import { slugify } from 'transliteration';
import { Company } from '../apiTypes';
import Loader from '../components/Loader';
import { managerCredentials } from '../constants';
import { auth, db } from '../firebase';

const existSlugs = ['create']

function getSteps() {
  return ['Авторизация', 'Создание компании', 'Настройка'];
}

function CreationPage() {
  const [userAuth, userIsValidating] = useAuthState(auth);

  const [activeStep, setActiveStep] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [searchCompany, setSearchCompany] = useState('');
  const [isChecking, setIsChecking] = useState(false)
  const [isFetching, setIsFetching] = useState(false);

  const slug = slugify(companyName);
  const foundCompany = useDocument<Company>(searchCompany ? `company/${slug}` : null)
  const alreadyExist = !!foundCompany.data?.name || existSlugs.includes(searchCompany)

  const setSearchCompanyDebounce = useMemo(() => debounce(setSearchCompany, 600), [])
  const setIsCheckingDebounce = useMemo(() => debounce(setIsChecking, 1000), [])

  const steps = getSteps();
  const history = useHistory();

  const goToAdmin = () => {
    history.push(`/${slug}`)
    return
  }

  const copyPath = () => {
    navigator.clipboard.writeText(`https://jimba.cloud/${slug}`)
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1 && userAuth?.email) {
      setIsFetching(true);
      const newCompany: Company = {
        name: companyName,
        orderNumber: 1001,
        payoutNumber: 1001
      }
      const userObj = {
        displayName: userAuth.displayName,
        email: userAuth.email,
        photoURL: userAuth.photoURL,
        type: 'manager',
        credentials: managerCredentials
      }
      await setDoc(doc(db, "company", slug), newCompany);
      await setDoc(doc(db, "company", slug, "users", userAuth.email), userObj);
      setIsFetching(false);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const login = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {}).catch((error) => {});
  }


  return (
    <Box marginTop={4} maxWidth='800px' margin='40px auto'>
      <Loader open={isFetching || userIsValidating} />

      <Box
        sx={{
          backgroundImage: 'url("/jimbo_logo.png")',
          backgroundSize: 'cover',
          width: '180px',
          height: '80px',
          m: '50px auto',
        }}
      />

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box padding={4} >
        {activeStep === 0 && userAuth &&
          <Box maxWidth='300px' margin='0 auto'>
              <Card>
                <CardHeader
                  avatar={<Avatar alt={userAuth.displayName || ''} src={userAuth.photoURL || ''} />}
                  title={userAuth.displayName}
                  subheader={userAuth.email}
                />
              </Card>
              <Box marginTop={1}>
                <Button variant="outlined" onClick={login} >
                  Сменить учетную запись Google
                </Button>
              </Box>
          </Box>
        }
        {
          activeStep === 0 && !userAuth &&
          <Box marginTop={1} display='flex' justifyContent='center'>
            <Button variant="outlined" onClick={login} >
              Войти с помощью Google
            </Button>
          </Box>
        }

        {activeStep === 1 &&
          <Box maxWidth='300px' margin='0 auto'>
            <TextField
              id="company-name"
              label="Название компании"
              variant="outlined"
              value={companyName}
              fullWidth
              InputProps={{
                endAdornment: isChecking || foundCompany.isValidating
                  ? <div><CircularProgress size={16}/></div>
                  : !companyName || alreadyExist ? null : <Check />
              }}
              onChange={e => {
                const {value} = e.target
                // if (/^([0-9 a-z-]+|\d+)$/i.test(value) || value === '') {
                  setCompanyName(value)
                  !isChecking && setIsChecking(true)
                  setIsCheckingDebounce(false)
                  setSearchCompanyDebounce(value)
                // }
              }}
              error={alreadyExist}
              helperText={alreadyExist && 'Такая компания уже зарегестрирована'}
            />
          </Box>
        }

        {activeStep === 2 &&
          <Box maxWidth='300px' margin='0 auto'>
            <Typography>Дополнительные настройки пока что недоступны</Typography>
          </Box>
        }

        {activeStep === steps.length &&
          <Box maxWidth='500px' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
            <CheckCircle style={{ color: green[500], fontSize: '50px' }}/>
            <Typography variant='h4'>Готово!</Typography>
            <Box marginTop={2} marginBottom={2}>
              <Typography>Компания зарегестрирована! Админ панель доступна по ссылке:</Typography>
            </Box>
            <TextField
              id="input-link"
              variant='outlined'
              fullWidth
              value={`https://jimba.cloud/${slug}`}
              label="Ссылка на админ панель"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={copyPath}>
                      <FileCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box marginTop={3}>
              <Button variant="contained" color="primary" onClick={goToAdmin} fullWidth>
                Перейти в админ панель
              </Button>
          </Box>
          </Box>
        }

        {activeStep !== steps.length &&
          <Box display='flex' justifyContent='space-between' marginTop={9}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Назад
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}
              disabled={
                (activeStep === 0 && !userAuth) ||
                (activeStep === 1 && (alreadyExist || !companyName || isChecking))
              }
              >
              Вперед
            </Button>
          </Box>
        }
      </Box>
    </Box>
  );
}

export default CreationPage;
