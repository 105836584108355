import { doc, setDoc, updateDoc } from '@firebase/firestore';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, Wallet } from '../../apiTypes';
import Confirmation from '../../components/Confirmation';
import { db } from '../../firebase';

interface Props {
  wallet?: Document<Wallet> | null
  onClose: () => void
}

const EditWalletModal = (props: Props) => {
  const { companySlug } = useParams<Params>();
  const [confirm, setConfirm] = useState(false)
  const [name, setName] = useState(props.wallet?.name || '')
  const [isPrivate, setPrivate] = useState(props.wallet?.private || false)

  const deleteWallet = () => {
    if (props.wallet?.id) {
      updateDoc(doc(db, "company", companySlug, "wallets", props.wallet.id), {archived: true})
    }
    props.onClose()
  }

  const editWallet = () => {
    if (props.wallet?.id) {
      updateDoc(doc(db, "company", companySlug, "wallets", props.wallet.id), {name, private: isPrivate})
    } else {
      setDoc(doc(db, "company", companySlug, "wallets", uniqid()), {
        name,
        private: isPrivate,
        balance: 0,
        archived: false
      })
    }
    props.onClose()
  }

  return (
    <Dialog open={true} maxWidth='xs' fullWidth onClose={props.onClose}>
      <DialogTitle>
        {props.wallet ? 'Редактировать счет' : 'Новый счет'}
      </DialogTitle>

      <DialogContent dividers>
        <TextField
          fullWidth
          value={name}
          onChange={e => setName(e.target.value)}
          label='Название счета'
          sx={{my: 2}}
        />


        <FormControlLabel
          control={
            <Checkbox
              checked={isPrivate}
              onChange={e => setPrivate(e.target.checked)}
              name='Приватный счет'
            />
          }
          label='Приватный счет'
        />
      </DialogContent>

      <DialogActions>
        {props.wallet &&
          <Button color='error' onClick={() => setConfirm(true)}>
            Удалить
          </Button>
        }

        <Button variant='outlined' onClick={editWallet} disabled={!name}>
          {props.wallet ? 'Сохранить изменения' : 'Создать счет'}
        </Button>
      </DialogActions>

      {confirm &&
        <Confirmation
          open={confirm}
          title='Вы уверены, что хотите безвозвратно удалить счет?'
          onSubmit={deleteWallet}
          onClose={() => setConfirm(false)}
        />
      }

    </Dialog>
  )
}

export default EditWalletModal;
