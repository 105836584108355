import { Search } from '@mui/icons-material';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { ConsumablesByCategories, useAdmin } from '../../contexts/admin.context';


function ConsumablesStock() {
  const { stores, consumablesByCategories } = useAdmin()
  const [inpunValue, setInputValue] = useState('')
  const [search, setSearch] = useState('')
  const applySearch = useMemo(() => debounce(setSearch, 500), []);

  const filteredConsumables = useMemo(() => {
    const filteredConsumables: ConsumablesByCategories = {}

    Object.entries(consumablesByCategories).forEach(([category, consumables]) => {
      const filteredCategoryItems = consumables.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()))
      if (filteredCategoryItems.length) {
        filteredConsumables[category] = filteredCategoryItems
      }
    })

    return filteredConsumables
  }, [consumablesByCategories, search])


  return (
    <Fragment>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 240px)'}}>
        <Table
          stickyHeader
          size='small'
          sx={{
            width: 'auto',
            mx: 'auto',
            '& td': {padding: '3px'},
            '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
            '& th': {padding: '4px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
            '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3},
            '& tr': {verticalAlign: 'middle'},
            'tr:hover td:first-child': {backgroundColor: t => t.palette.grey[300]},
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField
                  sx={{minWidth: '200px', mx: 1}}
                  value={inpunValue}
                  size='small'
                  label='Поиск'
                  onChange={e => {
                    setInputValue(e.target.value)
                    applySearch(e.target.value)
                  }}
                  InputProps={{
                    endAdornment: <Search/>
                  }}
                />
              </TableCell>

              {stores.map(store => {
                return (
                  <TableCell key={store.id} align='center'>
                    <Box sx={{p: '2px' }} >
                      {store.name}
                    </Box>
                  </TableCell>
                )
              })}
              <TableCell>Всего</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.entries(filteredConsumables).map(([category, consumables]) => (
              <Fragment key={category}>
                <TableRow  sx={{ textAlign: 'center', backgroundColor: t => t.palette.grey[100] }}>
                  <Typography variant='h6' sx={{m: 1}} >
                    {category}
                  </Typography>
                </TableRow>

                  {consumables.map(consumable => {
                    const {unit, packageName, qpp} = consumable

                    const total = stores.reduce((acc, s) => {
                      return acc + (s.residues[consumable.id] || 0)
                    }, 0)

                    return(
                      <TableRow key={consumable.id} sx={{ verticalAlign: 'top', ":hover": {backgroundColor: t => t.palette.primary.light + '20'}}}>
                        <TableCell align='right'>
                          <Typography variant='subtitle2' sx={{ maxWidth: 210 }} >
                            {consumable.name}
                          </Typography>
                        </TableCell>

                        {stores.map(store => {
                          const qnt = store.residues[consumable.id] || 0

                          return (
                            <TableCell key={store.id} align='center' sx={{ ':hover': { backgroundColor: t => t.palette.primary.light + '30' } }}>
                              {qnt !== 0 &&
                                <Tooltip
                                  disableInteractive
                                  title={packageName !== 'none' && qpp
                                    ? <Typography>
                                        {`${Math.floor(qnt/qpp)} ${packageName} и ${qnt%qpp} ${unit}`}
                                      </Typography>
                                    : <Typography>{`${qnt} ${unit}`}</Typography>
                                  }
                                >
                                  <Typography variant="body2">{qnt}</Typography>
                                </Tooltip>
                              }
                              {qnt === 0 && <Typography variant="body2" color='#aaa'>0</Typography>}
                            </TableCell>
                          )
                        })}
                        <TableCell>
                          {total !== 0 &&
                              <Tooltip
                                disableInteractive
                                title={packageName !== 'none' && qpp
                                  ? <Typography>
                                      {`${Math.floor(total/qpp)} ${packageName} и ${total%qpp} ${unit}`}
                                    </Typography>
                                  : <Typography>{`${total} ${unit}`}</Typography>
                                }
                              >
                                <Typography variant='subtitle2'>{total}</Typography>
                              </Tooltip>
                            }
                          <Typography variant='subtitle2'>
                              {total === 0 && 0}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </Fragment>
            ))}
          </TableBody>
        </Table>

      </TableContainer>

      {!Object.keys(filteredConsumables).length &&
        <Typography variant='h6' sx={{ m: 5, textAlign: 'center' }}>
          По этому запросу ничего не найдено
        </Typography>
      }
    </Fragment>
  );
}

export default ConsumablesStock;
