import { AllInbox, Assessment, Assignment, AttachMoney, ChevronLeft, ChevronRight, DateRange, Money, MoveToInbox, People, RecentActors, Style, SwapHoriz, TaskAlt, Today } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserCredentialKey, UserCredentials } from '../apiTypes';

interface Props {
  closeMenu: () => void
  userCredentials: UserCredentials
  collapseMenu: () => void
  collapsed: boolean
}

type MenuMap = {
  [path: string]: {
    title: string
    credentialKey: UserCredentialKey
    icon: JSX.Element
  }
}

export const menuMap: MenuMap = {
  tasks: {
    title: 'Задачи',
    credentialKey: 'tasks',
    icon: <TaskAlt />,
  },
  daily_journal: {
    title: 'Ежедневный журнал',
    credentialKey: 'daily_journal',
    icon: <Today />,
  },
  journal: {
    title: 'График смен',
    credentialKey: 'journal',
    icon: <DateRange />,
  },
  consumables_motion: {
    title: 'Движение расходников',
    credentialKey: 'consumables_motion',
    icon: <SwapHoriz />,
  },
  orders: {
    title: 'Поставки',
    credentialKey: 'consumables_orders',
    icon: <MoveToInbox />,
  },
  stores: {
    title: 'Склады',
    credentialKey: 'stores',
    icon: <AllInbox />,
  },
  consumables: {
    title: 'Виды расходников',
    credentialKey: 'consumables',
    icon: <Style />,
  },
  users: {
    title: 'Пользователи',
    credentialKey: 'users',
    icon: <People />,
  },
  workers: {
    title: 'Сотрудники',
    credentialKey: 'workers',
    icon: <RecentActors />,
  },
  tech_maps: {
    title: 'Технологические карты',
    credentialKey: 'techMaps',
    icon: <Assignment />,
  },
  wallets: {
    title: 'Движение денежных средств',
    credentialKey: 'wallets',
    icon: <AttachMoney />,
  },
  payouts: {
    title: 'Выплаты з/п',
    credentialKey: 'payouts',
    icon: <Money />,
  },
  payouts_report: {
    title: 'Отчет по ФОТ',
    credentialKey: 'payouts_report',
    icon: <Assessment />,
  },
  consumables_report: {
    title: 'Отчет по расходникам',
    credentialKey: 'consumables_report',
    icon: <Assessment />,
  },
}

function Menu(props: Props) {
  const { closeMenu, userCredentials, collapseMenu, collapsed } = props

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <List
        component="nav"
        sx={{ width: '100%', maxWidth: 300, bgcolor: 'palette.grey.100' }}
      >
        {Object.keys(menuMap).map(page => {
          const menuItem = menuMap[page]

          if (userCredentials[menuItem.credentialKey]) {
            return <MenuItem key={page} title={menuItem.title} page={page} closeMenu={closeMenu} collapsed={collapsed}/>
          }

          return null
        })}
      </List>

      <ListItem button onClick={closeMenu} sx={{display: {md: 'none'}}}>
        <Box margin='0 auto'>
          <ChevronLeft fontSize='large' />
        </Box>
      </ListItem>

      <ListItem button onClick={collapseMenu} sx={{display: {xs: 'none', md: 'flex'}}}>
        <Box margin='0 auto'>
          {collapsed
            ? <ChevronRight fontSize='large' />
            : <ChevronLeft fontSize='large' />
          }
        </Box>
      </ListItem>
    </Box>
  );
}

export default Menu

interface Params {
  companySlug: string
}

interface MenuItemProps {
  title: string
  page: string
  closeMenu: () => void
  collapsed: boolean
}

const MenuItem = (props: MenuItemProps) => {
  const history = useHistory();
  const { companySlug } = useParams<Params>();

  const goTo = () => {
    history.push(`/${companySlug}/${props.page}`)
    props.closeMenu()
  }

  return (
    <ListItemButton onClick={goTo}>
      <ListItemIcon sx={{minWidth: '43px'}}>
        {menuMap[props.page].icon}
      </ListItemIcon>
      {!props.collapsed && <ListItemText primary={props.title} />}
    </ListItemButton>
  )
}
