import { Edit, Visibility } from "@mui/icons-material";
import { Box, Card, CardHeader, IconButton } from "@mui/material";
import { useState } from "react";
import { Document } from "swr-firestore-v9";
import { TechMap, UserCredentials } from "../../apiTypes";
import { useAdmin } from "../../contexts/admin.context";
import EditTechMap from "./EditTechMap";
import ViewTechMap from "./ViewTechMap";

interface Props {
  techMap: Document<TechMap>;
  userCredentials: UserCredentials;
}

const TechMapCard = (props: Props) => {
  const { userCredentials, techMap } = props;
  const { name, object, stores, archived } = techMap;
  const { stores: allStores } = useAdmin();
  const storesString = stores
    .map((id) => allStores.find((s) => s.id === id)?.name || "Неизвестный")
    .join(", ");

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);

  return (
    <Box marginTop={2}>
      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: (t) => t.palette.grey[50], overflow: "auto" }}
          title={object + ": " + name + (archived ? " (В архиве)" : "")}
          subheader={stores.length ? `Cклад: ${storesString}` : undefined}
          action={
            <Box paddingTop={1}>
              {userCredentials.techMaps_edit && (
                <IconButton onClick={() => setEditModalIsOpen(true)}>
                  <Edit />
                </IconButton>
              )}

              {userCredentials.workers_info && (
                <IconButton onClick={() => setViewModalIsOpen(true)}>
                  <Visibility />
                </IconButton>
              )}
            </Box>
          }
        />
      </Card>

      {editModalIsOpen && (
        <EditTechMap
          techMap={techMap}
          open={editModalIsOpen}
          onClose={() => setEditModalIsOpen(false)}
          userCredentials={userCredentials}
        />
      )}

      {viewModalIsOpen && (
        <ViewTechMap
          techMap={techMap}
          open={viewModalIsOpen}
          onClose={() => setViewModalIsOpen(false)}
        />
      )}
    </Box>
  );
};

export default TechMapCard;
