import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, Store } from '../../apiTypes';
import Confirmation from '../../components/Confirmation';
import { db } from '../../firebase';

interface Props {
  open: boolean
  onClose: () => void
  store?: Document<Store>
}

const EditStore = (props: Props) => {
  const { open, onClose, store } = props
  const { companySlug } = useParams<Params>();
  const [name, setName] = useState(store?.name || '')
  const [confirmOpen, setConfirmOpen] = useState(false);

  const saveChanges = () => {
    if (store?.id) {
      updateDoc(doc(db, "company", companySlug, "stores", store.id), { name })
    } else {
      const newStore: Store = { name, residues: {} }
      setDoc(doc(db, "company", companySlug, "stores", uniqid()), newStore)
    }
    onClose()
  }

  const deleteStore = () => {
    if (store?.id) {
      deleteDoc(doc(db, "company", companySlug, "stores", store.id))
      setConfirmOpen(false)
      onClose()
    }
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={open} fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Склад
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box marginTop={2}>
            <TextField
              fullWidth
              required
              label="Название"
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          {store?.id &&
            <Button onClick={() => setConfirmOpen(true)} color='secondary'>
              Удалить
            </Button>
          }
          <Button autoFocus onClick={saveChanges} color="primary" disabled={!name}>
            {store?.id ? 'Сохранить изменения' : 'Добавить склад'}
          </Button>
        </DialogActions>
      </Dialog>

      <Confirmation
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title="Вы уверены, что хотите удалить этот склад?"
        onSubmit={deleteStore}
      />
    </Fragment>
  )
}

export default EditStore;
