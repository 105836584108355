import { doc, getDoc, increment, updateDoc } from '@firebase/firestore';
import { Close, FilterList, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import { Autocomplete, Box, Button, Collapse, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, useCollection } from 'swr-firestore-v9';
import { ArchivedStoreLogs, Consumable, Params, Store, StoreLog, StoreLogType, UserCredentials } from '../../apiTypes';
import ConsumableTitle from '../../components/ConsumableTitle';
import Loader from '../../components/Loader';
import { Map, translations } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';
import { getDateCode } from '../../utils';
import ConsumableResiduesCard from '../Stores/ConsumableResiduesCard';
import ConsumablesEventModal from './ConsumablesEventModal';

const options: StoreLogType[] = ['move', 'spend', 'taking', 'take']


interface Props {
  userCredentials: UserCredentials
}

function ConsumablesMotion(props: Props) {
  const { companySlug } = useParams<Params>();
  const [date, setDate] = useState<Date>(new Date())
  const [eventType, setEventType] = useState<StoreLogType | null>(null)
  const [store, setStore] = useState<string | null>(null)
  const [consumable, setConsumable] = useState<Document<Consumable> | null>(null)
  const [useFilter, setUseFilter] = useState(false)
  const { stores, storesMap, consumables, consumablesByCategories, isValidating } = useAdmin()
  const logs = useCollection<StoreLog>(`company/${companySlug}/storesLogs`, {
    listen: true,
    where: [
      ['date', '==', format(date, 'dd.MM.yyyy')],
    ],
  })

  const filteredLogs = useFilter
    ? (logs.data || []).filter(l => (
      (!eventType || l.type === eventType)
      && (!store || l.from === store || l.to === store))
      && (!consumable || Object.keys(l.residues).some(id => id === consumable.id))
    )
    : logs.data || []

  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <Box padding={2} maxWidth={800} margin='0 auto'>
        <Loader open={isValidating || logs.isValidating} />

        <Box sx={{mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box maxWidth={160}>
            <DatePicker
              disableMaskedInput
              label="Дата"
              value={date}
              onChange={(date) => date && setDate(date)}
              disableFuture
              renderInput={(params) => <TextField {...params} size='small' />}
              />
          </Box>

          {!useFilter &&
            <Button sx={{ml: 2}} onClick={() => setUseFilter(true)} startIcon={<FilterList/>}>
              Фильтр
            </Button>
          }

          {(props.userCredentials.consumables_move || props.userCredentials.consumables_spend) &&
            <Box marginLeft={2}>
              <Button variant='outlined' color='primary' style={{height: '100%'}}
                onClick={() => setOpen(true)}
              >
                Новое событие
              </Button>
            </Box>
          }
        </Box>

        {useFilter &&
          <Box sx={{display: 'flex', alignItems: 'center',  my: 2, p: 2, backgroundColor: 'rgb(237, 247, 237)', borderRadius: 1 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2 }}
              sx={{width: '100%'}}
            >
              <Autocomplete
                fullWidth
                options={options}
                renderInput={(params) => <TextField {...params} label="Событие" size='small' />}
                value={eventType}
                onChange={(e, v) => setEventType(v)}
                getOptionLabel={o => translations[o]}
              />

              <Autocomplete
                fullWidth
                options={stores.map(s => s.id)}
                renderInput={(params) => <TextField {...params} label="Склад" size='small' />}
                value={store}
                onChange={(e, v) => setStore(v)}
                getOptionLabel={o => storesMap[o]?.name}
              />

              <Autocomplete
                fullWidth
                size='small'
                value={consumable}
                options={consumables}
                groupBy={(option) => option.category || 'Без категории'}
                getOptionLabel={o => o.name}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <ConsumableTitle consumable={option}/>
                  </Box>
                )}
                blurOnSelect
                openOnFocus
                renderInput={(params) => <TextField {...params} label="Выберите расходник" variant="outlined" />}
                onChange={(e, value) => setConsumable(value)}
              />
            </Stack>
            <IconButton onClick={() => {
              setUseFilter(false)
              setConsumable(null)
              setStore(null)
              setEventType(null)
            }} sx={{ml: 1}}>
              <Close />
            </IconButton>
          </Box>
        }


        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Время</TableCell>
                <TableCell>Событие</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Источник</TableCell>
                <TableCell>Назначение</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs
                .sort((a,b) => b.time - a.time)
                .map(log => (
                  <Row
                    key={log.id}
                    log={log}
                    consumablesByCategories={consumablesByCategories}
                    storesMap={storesMap}
                    userCredentials={props.userCredentials}
                  />
                ))
              }

              {!filteredLogs.length &&
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box padding={3}>
                      <Typography variant='h5' color='textSecondary' align='center'>
                        В этот день
                        {store ? ` на складе "${storesMap[store]?.name}" ` : ' '}
                        не было событий
                        {eventType ? ` "${translations[eventType]}"` : ''}
                        {consumable ? ` c расходником "${consumable.name}"` : ''}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {open &&
        <ConsumablesEventModal
          open={open}
          onClose={() => {
            setOpen(false)
            setDate(new Date())
          }}
          consumablesByCategories={consumablesByCategories}
          consumables={consumables}
          userCredentials={props.userCredentials}
        />
      }
    </Fragment>
  );
}

export default ConsumablesMotion;

interface RowProps {
  log: Document<StoreLog>
  consumablesByCategories: {
    [category: string]: Document<Consumable>[]
  }
  storesMap: Map<Document<Store>>
  userCredentials: UserCredentials
}

const Row = (props: RowProps) => {
  const { log, consumablesByCategories, storesMap, userCredentials } = props
  const { companySlug } = useParams<Params>();
  const [open, setOpen] = useState(false)

  const cancelEvent = async () => {
    const fromStoreChanges: {[key: string] : any} = {}

    Object.keys(log.residues).forEach(id => {
      const residuesKey = `residues.${id}`
      fromStoreChanges[residuesKey] = increment(log.residues[id])
    })

    updateDoc(doc(db, "company", companySlug, "storesLogs", log.id), { canceled: true })
    updateDoc(doc(db, "company", companySlug, "stores", log.from), fromStoreChanges)
    if (log.type === 'spend') {
      const dcString = getDateCode(new Date(log.time)).toString()
      const docRef = doc(db, "company", companySlug, "archivedStoresLogs", dcString);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const archivedStoresLogs = docSnap.data() as ArchivedStoreLogs;
        const newLogs = archivedStoresLogs.logs.filter(l => l.id !== log.id)
        updateDoc(doc(db, "company", companySlug, "archivedStoresLogs", dcString), {
          logs: newLogs
        })
      }
    }
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'none' } }}>
        <TableCell style={{paddingRight: 0}}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {new Date(log.time).toLocaleTimeString()}
        </TableCell>
        <TableCell>
          <Box sx={{color: t => log.canceled ? t.palette.error.dark : t.palette.text.primary}}>
            {translations[log.type] + (log.canceled ? ' (отменено)' : '')}
          </Box>
        </TableCell>
        <TableCell>{log.author}</TableCell>
        <TableCell>{storesMap[log.from]?.name || log.from}</TableCell>
        <TableCell>{storesMap[log.to]?.name || log.to}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{padding: 0}}></TableCell>
        <TableCell colSpan={5} style={{padding: 0}}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginRight={2} marginLeft={2}>
              {consumablesByCategories && Object.keys(consumablesByCategories).map(category => {
                const items = consumablesByCategories[category].filter(c => log.residues[c.id] !== undefined)

                if (!items.length) {
                  return null
                }

                return (
                  <Box marginBottom={2} key={category}>
                    <Typography variant='caption' color='textSecondary'>
                      {category === 'withoutCategory' ? 'Без категории' : category}
                    </Typography>

                    {items.map(consumable => (
                      <ConsumableResiduesCard
                        key={consumable.id}
                        consumable={consumable}
                        qnt={log.residues[consumable.id] || 0}
                        prevQnt={log.prevResidues ? (log.prevResidues[consumable.id] || 0) : undefined}
                      />
                    ))}
                  </Box>
                )
              })}

              {userCredentials.consumables_cancel_spend && log.type === 'spend' && !log.canceled &&
                <Button
                  sx={{my: 2}}
                  color='error'
                  onClick={cancelEvent}
                >
                  Отменить событие
                </Button>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
