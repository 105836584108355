import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCollection } from 'swr-firestore-v9';
import { Order, Params, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { getInitMap } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import OrderEditModal from './OrderEditModal';
import OrderRow from './OrderRow';

interface Props {
  userCredentials: UserCredentials
}

function Orders(props: Props) {
  const { companySlug } = useParams<Params>();
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()]);

  const { consumables, consumablesByCategories, isValidating } = useAdmin()
  const { stores } = useAdmin()
  const orders = useCollection<Order>(`company/${companySlug}/orders`, {
    listen: true,
    where: [
      ['time', '>', dates[0]?.getTime() || 0],
      ['time', '<',  dates[1]?.getTime() || 0],
    ],
  })

  const [open, setOpen] = useState(false)

  const totalResidues = useMemo(
    () => stores.reduce((acc, s) => {
      Object.keys(s.residues).forEach(key => {
        acc[key] = acc[key] ? (acc[key] + s.residues[key]) : s.residues[key]
      })
      return acc
    }, getInitMap<number>()),
    [stores]
  )

  useEffect(() => {
    if (!open) {
      const initEndDate = new Date()
      initEndDate.setHours(23)
      initEndDate.setMinutes(59)
      const initStartDate = new Date()
      initStartDate.setHours(0)
      initStartDate.setMinutes(0)
      initStartDate.setDate(initStartDate.getDate() - 30)
      setDates([initStartDate, initEndDate])
    }
  }, [open])

  return (
    <Fragment>
      <Box padding={2} maxWidth={800} margin='0 auto'>
        <Loader open={isValidating || orders.isValidating} />

        <Box marginTop={3} marginBottom={3} display='flex' justifyContent='space-between'>
          <Box maxWidth={240}>
            <MobileDateRangePicker
              cancelText={null}
              showToolbar={false}
              startText="Период с"
              endText="Период до"
              value={dates}
              disableMaskedInput
              disableFuture
              onAccept={(newValue) => {
                setDates(newValue);
              }}
              onChange={() => {}}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                  <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
                </React.Fragment>
              )}
            />
          </Box>

          {props.userCredentials.consumables_orders_create &&
            <Box marginLeft={2}>
              <Button variant='outlined' color='primary' style={{height: '100%'}}
                onClick={() => setOpen(true)}
              >
                Создать поставку
              </Button>
            </Box>
          }
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Дата создания</TableCell>
                <TableCell>Номер</TableCell>
                <TableCell>Поставщик</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.data && orders.data
                .sort((a,b) => b.time - a.time)
                .map(order => (
                  <OrderRow
                    key={order.id}
                    order={order}
                    consumablesByCategories={consumablesByCategories}
                    userCredentials={props.userCredentials}
                    consumables={consumables}
                    totalResidues={totalResidues}
                  />
                ))
              }

              {orders.data && !orders.data.length &&
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box padding={3}>
                      <Typography variant='h5' color='textSecondary' align='center'>Нет поставок</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {open &&
        <OrderEditModal
          open={open}
          onClose={() => setOpen(false)}
          consumablesByCategories={consumablesByCategories}
          consumables={consumables}
          userCredentials={props.userCredentials}
          totalResidues={totalResidues}
        />
      }
    </Fragment>
  );
}

export default Orders;
