
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable } from '../../apiTypes';

interface Props {
  consumable: Document<Consumable>
  qnt: number
  onChange: (qnt: number) => void
  helperText?: string[]
  availableQnt?: number
  autofocus?: boolean
}

const ConsumablesInput = (props: Props) => {
  const {qnt, consumable, onChange, availableQnt, helperText = [], autofocus = false } = props
  const {unit, packageName, qpp} = consumable

  const handleChange = (newQnt: number) => {
    if (newQnt < 0) {
      onChange(0)
      return
    }
    if ((availableQnt !== undefined && availableQnt > 0 && availableQnt >= newQnt) || availableQnt === undefined) {
      onChange(newQnt)
    }
  }

  return (
    <div >
      {packageName !== 'none' && qpp
        ? (
          <Box display='flex' alignItems='center' width='100%' minWidth='250px'>
            <TextField
              fullWidth
              variant="outlined"
              type='number'
              size='small'
              value={String(Math.floor(qnt/qpp))}
              onChange={e => handleChange((Number(e.target.value) * qpp) + qnt%qpp)}
              autoFocus={autofocus}
              InputProps={{
                endAdornment: <InputAdornment position="start">{packageName}</InputAdornment>,
              }}
            />
            &nbsp;и&nbsp;
            <TextField
              fullWidth
              variant="outlined"
              type='number'
              size='small'
              value={String(qnt%qpp)}
              onChange={e => handleChange((Math.floor(qnt/qpp) * qpp) + Number(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
              }}
            />
          </Box>
        )
        : (
          <TextField
            fullWidth
            variant="outlined"
            type='number'
            size='small'
            value={String(qnt)}
            autoFocus={autofocus}
            onChange={e => handleChange(Number(e.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="start">{unit}</InputAdornment>,
              style: { maxWidth: '47.5%' }
            }}
          />
        )
      }

      {!!helperText.length &&
        <div>
          {helperText.map(text => (
            <Typography variant='caption' color='textSecondary' sx={{marginRight: 2}} key={text}>
              {text}
            </Typography>
          ))}
        </div>
      }
    </div>
  )
}

export default ConsumablesInput;
