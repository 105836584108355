import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { Document } from 'swr-firestore-v9';
import { Payout, Worker } from '../../apiTypes';
import { Map } from '../../constants';
import { Checked } from './Payouts';

interface Props {
  onClose: () => void
  workersMap: Map<Document<Worker>>
  payouts: Document<Payout>[]
  checked: Checked
}

const PayoutsPrintModal = (props: Props) => {
  const { onClose, workersMap, payouts, checked } = props
  const filteredPayouts = payouts.filter(p => checked[p.id])

  useEffect(() => {
    setTimeout(() => {
      window.print()
      onClose()
    }, 1000)
  }, [onClose])

  return (
    <Dialog onClose={onClose} open={true} maxWidth='md' fullWidth>
      <DialogContent dividers>
        <Typography variant='h6' align='center'>
          Ведомость №{filteredPayouts.map(p => p.number).join('/')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>Сумма, грн</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Подпись</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPayouts.map(payout => {
              const worker = workersMap[payout.worker]
              const workerName = worker?.lastName + ' ' + worker?.name
              return (
                <TableRow key={payout.id}>
                  <TableCell>{workerName}</TableCell>
                  <TableCell>{payout.sum}</TableCell>
                  <TableCell>{format(payout.time, 'dd.MM.yyyy')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

export default PayoutsPrintModal;
