import { Search } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { Fragment, useMemo, useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable, UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { ConsumablesByCategories, useAdmin } from '../../contexts/admin.context';
import ConsumableCard from './ConsumableCard';
import EditConsumablesType from './EditConsumablesType';

interface Props {
  userCredentials: UserCredentials
}

function ConsumablesTypes(props: Props) {
  const { consumablesByCategories, isValidating } = useAdmin()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const categoryOptions = consumablesByCategories
    ? Object.keys(consumablesByCategories).filter(c => c !== 'withoutCategory')
    : []

  const filteredConsumablesByCategories = useMemo(() => {
    const res: ConsumablesByCategories = {}
    Object.keys(consumablesByCategories).forEach(category => {
      if (selectedCategory && selectedCategory !== category) {
        return
      }
      res[category] = consumablesByCategories[category].filter(c => c.name.includes(search) || (c.ean && c.ean.includes(search)))
    })
    return res
  }, [consumablesByCategories, search, selectedCategory])

  return (
    <Box padding={2} maxWidth={500} margin='0 auto'>
      <Loader open={isValidating} />

      {props.userCredentials.consumables_edit &&
        <Box marginTop={3} textAlign='right'>
          <Button variant='outlined' color='primary' style={{height: '100%'}}
            onClick={() => setOpen(true)}
          >
            Добавить вид расходника
          </Button>
        </Box>
      }

      <Box marginTop={3} marginBottom={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" label="Поиск" size='small' fullWidth
              InputProps={{ endAdornment: <Search /> }}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={selectedCategory}
              onChange={(_, val: string | null) => setSelectedCategory(val)}
              options={categoryOptions}
              openOnFocus
              renderInput={(params) => <TextField
                {...params}
                label="Категория"
                variant="outlined"
                size="small"
              />}
            />
          </Grid>
        </Grid>
      </Box>

      {Object.keys(filteredConsumablesByCategories).map(category => (
        <Fragment key={category}>
          {!!filteredConsumablesByCategories[category].length &&
            <Fragment>
              <Box marginTop={2}>
                <Typography variant='h5' color='textSecondary'>
                  {category === 'withoutCategory' ? 'Без категории' : category}
                </Typography>
              </Box>
              {filteredConsumablesByCategories[category]?.map((consumable: Document<Consumable>) => (
                <ConsumableCard consumable={consumable} userCredentials={props.userCredentials} key={consumable.id} withEan/>
              ))}
            </Fragment>
          }
        </Fragment>
      ))}

      {props.userCredentials.consumables_edit && open &&
        <EditConsumablesType open={open} onClose={() => setOpen(false)} />
      }
    </Box>
  );
}

export default ConsumablesTypes;

export const consumablesReducer = (acc: {[category: string]: Document<Consumable>[]}, consumable: Document<Consumable>) => {
  if (consumable.category) {
    if (acc[consumable.category]) {
      acc[consumable.category].push(consumable)
    } else {
      acc[consumable.category] = [consumable]
    }
  } else {
    acc.withoutCategory.push(consumable)
  }

  return acc
}
