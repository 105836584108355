import { GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { auth } from '../firebase';

function AuthPage() {
  const login = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((result) => {}).catch((error) => {});
  }

  return (
    <Box padding={4} display='flex' flexDirection='column' alignItems='center'>
      <Box paddingBottom={4} textAlign='center'>
        <Typography variant='h5'>Пожалуйста, авторцируйтесь!</Typography>
      </Box>
      <Button onClick={login} variant='outlined' color='primary'>Войти с помощью google</Button>
    </Box>
  );
}

export default AuthPage;
