import { Close } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { Document } from "swr-firestore-v9";
import { slugify } from "transliteration";
import uniqid from "uniqid";
import {
  ExtendedActions,
  Params,
  TechMap,
  TechMapAction,
  UserCredentials,
} from "../../apiTypes";
import Confirmation from "../../components/Confirmation";
import Loader from "../../components/Loader";
import { weekDays } from "../../constants";
import { useAdmin } from "../../contexts/admin.context";
import { db } from "../../firebase";
import Actions from "./Actions";

const getNewAction = (): TechMapAction => ({
  id: uniqid(),
  place: "",
  name: "",
  instructions: "",
  duration: 30,
});

const initExtendedActions: ExtendedActions = {
  "0": [],
  "1": [],
  "2": [],
  "3": [],
  "4": [],
  "5": [],
  "6": [],
};

interface Props {
  open: boolean;
  onClose: () => void;
  techMap?: Document<TechMap>;
  userCredentials: UserCredentials;
}

const initialTouched = { name: false, lastName: false, phone: false };

const EditTechMap = (props: Props) => {
  const { open, onClose, techMap, userCredentials } = props;
  const { companySlug } = useParams<Params>();
  const { stores: allStores } = useAdmin();
  const storesOptions =
    allStores.map((s) => ({ id: s.id, name: s.name })) || [];

  const [name, setName] = useState(techMap?.name || "");
  const [object, setObject] = useState(techMap?.object || "");
  const [startTime, setStartTime] = useState(techMap?.startTime || "07:00");
  const [stores, setStores] = useState(techMap?.stores || []);
  const [writeOffZones, setWriteOffZones] = useState(
    techMap?.writeOffZones || []
  );
  const [rate, setRate] = useState(techMap?.rate || 0);
  const [traineeRate, setTraineeRate] = useState(techMap?.traineeRate || 0);
  const [actions, setActions] = useState(techMap?.actions || [getNewAction()]);
  const [extendedActions, setExtendedActions] = useState<ExtendedActions>(
    techMap?.extendedActions || initExtendedActions
  );
  const [tabIndex, setTabIndex] = useState(0);

  const [touched, setTouched] = useState(initialTouched);
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [writeOffZonesInput, setWriteOffZonesInput] = useState("");

  const handleChangeExtendedActions = (newActions: TechMapAction[]) => {
    const dayIndex = String(tabIndex - 1);
    setExtendedActions((prev) => ({ ...prev, [dayIndex]: newActions }));
  };

  const saveChanges = async () => {
    setLoading(true);
    const newTechMap = {
      name,
      object,
      startTime,
      stores,
      writeOffZones,
      rate,
      traineeRate,
      actions,
      extendedActions,
      archived: techMap?.archived || false,
    };
    if (techMap?.id) {
      await updateDoc(
        doc(db, "company", companySlug, "techMaps", techMap.id),
        newTechMap
      );
    } else {
      await setDoc(
        doc(
          db,
          "company",
          companySlug,
          "techMaps",
          slugify(`${name}_${object}`)
        ),
        newTechMap
      );
    }
    setLoading(false);
    onClose();
  };

  const deleteTechMap = async () => {
    if (techMap?.id) {
      setLoading(true);
      await deleteDoc(doc(db, "company", companySlug, "techMaps", techMap.id));
      setLoading(false);
      setConfirmOpen(false);
      onClose();
    }
  };

  const toArchive = async () => {
    if (techMap?.id) {
      setLoading(true);
      await updateDoc(doc(db, "company", companySlug, "techMaps", techMap.id), {
        archived: true,
      });
      setLoading(false);
      onClose();
    }
  };

  const fromArchive = async () => {
    if (techMap?.id) {
      setLoading(true);
      await updateDoc(doc(db, "company", companySlug, "techMaps", techMap.id), {
        archived: false,
      });
      setLoading(false);
      onClose();
    }
  };

  const handleChange = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const dublicate = () => {
    const dayIndex = String(tabIndex - 1);
    setExtendedActions((prev) => ({ ...prev, [dayIndex]: actions }));
  };

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        fullScreen={document.documentElement.clientWidth < 860}
        fullWidth
        maxWidth="lg"
      >
        <Loader open={loading} />

        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {techMap?.id
              ? "Технологическая карта"
              : "Новая технологическая карта"}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box maxWidth={600} margin="16px auto">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Название"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={touched.name && !name}
                  helperText={touched.name && !name && "Обязательное поле"}
                  onBlur={() => setTouched({ ...touched, name: true })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="object"
                  label="Объект"
                  size="small"
                  value={object}
                  onChange={(e) => setObject(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  id="time"
                  label="Время начала"
                  type="time"
                  defaultValue="07:00"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="stores"
                  limitTags={1}
                  size="small"
                  options={storesOptions.map((s) => s.id)}
                  getOptionLabel={(o) =>
                    storesOptions.find((s) => s.id === o)?.name || "Неизвестный"
                  }
                  value={stores}
                  ChipProps={{ variant: "outlined" }}
                  onChange={(e, v) => setStores(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Доступные склады"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="write-off-zones"
                  limitTags={1}
                  size="small"
                  fullWidth
                  options={[writeOffZonesInput, ...writeOffZones]}
                  filterOptions={(o) => (writeOffZonesInput ? o : [])}
                  getOptionLabel={(o) =>
                    o && o === writeOffZonesInput ? `Добавить: ${o}` : o
                  }
                  value={writeOffZones}
                  inputValue={writeOffZonesInput}
                  noOptionsText="Введите название зоны"
                  blurOnSelect={false}
                  ChipProps={{ variant: "outlined" }}
                  onInputChange={(e, v) => setWriteOffZonesInput(v)}
                  onBlur={() =>
                    writeOffZonesInput &&
                    setWriteOffZones([...writeOffZones, writeOffZonesInput])
                  }
                  onChange={(e, v) => setWriteOffZones(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Зоны списания расходников"
                      size="small"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="rate"
                  label="Ставка (грн)"
                  type="number"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled={!userCredentials.techMaps_edit_rate}
                  value={String(rate)}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0) {
                      setRate(Number(e.target.value));
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="traineeRate"
                  label="Ставка при стажировке (грн)"
                  type="number"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled={!userCredentials.techMaps_edit_rate}
                  value={String(traineeRate)}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0) {
                      setTraineeRate(Number(e.target.value));
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box marginTop={2} textAlign="center">
            <Typography variant="h6">Инструкции</Typography>

            <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth">
              <Tab label="Базовие" />
              {weekDays.map((day, dayIndex) => (
                <Tab key={day} label={day} />
              ))}
            </Tabs>

            {tabIndex === 0 && (
              <Actions
                startTime={startTime}
                actions={actions}
                setActions={setActions}
              />
            )}

            {tabIndex > 0 && (
              <Actions
                startTime={startTime}
                actions={extendedActions[tabIndex - 1]}
                setActions={handleChangeExtendedActions}
                dublicate={dublicate}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          {techMap?.id && techMap.archived && (
            <Button onClick={fromArchive} color="secondary">
              Восстановить
            </Button>
          )}
          {techMap?.id && !techMap.archived && (
            <Button onClick={toArchive} color="secondary">
              В архив
            </Button>
          )}
          {techMap?.id && (
            <Button onClick={() => setConfirmOpen(true)} color="error">
              Удалить
            </Button>
          )}
          <Button
            autoFocus
            onClick={saveChanges}
            color="primary"
            disabled={!name}
          >
            {techMap?.id ? "Сохранить изменения" : "Добавить тех карту"}
          </Button>
        </DialogActions>
      </Dialog>

      <Confirmation
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title="Вы уверены, что хотите безвозвратно удалить технологическую карту?"
        onSubmit={deleteTechMap}
      />
    </Fragment>
  );
};

export default EditTechMap;
