import {
  createContext, useContext, useMemo
} from 'react';
import { useAuthState } from 'react-firehooks';
import { useParams } from 'react-router';
import { Document, useCollection } from 'swr-firestore-v9';
import { Author, Consumable, Params, Store, TechMap, User, Wallet, Worker } from '../apiTypes';
import { getInitMap, Map } from '../constants';
import { auth } from '../firebase';

export type ConsumablesByCategories = { [category: string]: Document<Consumable>[] }
interface AdminContextValue {
  companySlug: string
  isValidating: boolean
  workers: Document<Worker>[]
  workersMap: Map<Document<Worker>>
  authorsMap: Map<Author>
  techMaps: Document<TechMap>[]
  archivedTechMaps: Document<TechMap>[]
  techMapsMap: Map<Document<TechMap>>
  techMapsByObject: { [object: string]: Document<TechMap>[] }
  allTechMapsByObject: { [object: string]: Document<TechMap>[] }
  writeOffZonesMap: Map<string>
  stores: Document<Store>[]
  storesMap: Map<Document<Store>>
  consumables: Document<Consumable>[]
  consumableMap: Map<Document<Consumable>>
  consumablesByCategories: ConsumablesByCategories
  wallets: Document<Wallet>[]
  walletsMap: Map<Document<Wallet>>
  users: Document<User>[]
  user?: Document<User>
  usersMap: Map<Document<User>>
}

interface AdminProviderProps {
  children: JSX.Element
}

const AdminContext = createContext<AdminContextValue | null>(null);

export const useAdmin = (): AdminContextValue => {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error('useAdmin must be used within a AdminProvider');
  }

  return context;
};

export const AdminProvider = (props: AdminProviderProps) => {
  const { companySlug } = useParams<Params>();
  const [userAuth] = useAuthState(auth)
  const users = useCollection<User>(`company/${companySlug}/users`, { listen: true })
  const workers = useCollection<Worker>(`company/${companySlug}/workers`, { listen: true })
  const allTechMaps = useCollection<TechMap>(`company/${companySlug}/techMaps`, { listen: true })
  const stores = useCollection<Store>(`company/${companySlug}/stores`, {listen: true})
  const consumables = useCollection<Consumable>(`company/${companySlug}/consumables`, {listen: true})
  const wallets = useCollection<Wallet>(`company/${companySlug}/wallets`, {listen: true})
  const user = users.data?.find(u => u.email === userAuth?.email)

  const workersMap = useMemo(
    () => (workers.data || []).reduce((acc, w) => {
      acc[w.id] = w
      return acc
    }, getInitMap<Document<Worker>>()),
    [workers.data]
  )

  const authorsMap: Map<Author> = useMemo(() => {
    const authorsMap: Map<Author> = {}
    if (users.data && workers.data) {
      users.data.forEach(user => {
        authorsMap[user.id] = { name: user.displayName, photoURL: user.photoURL }
      })
      workers.data.forEach(worker => {
        authorsMap[worker.id] = { name: worker.lastName + ' ' + worker.name, photoURL: worker.photoURL }
      })
    }
    return authorsMap
  }, [users.data, workers.data])

  const archivedTechMaps = useMemo(
    () => (allTechMaps.data || []).filter(tm => tm.archived),
    [allTechMaps.data]
  )

  const techMaps = useMemo(
    () => (allTechMaps.data || []).filter(tm => !tm.archived),
    [allTechMaps.data]
  )

  const techMapsMap = useMemo(
    () => (allTechMaps.data || []).reduce((acc, tm) => {
      acc[tm.id] = tm
      return acc
    }, getInitMap<Document<TechMap>>()),
    [allTechMaps.data]
  )

  const techMapsByObject = useMemo(
    () => techMaps.reduce(techMapsReducer, {withoutObject: []}),
    [techMaps]
  )

  const allTechMapsByObject = useMemo(
    () => [...techMaps, ...archivedTechMaps].reduce(techMapsReducer, {withoutObject: []}),
    [techMaps, archivedTechMaps]
  )

  const writeOffZonesMap = useMemo(
    () => (techMaps || []).reduce((acc, tm) => {
      (tm.writeOffZones || []).forEach(woz => acc[woz] = woz)
      return acc
    }, getInitMap<string>()),
    [techMaps]
  )

  const storesMap = useMemo(
    () => (stores.data || []).reduce((acc, s) => {
      acc[s.id] = s
      return acc
    }, getInitMap<Document<Store>>()),
    [stores.data]
  )

  const consumableMap = useMemo(
    () => (consumables.data || []).reduce((acc, c) => {
      acc[c.id] = c
      return acc
    }, getInitMap<Document<Consumable>>()),
    [consumables.data]
  )

  const consumablesByCategories = useMemo(
    () => (consumables.data || []).reduce(consumablesReducer, {withoutCategory: []}),
    [consumables.data]
  )

  const walletsMap = useMemo(
    () => (wallets.data || []).reduce((acc, w) => {
      acc[w.id] = w
      return acc
    }, getInitMap<Document<Wallet>>()),
    [wallets.data]
  )

  const usersMap = useMemo(
    () => (users.data || []).reduce((acc, u) => {
      acc[u.id] = u
      return acc
    }, getInitMap<Document<User>>()),
    [users.data]
  )

  return (
    <AdminContext.Provider value={{
      companySlug,
      isValidating: workers.isValidating || allTechMaps.isValidating || users.isValidating || stores.isValidating || consumables.isValidating || wallets.isValidating,
      workers: workers.data || [],
      workersMap,
      authorsMap,
      techMaps,
      archivedTechMaps,
      techMapsMap,
      techMapsByObject,
      allTechMapsByObject,
      writeOffZonesMap,
      stores: stores.data || [],
      storesMap,
      consumables: consumables.data || [],
      consumableMap,
      consumablesByCategories,
      wallets: wallets.data || [],
      walletsMap,
      users: users.data || [],
      user,
      usersMap,
    }} >
      {props.children}
    </AdminContext.Provider>
  );
};


const techMapsReducer = (acc: {[object: string]: Document<TechMap>[]}, techMap: Document<TechMap>) => {
  if (techMap.object) {
    if (acc[techMap.object]) {
      acc[techMap.object].push(techMap)
    } else {
      acc[techMap.object] = [techMap]
    }
  } else {
    acc.withoutObject.push(techMap)
  }

  return acc
}

export const consumablesReducer = (acc: {[category: string]: Document<Consumable>[]}, consumable: Document<Consumable>) => {
  if (consumable.category) {
    if (acc[consumable.category]) {
      acc[consumable.category].push(consumable)
    } else {
      acc[consumable.category] = [consumable]
    }
  } else {
    acc.withoutCategory.push(consumable)
  }

  return acc
}
