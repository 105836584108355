import { doc, increment, updateDoc, writeBatch } from '@firebase/firestore';
import { Add, Remove } from '@mui/icons-material';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useAuthState } from 'react-firehooks/auth';
import { useParams } from 'react-router';
import { Document, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Params, User, UserCredentials, Wallet, WalletActionLog, WalletLog } from '../../apiTypes';
import Confirmation from '../../components/Confirmation';
import { auth, db } from '../../firebase';

const translations = {
  income: 'доход',
  spending: 'расход',
  transfer: 'перевод',
  cancel: '',
}

const icons = {
  income: <Add/>,
  spending: <Remove/>,
  transfer: '',
  cancel: '',
}

interface Props {
  wallet: Document<Wallet>
  wallets: Document<Wallet>[]
  onClose: () => void
  action?: Document<WalletLog>
  type: WalletActionLog
  userCredentials: UserCredentials
}

const EventModal = (props: Props) => {
  const { type, action, wallet, wallets, onClose } = props
  const { companySlug } = useParams<Params>()
  const [userAuth] = useAuthState(auth)
  const user = useDocument<User>(userAuth ? `company/${companySlug}/users/${userAuth.email}` : null)
  const author = user.data?.id || '';
  const [confirm, setConfirm] = useState(false)
  const [target, setTarget] = useState<Document<Wallet> | null>(null)
  const [comment, setComment] = useState(action?.comment || '')
  const [sum, setSum] = useState(action?.sum || 0)

  const saveAction = () => {
    if (action?.id) {
      updateDoc(doc(db, "company", companySlug, "wallets", wallet.id, "logs", action.id), {comment})
    } else if (type === 'transfer' && target) {
      const log: WalletLog = {
        time: new Date().getTime(),
        sum, author,
        wallet: wallet.id,
        total: wallet.balance - sum,
        comment: `Перевод на счет "${target.name}"`,
        type: 'spending'
      }
      const targetLog: WalletLog = {
        time: new Date().getTime(),
        sum, author,
        wallet: target.id,
        total: target.balance + sum,
        comment: `Перевод co счетa "${wallet?.name}"`,
        type: 'income'
      }
      const batch = writeBatch(db);
      batch.update(doc(db, "company", companySlug, "wallets", wallet.id), {
        balance: increment(-sum)
      })
      batch.set(doc(db, "company", companySlug, "wallets",  wallet.id, "logs", uniqid()), log)
      batch.update(doc(db, "company", companySlug, "wallets", target.id), {
        balance: increment(sum)
      })
      batch.set(doc(db, "company", companySlug, "wallets",  target.id, "logs", uniqid()), targetLog)
      batch.commit()
    } else {
      const batch = writeBatch(db);
      const log: WalletLog = {
        time: new Date().getTime(),
        sum, author,
        wallet: wallet.id,
        total: type === 'income' ? wallet.balance + sum : wallet.balance - sum,
        comment,
        type
      }
      batch.update(doc(db, "company", companySlug, "wallets", wallet.id), {
        balance: increment(type === 'income' ? sum : -sum)
      })
      batch.set(doc(db, "company", companySlug, "wallets",  wallet.id, "logs", uniqid()), log)
      batch.commit()
    }
    onClose()
  }

  const cancelAction = () => {
    if (action) {
      const log: WalletLog = {
        time: new Date().getTime(),
        sum, author,
        wallet: wallet.id,
        total: type === 'income' ? wallet.balance - sum : wallet.balance + sum,
        comment: `Отмена события "${action?.comment}" от ${format(new Date(action?.time), 'dd.MM.yyyy HH:mm')}`,
        type: 'cancel'
      }
      const batch = writeBatch(db);
      batch.update(doc(db, "company", companySlug, "wallets", wallet.id), {
        balance: increment(type === 'income' ? -sum : sum)
      })
      batch.update(doc(db, "company", companySlug, "wallets", wallet.id, "logs", action.id), {
        canceled: true
      })
      batch.set(doc(db, "company", companySlug, "wallets",  wallet.id, "logs", uniqid()), log)
      batch.commit()
      onClose()
    }
  }

  return (
    <Dialog open={true} maxWidth='xs' fullWidth onClose={onClose}>
      <DialogTitle>
        {action ? 'Редактировать ' : 'Создать '}{translations[type]}
      </DialogTitle>

      <DialogContent dividers>
        {type === 'transfer' &&
          <Autocomplete
            value={target}
            options={wallets.filter(w => w.id !== wallet.id)}
            getOptionLabel={o => o.name}
            renderInput={(params) => <TextField {...params} label="На счет" required />}
            onChange={(_, o) => setTarget(o)}
          />
        }

        <TextField
          fullWidth
          type='number'
          value={String(sum)}
          onChange={e => setSum(Number(e.target.value))}
          label='Сумма'
          sx={{my: 2}}
          required
          disabled={!!action}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {icons[type]}
              </InputAdornment>
            ),
          }}
          helperText={type === 'spending' || type === 'transfer' ? `Доступно: ${wallet.balance}` : undefined}
        />

        {type !== 'transfer' &&
          <TextField
            fullWidth
            value={comment}
            onChange={e => setComment(e.target.value)}
            required
            label='Комментарий'
            sx={{my: 2}}
          />
        }


      </DialogContent>

      <DialogActions>
        {action && type !== 'transfer' &&
          <Button color='error' onClick={() => setConfirm(true)}>
            Отменить
          </Button>
        }

        <Button
          variant='outlined'
          onClick={saveAction}
          disabled={!sum || (type === 'transfer' && !target) || (type !== 'transfer' && !comment)}
        >
          {action ? 'Сохранить изменения' : `Создать ${translations[type]}`}
        </Button>
      </DialogActions>

      {confirm &&
        <Confirmation
          open={confirm}
          title='Вы уверены, что хотите отменить событие?'
          onSubmit={cancelAction}
          onClose={() => setConfirm(false)}
        />
      }

    </Dialog>
  )
}

export default EventModal;
