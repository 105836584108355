import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { UserCredentials } from '../../apiTypes';
import Loader from '../../components/Loader';
import { useAdmin } from '../../contexts/admin.context';
import CreateUserModal from './CreateUserModal';
import UserCard from './UserCard';

interface Props {
  userCredentials: UserCredentials
}

function Users(props: Props) {
  const { users, isValidating } = useAdmin()
  const [open, setOpen] = useState(false)

  return (
    <Box padding={2} maxWidth={500} margin='0 auto'>
      <Loader open={isValidating} />
      {users.length &&
        users.map(user => (
          <Box key={user.email} marginBottom={4}>
            <UserCard user={user} userCredentials={props.userCredentials}/>
          </Box>
        ))
      }

      {props.userCredentials.users_edit_credentials &&
        <Box marginBottom={4}>
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            onClick={() => setOpen(true)}
          >
            Добавить пользователя
          </Button>
        </Box>
      }

      {props.userCredentials.users_edit_credentials &&
        <CreateUserModal open={open} onClose={() => setOpen(false)}/>
      }
    </Box>
  );
}

export default Users;
