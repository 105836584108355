
import { Button, Container, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { slugify } from 'transliteration';

function SelectCompany() {
  const history = useHistory();
  const [name, setName] = useState('')

  const goToAdmin = () => {
    history.push(`/${slugify(name)}/tasks`)
    localStorage.setItem('company', slugify(name))
    localStorage.setItem('platform', 'admin')
  }

  const goToTerminal = () => {
    history.push(`/${slugify(name)}/terminal`)
    localStorage.setItem('company', slugify(name))
    localStorage.setItem('platform', 'terminal')
  }

  useEffect(() => {
    const company = localStorage.getItem('company')
    const platform = localStorage.getItem('platform')

    if (company) {
      if (platform === 'admin') {
        history.push(`/${company}/tasks`)
      }
      if (platform === 'terminal') {
        history.push(`/${company}/terminal`)
      }
    }
  }, [])

  return (
    <Container maxWidth='xs' sx={{ py: 5 }}>
      <Typography variant='h5' align='center' sx={{py: 2}}>
        Введите название компании
      </Typography>

      <TextField
        fullWidth
        value={name}
        onChange={e => setName(e.target.value)}
        label="Компания"
      />

      <Button
        sx={{my: 2}}
        onClick={goToAdmin}
        fullWidth
        size='large'
        variant='contained'
        disabled={!name}
      >
        Перейти в админ панель
      </Button>

      <Button
        sx={{my: 2}}
        onClick={goToTerminal}
        fullWidth
        size='large'
        variant='contained'
        disabled={!name}
      >
        Перейти в терминал
      </Button>
    </Container>
  );
}

export default SelectCompany;
