import { Image } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable } from '../apiTypes';

interface Props {
  consumable: Document<Consumable>
  withEan?: boolean
}

const ConsumableTitle = (props: Props) => {
  const {imageUrl, name, unit, packageName, qpp, ean} = props.consumable

  return (
    <Box display='flex' alignItems='center' marginLeft={1}>
      {imageUrl
        ? <Avatar alt={name} src={imageUrl || ''} />
        : <Avatar><Image /></Avatar>
      }
      <Box marginLeft={1}>
        <Typography>
          {name}
          {props.withEan && ean &&
            <Typography variant='caption' sx={{color: t => t.palette.grey[700], ml: 1}}>{ean}</Typography>
          }
        </Typography>
        <Typography variant='caption' color='textSecondary'>
          {packageName === 'none' ? `${unit}.` : `${qpp} ${unit}. в ${packageName}.`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ConsumableTitle;
