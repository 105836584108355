import { IconButton, PropTypes } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

interface Props {
  icon: JSX.Element
  title: string
  onClick: () => void
  color?: PropTypes.Color
}

function IconTitleButton(props: Props) {

  return (
    <Box width={86} display='flex' flexDirection='column' alignItems='center' margin='0 8px' >
      <IconButton
        color={props.color}
        sx={{
          '& span': {
            '& svg': {
              fontSize: '30px'
            }
          }
        }}
        onClick={props.onClick}
      >
        {props.icon}
      </IconButton>
      <Box sx={{
        fontSize: '12px',
        textAlign: 'center',
        color: 'theme.palette.grey[600]',
      }}>
        {props.title}
      </Box>
    </Box>
  );
}

export default IconTitleButton;
