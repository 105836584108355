import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import format from 'date-fns/format';
import ru from "date-fns/locale/ru";
import { Fragment } from 'react';
import { Document } from 'swr-firestore-v9';
import { TechMap, Worker, WorkShift } from '../../apiTypes';
import { Map } from '../../constants';
import { getDateFromDateCode } from '../../utils';


interface Props {
  dateCodes: number[]
  techMapsByObject: {
    [object: string]: Document<TechMap>[]
  }
  workShifts: Document<WorkShift>[]
  techMapsMap: Map<Document<TechMap>>
  workersMap: Map<Document<Worker>>
}

function PayoutsByTechMapTable(props: Props) {
  const { dateCodes, techMapsByObject, workShifts, workersMap } = props

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 240px)'}}>
      <Table
        stickyHeader
        size='small'
        sx={{
          width: 'auto',
          mx: 'auto',
          '& td': {padding: '6px'},
          '& td:first-child': {position: 'sticky', left: '0px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
          '& th': {padding: '4px', zIndex: 2, backgroundColor: t => t.palette.grey[100]},
          '& th:first-child': {position: 'sticky', left: '0px', zIndex: 3},
          '& tr': {verticalAlign: 'middle'},
          'tr:hover td:first-child': {backgroundColor: t => t.palette.grey[300]},
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Тех. карта</TableCell>
            {dateCodes.map(dc => {
              const date = getDateFromDateCode(dc)
              const day = format(date, 'EEEEEE', {locale: ru})
              return (
                <TableCell key={dc} align='center'>
                  <Box sx={{p: '2px' }} >
                    {format(date, 'dd.MM')}
                  </Box>

                  <Typography color={(day === 'сб' || day === 'вс') ? 'orange' : 'default'}>
                    {day}
                  </Typography>
                </TableCell>
              )
            })}
            <TableCell>Всего</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {techMapsByObject && Object.keys(techMapsByObject).map(object => {
            const techMaps = techMapsByObject[object]
            if (!techMaps.length) {
              return null
            }

            return(
              <Fragment key={object}>
                {techMaps.map(techMap => {
                  const total = dateCodes.reduce((acc, dc) => {
                    const filteredWorkShifts = workShifts.filter(ws => ws.dateCode === dc && ws.techMap === techMap.id)
                    let dcTotal = 0
                    filteredWorkShifts.forEach(ws => dcTotal = dcTotal + (ws.rate || techMap.rate))
                    return acc + dcTotal
                  }, 0)

                  return (
                    <TableRow key={techMap.id} sx={{ verticalAlign: 'top', ":hover": {backgroundColor: t => t.palette.primary.light + '20'}}}>
                      <TableCell align='right'>
                        <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap' }} >
                          {object + ': ' + techMap.name}
                        </Typography>
                      </TableCell>

                      {dateCodes.map(dc => {
                        const filteredWorkShifts = workShifts.filter(ws => ws.dateCode === dc && ws.techMap === techMap.id)
                        let hasChanges = false
                        const rates = filteredWorkShifts.reduce((acc, ws) => {
                          if (ws.rate && ws.plannedRate && ws.rate !== ws.plannedRate) {
                            hasChanges = true
                          }
                          return acc + (ws.rate || techMap.rate)
                        }, 0)
                        return (
                          <TableCell key={dc} align='center' sx={{ ':hover': { backgroundColor: t => t.palette.primary.light + '30' } }}>
                            <Tooltip
                              arrow
                              disableInteractive
                              title={
                                <Fragment>
                                  {filteredWorkShifts.map((ws, i) => (
                                    <Box key={ws.id}>
                                      <p>{techMap.object + ' ' + techMap.name}</p>
                                      <p>{workersMap[ws.worker]?.lastName + ' ' + workersMap[ws.worker]?.name}</p>
                                      <p>Плановая ставка: {ws.plannedRate || techMap.rate}</p>
                                      {ws.closed && <p>Начисленная ставка: {ws.rate}</p>}
                                      {ws.comment && <p>Комментарий: {ws.comment}</p>}
                                      {i !== filteredWorkShifts.length - 1 && <Box sx={{my: 1}}><Divider /></Box>}
                                    </Box>
                                  ))}
                                </Fragment>
                              }
                            >
                              <Box sx={{color: t => hasChanges ? t.palette.error.dark : 'inherit', cursor: 'default'}}>
                                {rates || null}
                              </Box>
                            </Tooltip>
                          </TableCell>
                        )
                      })}
                      <TableCell>
                        <Typography variant='subtitle2'>
                          {total}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Fragment>
            )
          })}

          <TableRow sx={{ verticalAlign: 'top' }}>
            <TableCell>
              <Box sx={{ padding: '4px' }} >Итого</Box>
            </TableCell>

            {dateCodes.map(dc => {
              const filteredWorkShifts = workShifts.filter(ws => ws.dateCode === dc)
              const total = filteredWorkShifts.reduce((acc, ws) => {
                return acc + (ws.rate || 0)
              }, 0)
              return (
                <TableCell key={dc} align='center'>
                  <Typography variant='subtitle2'>
                    {total}
                  </Typography>
                </TableCell>
              )
            })}
            <TableCell>
              <Typography variant='subtitle2'>
                {workShifts.reduce((acc, ws) => {
                  return acc + (ws.rate || 0)
                }, 0)}
              </Typography>
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PayoutsByTechMapTable;
