import { deleteDoc, doc, setDoc, updateDoc } from '@firebase/firestore';
import { Close } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, useCollection, useDocument } from 'swr-firestore-v9';
import uniqid from 'uniqid';
import { Company, Params, Payout, UserCredentials, Wallet, Worker, WorkShift } from '../../apiTypes';
import SmartAvatar from '../../components/SmartAvatar';
import { Map } from '../../constants';
import { useAdmin } from '../../contexts/admin.context';
import { db } from '../../firebase';



interface Props {
  onClose: () => void
  workers: Document<Worker>[]
  workersMap: Map<Document<Worker>>
  walletsMap: Map<Document<Wallet>>
  payout?: Document<Payout>
  userCredentials: UserCredentials
  workShiftsFromThisMonth: Document<WorkShift>[]
}

const PayoutEditModal = (props: Props) => {
  const { onClose, workersMap, payout, workers, userCredentials, walletsMap, workShiftsFromThisMonth } = props
  const { companySlug } = useParams<Params>();
  const [workerId, setWorkerId] = useState<string | null>(payout ? payout.worker : null)
  const [sum, setSum] = useState<number>(payout ? payout.sum : 0)
  const [checked, setChecked] = useState(payout && !payout.fromWallet ? false : true)
  const [walletId, setWalletId] = useState<string | null>(payout ? payout.fromWallet : null)
  const worker = workerId ? workersMap[workerId] : null
  const company = useDocument<Company>(`company/${companySlug}`)
  const { wallets } = useAdmin()

  const workShifts = useMemo(() => {
    return workShiftsFromThisMonth.filter(ws => ws.closed && ws.worker === workerId)
  }, [workShiftsFromThisMonth, workerId])

  const openedPayouts = useCollection<Payout>(workerId ? `company/${companySlug}/payouts` : null, {
    listen: true,
    where: [
      ['closed', '==', false],
      ['worker', '==', workerId],
    ],
  })

  const unpaidSumFromThisMonth = useMemo(
    () => workShifts.reduce((acc, ws) => acc + (ws.rate || 0), 0),
    [workShifts]
  )

  const openedPayoutsSum = useMemo(
    () => (openedPayouts.data || []).reduce((acc, p) => p.id !== payout?.id ? acc + (p.sum || 0) : acc, 0),
    [openedPayouts.data, payout?.id]
  )

  const availableWallets = userCredentials.wallets_private
    ? wallets.filter(w => !w.archived)
    : wallets.filter(w => !w.private && !w.archived)

  const saveChanges = () => {
    if (!payout && userCredentials.payouts_create) {
      const payoutNumber = (company.data?.payoutNumber || 0) + 1
      const payout: Payout = {
        time: new Date().getTime(),
        number: payoutNumber,
        sum,
        worker: workerId || '',
        closed: false,
        fromWallet: checked ? walletId : null,
        walletLogId: null,
      }
      setDoc(doc(db, "company", companySlug, "payouts", uniqid()), payout)
      updateDoc(doc(db, "company", companySlug), { payoutNumber })
    } else if (payout && userCredentials.payouts_edit) {
      updateDoc(doc(db, "company", companySlug, "payouts", payout.id), {
        sum,
        worker: workerId || '',
        closed: false,
        fromWallet: checked ? walletId : null,
      })
    }
    onClose()
  }

  const deletePayout = () => {
    if (payout && !payout.closed) {
      deleteDoc(doc(db, "company", companySlug, "payouts", payout.id))
    }
    onClose()
  }

  const changeSum = (sum: number) => {
    if (worker?.balance) {
      setSum(sum <= (worker.balance - openedPayoutsSum) ? sum : worker.balance - openedPayoutsSum)
    }
  }

  return (
    <Fragment>
      <Dialog onClose={onClose} open={true} maxWidth='xs' fullWidth>

        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            Ведомость №{company.data ? company.data.payoutNumber + 1 : ''}
            <IconButton aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Autocomplete
            value={workerId}
            options={workers.filter(w => !w.archived).map(w => w.id)}
            getOptionLabel={o => {
              const worker = workersMap[o] || {}
              return worker.lastName + ' ' + worker.name
            }}
            renderOption={(props, o) => {
              const worker = workersMap[o] || {}
              const name = worker.lastName + ' ' + worker.name
              return (
                <Box component="li" {...props} sx={{display: 'flex'}}>
                  <SmartAvatar name={name} photoURL={worker.photoURL} />
                  <Box marginLeft={1}>{name}</Box>
                </Box>
              )
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label='Получатель' required sx={{my: 2}}/>}
            onChange={(e, value) => {
              setSum(0)
              setWorkerId(value)
            }}
          />

          <TextField
            sx={{my: 2}}
            fullWidth
            label={'Сумма, грн'}
            type='number'
            value={String(sum)}
            required
            onChange={e => changeSum(Number(e.target.value))}
          />

          {worker &&
            <Box sx={{ml: 2, mb: 2}}>
              {!!openedPayoutsSum &&
                <Typography fontSize='12px' color='text.secondary' sx={{my: '3px'}}>
                  {`Непроведенные ведемости на сумму: ${openedPayoutsSum} грн`}
                </Typography>
              }
              <Typography fontSize='12px' color='text.secondary'>
                Не выплачено всего:
                <Button size='small' sx={{py: 0}} onClick={() => changeSum(worker.balance)}>
                  {`${worker.balance} грн`}
                </Button>
              </Typography>
              <Typography fontSize='12px' color='text.secondary'>
                Не выплачено за прошлый месяц:
                <Button size='small' sx={{py: 0}} onClick={() => changeSum(worker.balance - unpaidSumFromThisMonth)}>
                  {`${worker.balance - unpaidSumFromThisMonth} грн`}
                </Button>
              </Typography>
            </Box>
          }

          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={e => setChecked(e.target.checked)}
                name='Списать со счета'
              />
            }
            label='Списать со счета'
          />

          <Autocomplete
            value={walletId}
            options={availableWallets.map(w => w.id)}
            getOptionLabel={o => walletsMap[o]?.name || ''}
            fullWidth
            renderInput={(params) => <TextField
              {...params}
              label='Списать со счета'
              required={checked}
              sx={{mb: 2}}
            />}
            onChange={(e, value) => setWalletId(value)}
            disabled={!checked}
          />
        </DialogContent>

        <DialogActions>
          {payout &&
            <Button
              onClick={deletePayout}
              color="error"
            >
              Удалить ведомость
            </Button>
          }
          <Button
            autoFocus
            onClick={saveChanges}
            color="primary"
            disabled={!workerId || !sum || sum <= 0 || (checked && !walletId)}
          >
            {!!payout ? 'Сохранить изменения' : 'Создать ведомость'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default PayoutEditModal;
