import { Edit, Image } from '@mui/icons-material';
import { Avatar, Box, Card, CardHeader, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Document } from 'swr-firestore-v9';
import { Consumable, UserCredentials } from '../../apiTypes';
import EditConsumablesType from './EditConsumablesType';

interface ConsumableCardProps {
  consumable: Document<Consumable>
  userCredentials: UserCredentials
  withEan?: boolean
}

const ConsumableCard = (props: ConsumableCardProps) => {
  const {userCredentials, consumable, withEan = false } = props
  const {
    name,
    unit,
    imageUrl,
    packageName,
    qpp,
    ean
  } = consumable

  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  return (
    <Box marginTop={1}>
      <Card variant="outlined">
        <CardHeader
          sx={{ backgroundColor: t => t.palette.grey[50], overflow: 'auto' }}
          avatar={imageUrl
            ? <Avatar alt={name} src={imageUrl || ''} />
            : <Avatar><Image /></Avatar>
          }
          title={withEan && ean ? `${name} [${ean}]` : name}
          subheader={`${unit}.${packageName === 'none' ? '' : ` (${qpp} ${unit}. в ${packageName}.)`}`}
          action={userCredentials.consumables_edit &&
            <IconButton
              onClick={() => setEditModalIsOpen(true)}
            >
              <Edit />
            </IconButton>
          }
        />
      </Card>

      {editModalIsOpen &&
        <EditConsumablesType consumable={consumable} open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)} />
      }
    </Box>
  )
}

export default ConsumableCard;

