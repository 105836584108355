import { Add, AddBox, ArrowRightAltTwoTone, Edit, Remove, WarningAmberTwoTone } from '@mui/icons-material';
import { MobileDateRangePicker } from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Autocomplete, Box, Button, Container, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, useCollection } from 'swr-firestore-v9';
import { Params, UserCredentials, WalletActionLog, WalletLog } from '../../apiTypes';
import Loader from '../../components/Loader';
import SmartAvatar from '../../components/SmartAvatar';
import { useAdmin } from '../../contexts/admin.context';
import EditWalletModal from './EditWalletModal';
import EventModal from './EventModal';

interface Props {
  userCredentials: UserCredentials
}

function Wallets(props: Props) {
  const { companySlug } = useParams<Params>();
  const { userCredentials } = props;
  const [walletId, setWalletId] = useState<string | null>(null)
  const [dates, setDates] = useState<DateRange<Date>>([new Date(), new Date()])
  const [open, setOpen] = useState(false)
  const [openEvent, setOpenEvent] = useState(false)
  const [event, setEvent] = useState<Document<WalletLog>>()
  const [type, setType] = useState<WalletActionLog>()
  const { usersMap, wallets, walletsMap, isValidating } = useAdmin()

  const walletLogs = useCollection<WalletLog>(walletId
    ? `company/${companySlug}/wallets/${walletId}/logs`
    : null,
    {
      listen: true,
      where: [
        ['time', '>', dates[0]?.getTime() || 0],
        ['time', '<',  dates[1]?.getTime() || 0],
      ],
    }
  )

  const availableWallets = userCredentials.wallets_private
    ? wallets.filter(w => !w.archived)
    : wallets.filter(w => !w.private && !w.archived)

  const addWallet = () => {
    walletId && setWalletId(null)
    setOpen(true)
  }

  useEffect(() => {
    const initEndDate = new Date()
    initEndDate.setHours(23)
    initEndDate.setMinutes(59)
    const initStartDate = new Date()
    initStartDate.setHours(0)
    initStartDate.setMinutes(0)
    initStartDate.setDate(initStartDate.getDate() - 7)
    setDates([initStartDate, initEndDate])
  }, [])

  useEffect(() => {
    if (availableWallets.length && !walletId) {
      setWalletId(availableWallets[0].id)
    }
  }, [])

  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <Loader open={isValidating || walletLogs.isValidating} />

      <Stack
        direction={{ xs: 'row' }}
        spacing={{ xs: 1 }}
        alignItems='center'
        justifyContent='center'
      >
        <Autocomplete
          value={walletId}
          onChange={(_, v) => setWalletId(v)}
          options={availableWallets.map(w => w.id)}
          sx={{ minWidth: 260 }}
          renderInput={(params) => <TextField {...params} label="Счет" />}
          getOptionLabel={o => walletsMap[o]?.name || ''}
        />

        {userCredentials.wallets_edit && walletId &&
          <IconButton onClick={() => setOpen(true)}> <Edit/> </IconButton>
        }

        {userCredentials.wallets_edit &&
          <IconButton onClick={addWallet}> <AddBox/> </IconButton>
        }
      </Stack>


      <Typography variant='h5' sx={{my: 4}}>
        {walletId ? `Остаток: ${walletsMap[walletId]?.balance} грн` : ''}
      </Typography>

      {walletId && userCredentials.wallets_create_actions &&
        <Stack
          direction={{ xs: 'row' }}
          spacing={{ xs: 2 }}
          justifyContent='center'
          sx={{mt: 4, mb: 6}}
        >
          <Button
            color='success' variant='outlined' startIcon={<Add />}
            onClick={() => {
              setType('income')
              setOpenEvent(true)
            }}
          >Доход</Button>

          <Button
            color='error' variant='outlined' startIcon={<Remove />}
            onClick={() => {
              setType('spending')
              setOpenEvent(true)
            }}
          >Расход</Button>

          <Button
            color='info' variant='outlined' startIcon={<ArrowRightAltTwoTone />}
            onClick={() => {
              setType('transfer')
              setOpenEvent(true)
            }}
          >Перевод</Button>
        </Stack>
      }

      {walletId &&
        <Box sx={{ width: '300px', mb: 1, mx: 'auto'}}>
          <MobileDateRangePicker
            cancelText={null}
            showToolbar={false}
            startText="Период с"
            endText="Период до"
            value={dates}
            disableMaskedInput
            disableFuture
            onAccept={(newValue) => {
              setDates(newValue)
            }}
            onChange={() => {}}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <TextField {...startProps} sx={{ marginRight: 1 }} size='small' /> -
                <TextField {...endProps} sx={{ marginLeft: 1 }} size='small' />
              </React.Fragment>
            )}
          />
        </Box>
      }

      {walletId &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>

              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Автор</TableCell>
                <TableCell>Комментарий</TableCell>
                <TableCell>Сумма, грн</TableCell>
                <TableCell>Остаток, грн</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {walletLogs.data?.sort((a,b) => b.time - a.time).map(log => {
                const userName = usersMap[log.author]?.displayName || log.author
                return (
                  <TableRow key={log.id}>
                    <TableCell>{format(new Date(log.time), 'dd.MM.yyyy HH:mm')}</TableCell>
                    <TableCell>
                      <Stack direction='row' alignItems='center' spacing={{xs: 1}}>
                        <SmartAvatar name={userName} photoURL={usersMap[log.author]?.photoURL}/>
                        <span>{userName}</span>
                      </Stack>
                    </TableCell>
                    <TableCell>{log.comment}</TableCell>
                    <TableCell>
                      <Stack direction='row' alignItems='center'>
                        {log.type === 'income' && <Add color='success' />}
                        {log.type === 'spending' && <Remove color='error' />}
                        {log.type === 'cancel' && <WarningAmberTwoTone color='warning' />}
                        <Box
                          component='span'
                          sx={{
                            fontSize: '22px',
                            color: log.type === 'income' ? 'success.main' : log.type === 'spending' ? 'error.main' : 'warning.main',
                            textDecoration: log.canceled ? 'line-through' : 'none'
                          }}
                        >
                          {log.sum}
                        </Box>
                      </Stack>
                    </TableCell>
                    <TableCell>{log.total}</TableCell>
                    <TableCell>
                      {log.type !== 'cancel' && !log.canceled && userCredentials.wallets_cancel_actions &&
                        <IconButton onClick={() => {
                          setEvent(log)
                          setType(log.type)
                          setOpenEvent(true)
                        }}>
                          <Edit />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                )
              })}

              {walletLogs.data && !walletLogs.data.length &&
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box padding={3}>
                      <Typography variant='h5' color='textSecondary' align='center'>За этот период не было событий</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      }

      {open && <EditWalletModal wallet={walletId ? walletsMap[walletId] : null} onClose={() => setOpen(false)}/> }

      {openEvent && walletId &&
        <EventModal
          userCredentials={userCredentials}
          wallet={walletsMap[walletId]}
          wallets={availableWallets}
          action={event}
          type={event?.type || type || 'income'}
          onClose={() => {
            setOpenEvent(false)
            setEvent(undefined)
            setType(undefined)
          }}
        />
      }

    </Container>

  );
}

export default Wallets;
